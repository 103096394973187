<template>
    <div class="flex w-full min-h-screen justify-center items-center">
        <div class="flex flex-col space-y-10 items-center justify-center text-center">
            <h1 class="text-15xl font-bold">
                <span>4</span>
                <span class="text-blue-500 dark:text-blue-400 duration-300">0</span>
                <span>3</span>

            </h1>
            <h3 class="text-8xl font-bold">
                {{ $t('errors.403.title', $store.state.locale) }}
            </h3>
            <p class="text-3xl">
                {{ $t('errors.403.body', $store.state.locale) }}
            </p>
            <router-link 
                :to="`/${$store.state.locale}/`"
                title="Back To Home"
                class="flex items-center space-x-1 border rounded border-blue-500 dark:border-blue-400 text-blue-500 dark:text-blue-400 hover:bg-blue-500 dark:hover:bg-blue-500 dark:hover:border-blue-500 hover:text-white dark:hover:text-white duration-300 px-3 py-2"
            >
                <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-5 fill-current"><path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"/></svg>
                <span>
                    {{ $t('errors.403.back', $store.state.locale) }}
                </span>
            </router-link>
        </div>
    </div>  
</template>
