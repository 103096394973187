/* eslint-disable */
// import router from '../../routing/router'
// import moment from 'moment';
// import { store } from '../../store';
export default
{
    namespaced: true,
    state: 
    {
        user: null,
        token: null,
        expires_at: null,
        orgGUID: null,
        supplierAID: null
    },
    actions: 
    {
        async init({commit, state})
        {
           //
        },
    },
    mutations:{
        SET_USER(state, user)
        {
            state.user = user;
        },
        SET_TOKEN(state, token)
        {
            state.token = token;
        },
        SET_EXPIRES_AT(state, expires_at)
        {
            state.expires_at = expires_at;
        },
        SET_ORG_GUID(state, guid)
        {
            state.orgGUID = guid;
        },
        SET_SUPPLIER_AID(state, aid)
        {
            state.supplierAID = aid;
        },
        SET_ACTIVE_SUBSCRIPTION(state, value)
        {
            state.user.active_subscription = value;
        },
        CLEAR_STATE(state)
        {
            state.user = null;
            state.token = null;
            state.expires_at = null;
        }
    },
    getters:{}
}
