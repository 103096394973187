import { useApi } from "../composables/useApi";
import { useStore } from "vuex";

const ZdhcProductService = () => 
{
    /* Data */
    const ApiClient = useApi();
    const store = useStore();

    /* Methods */
    const storeZdhcProduct = async(payload) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.post('zdhc-product/store', payload);
        if(res)
        {
            if(res.status === 200) return 200;
            return res.data;
        }
    };
    const searchZdhcProduct = async (id) =>
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.get(`zdhc-product/search/${id}`);
        if(res)
        {
            return res.data;
        }
    };
    const searchMatchById = async (id) => 
    {
        store.commit('errors/CLEAR_STATE');
        let matchesArray = [];
        const res = await ApiClient.get(`zdhc-product/check-for-match/${id}`);
        if(res)
        {
            res.data.results.forEach( p => 
            {
                let jsonName = JSON.parse(p.name)
                matchesArray.push({
                    name: jsonName[store.state.locale] ? jsonName[store.state.locale].toLowerCase() 
                    : (jsonName['en'] ? jsonName['en'].toLowerCase() : jsonName[Object.keys(jsonName)[0]].toLowerCase()),
                    id: p.id,
                    guid: p.guid,
                    translated_names: jsonName,
                    formulator: p.formulator,
                    data: p.data,
                    certifications: p.certifications
                });
            });
            //return res.data;
            return matchesArray;
        }
    };
    const searchMatchByWord = async (name) => 
    {
        store.commit('errors/CLEAR_STATE');
        let matchesArray = [];
        let encodedName = encodeURIComponent(name);
        const res = await ApiClient.get(`zdhc-product/search-by-name/${encodedName}`);

        if(res)
        {
            res.data.results.forEach( p => 
            {
                let jsonName = JSON.parse(p.name)
                matchesArray.push({
                    name: jsonName[store.state.locale] ? jsonName[store.state.locale].toLowerCase() 
                    : (jsonName['en'] ? jsonName['en'].toLowerCase() : jsonName[Object.keys(jsonName)[0]].toLowerCase()),
                    id: p.id,
                    guid: p.guid,
                    formulator: p.formulator
                });
            });

            //return res.data;
            return matchesArray;
        }
    };
    const showZdhcProduct = async (id) => 
    {
        let res = await ApiClient.get(`zdhc-product/${id}`);
        if(res)return res.data;
        return res;
    };
    const deleteZdhcProduct = async(product) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.delete(`zdhc-product/${product}`);
        if(res)
        {
            if(res.status === 200) return 200;
            return res.data;
        }
    };
    const fetchZdhcProduct = async (endpoint) =>
    {
        const res = await ApiClient.get(endpoint);
        if(res)
        { 
            if(res.data)  
            {
                return res.data;
            }
            return res;
        }
        
    };

    return {
        /* Methods */
        storeZdhcProduct,
        searchZdhcProduct,
        searchMatchById,
        searchMatchByWord,
        showZdhcProduct,
        deleteZdhcProduct,
        fetchZdhcProduct
    }
};

export default ZdhcProductService;


/**
 * 
 * TableRow.vue functions
 * 
 */
/* export const setpOneSearch = async (product, split = false) => 
{
    let encodedName = encodeURIComponent(product);
    let endpoint = `zdhc-product/step-one/${encodedName}`;
    if(split) endpoint = endpoint+'?split=true'
    const res = await apiClient.get(endpoint);
    if(res.data?.results)
    {
        return res.data
    }
    return res;
}; */