<template>
    <div class="container p-6 mx-auto flex flex-col justify-center  items-center gap-5 border-t">
        <div class="w-full text-sm lg:text-base text-center">
            &copy; {{ new Date().getFullYear() }} Jacopo Lelli - all rights reserved.
        </div>
        <div class="flex w-full gap-2 items-center justify-center ">
            <a href="//www.facebook.com/jacopo.lelli1" class="min-w-min" title="Facebook" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-5 fill-current"><path d="M20.9,2H3.1A1.1,1.1,0,0,0,2,3.1V20.9A1.1,1.1,0,0,0,3.1,22h9.58V14.25h-2.6v-3h2.6V9a3.64,3.64,0,0,1,3.88-4,20.26,20.26,0,0,1,2.33.12v2.7H17.3c-1.26,0-1.5.6-1.5,1.47v1.93h3l-.39,3H15.8V22h5.1A1.1,1.1,0,0,0,22,20.9V3.1A1.1,1.1,0,0,0,20.9,2Z"/></svg>
            </a>
            <a href="//www.linkedin.com/in/jacopo-lelli-348596150/" class="min-w-min" title="LinkedIn" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-5 fill-current"><path d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z"/></svg>
            </a>
            <a href="//omnes.one/jacopo-lelli" class="min-w-min" itle="One" target="_blank">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.000000 100.000000" preserveAspectRatio="xMidYMid meet" class="w-4 fill-current duration-300"><g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)" stroke="none"><path d="M348 961 c-340 -109 -445 -552 -192 -805 259 -258 708 -144 809 207 23 79 21 205 -3 284 -17 52 -76 163 -87 163 -2 0 11 -28 28 -62 30 -59 32 -70 32 -168 0 -95 -3 -110 -28 -163 -16 -32 -51 -80 -77 -106 -222 -222 -597 -101 -652 211 -28 158 60 326 210 400 58 29 79 34 162 37 120 5 85 19 -50 19 -66 1 -113 -5 -152 -17z"/><path d="M469 877 c-91 -34 -145 -85 -185 -171 -23 -51 -26 -67 -22 -135 2 -42 10 -85 16 -96 11 -18 12 -17 6 7 -10 43 15 126 49 165 62 71 171 96 251 58 71 -34 126 -119 126 -196 0 -99 -84 -192 -188 -208 l-47 -7 50 -8 c94 -13 222 40 274 113 61 85 78 197 45 294 -21 61 -84 132 -148 164 -62 31 -171 41 -227 20z"/></g></svg>
            </a>
        </div>
    </div>
</template>