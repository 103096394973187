<template>
  <!--<input type="search" class="w-full p-2 bg-red-50 rounded" v-model="search_input"/>-->
  <ais-instant-search v-if="search_input && open_algolia" :search-client="searchClient" index-name="products" class="absolute w-full z-10 dark:bg-gray-700 border dark:border-gray-700">
    <ais-search-box class="hidden" v-model="search_input"/>
    <ais-hits>
      <template v-slot:item="{ item }">
        <button
          @click.prevent="setProductName(item.name)"
          class="w-full bg-gray-200 dark:bg-gray-900 hover:bg-white dark:hover:bg-gray-700 duration-300 p-2 text-left"
        >{{ item.name }}</button>
      </template>
    </ais-hits>
  </ais-instant-search>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
import {reactive, ref, watch} from 'vue';

export default {
  props:['search_parent_input', 'open_algolia'],
  emits:['setProductName'],
  setup(props, {emit})
  {
    const search_input = ref(props.search_parent_input);
    const searchClient = reactive (algoliasearch(
      process.env.VUE_APP_ALGOLIA_ID,
      process.env.VUE_APP_ALGOLIA_SECRET,
    ));
    watch(
      () => props.search_parent_input,
      () => { search_input.value = props.search_parent_input }
    );

    const setProductName = (name) => 
    {
      emit('setProductName', name)
    }

    return {searchClient, search_input, setProductName}
  }
};
</script>

<style>
.ais-Hits-item
{
  padding: 0 !important;
}
.ais-Hits-list
{
  position:absolute !important;
  top:0;
  bottom:0;
  right:0;
  left:0;
  overflow-y: auto!important;
  max-height: 10rem !important;
  height: 10rem !important;
  overscroll-behavior: contain!important;
}
.ais-Hits
{
  position:relative !important;
}
</style>
