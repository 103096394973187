<script setup>
import { defineProps } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
/* Props */
const props = defineProps({
    product: {required:true}
})
/* Data */
const {t} = useI18n();
const store = useStore();
</script>
<template>
    <div class="w-full flex flex-col space-y-5">
        <div class="flex flex-col space-y-5 h-72 overflow-y-auto overscroll-contain text-sm justify-center text-center">
            <h3 class="text-2xl font-semibold">
                {{ t('noMatchModal.title', store.state.locale) }}
            </h3>
            <p 
                v-if="props.product.pid"
                class="text-lg" v-html="t('noMatchModal.body', store.state.locale).replace('_pid', props.product.pid)"></p>
        </div>
    </div>
</template>