<template>
  <div id="App" v-if="status === 'loaded' && status !== 'error'">
    <!-- maintenanceMode -->
    <div
      v-if="maintenanceMode == 'maintenance'"
      class="fixed inset-0 bg-white" style="z-index:100">
        <div class="absolute top-0 inset-x-0 flex w-full items-center justify-between container mx-auto p-5">
          <div
              class="inline-flex items-center py-3"
              >
              <img 
                src="@/assets/img/logo/logo-ympact.png"
                class="min:w-32 w-32">
          </div>
      </div>
    <div class="flex items-center h-full min-h-screen p-5">
        <div class="w-full dark:bg-zinc-900 dark:bg-zinc-900 h-full flex items-center justify-center">
            <div class="flex flex-col">
                <h1 class="font-bold text-7xl lg:text-9xl text-center lg:text-left mb-10 mx-auto">
                    503
                </h1>
                <h2 class="text-xl lg:text-3xl text-left mb-10 text-center">
                  The service has been temporarily suspended due to maintenance activities.  <br>
                  Try later
                </h2>
            </div>
        </div>
    </div>
    </div>
    <!-- Warning banner -->
    <div 
      v-if="showBanner"
      class="fixed inset-x-0 top-0 bg-yellow-700 flex items-center justify-between px-4 py-4 z-50 text-white">
      <div class="flex items-center justify-start space-x-3">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"      stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
        </svg>
        
          <div>
            <h1 class="text-xl font-semibold">
              {{ $t('banner.title', $store.state.locale) }}
            </h1>
            <p class="font-semibold">
              {{ $t('banner.body', $store.state.locale) }}
            </p>
          </div>
      </div>
      <button 
          title="Chiudi"
          @click.prevent="showBanner = false"
          class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-white">
                  <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                      c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                      c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
              </svg>
      </button>
    </div>
    <AuthLayout 
      v-if="$route.meta.layout === 'dashboard' && $store.state.auth.user"
      :showLayer="showLayer"
    />
    <GuestLayout v-else/>
  </div>
  <div v-else-if="status === 'loading'" class="w-full h-screen flex flex-col items-center justify-center text-center p-2"> 
    <h1 class="text-2xl">
      Loading
    </h1>
    <svg class="animate-spin w-12 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
  </div>
  <AccessTokenErrorComponent 
    v-else-if="status === 'error'" 
    @newRoute="goToRoute"
  />
  <div v-else-if="status === 'subscriptionError'">
    subscriptionError
  </div>
 <!--  <div 
   
    class="w-full h-screen flex flex-col items-center justify-center text-center p-2 space-y-5"> 
    <div class="flex flex-col space-y-2">
      <h3 class="text-2xl text-red-500">
        {{ $t('zdhc.accessTokenGenerationErrorTitle', $store.state.locale) }}
      </h3>
      <h3 class="text-xl">
        {{ $t('zdhc.accessTokenGenerationErrorSubTitle', $store.state.locale) }} 
        <span class="font-semibold">
          {{ $store.state.auth.user.user_key ?? 'not found' }}
        </span>
      </h3>
      <span class="text-xl">
        {{ $t('zdhc.accessTokenGenerationErrorVerification', $store.state.locale) }} 
      </span>
    </div>
    <a 
        class="w-full text-left p-2 group duration-300 flex items-center justify-center"
        :href="`mailto:${supportEmail}`">
        <h2 class="text-lg font-semibold group-hover:underline">
            Click here for help
        </h2>
    </a>
    <button
      :title="$t('sidebar.logout', $store.state.locale)"
      class="text-lg flex items-center justify-center"
      @click.prevent="signout"
      :class="setLogoutClasses"
      v-html="setLogoutContent"
      :disabled="logoutStatus !== 'void'">
    </button>
  </div> -->
  <vue3-snackbar bottom left></vue3-snackbar>
</template>

<script>
import moment from 'moment';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {ref, computed, defineAsyncComponent} from 'vue';
import {useI18n} from 'vue-i18n';
import SnackbarComposables from './composables/SnackbarComposables';
import ProfileService from './services/ProfileService';
import ZdhcServiceV2 from './services/Zdhc/ZdhcServiceV2';
import ZdhcServiceV5 from './services/Zdhc/ZdhcServiceV5';
import AuthService from './services/AuthService';
import GoogleService from './services/GoogleService';
import ZdhcProductService from './services/ZdhcProductService';
import GuestLayout from './layouts/Guest.vue';
import AuthLayout from './layouts/Auth.vue';
export default {
  name: 'App',
  components: 
  {
    /* GuestNavbar,
    Sidebar, */
    GuestLayout,
    AuthLayout,
    AccessTokenErrorComponent: defineAsyncComponent( () => import('./pages/errors/AccessTokenError.vue'))
  },
  setup()
  {
    /** 
     * 
     * Data
     * 
     */
    const maintenanceMode = process.env.VUE_APP_STATUS;
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const showNavigationModal = ref(false);
    const logoutStatus = ref('void');
    const showLayer = ref(false);
    const showBanner = ref(false);
    const status = ref('loaded');
    const { addSnackbar } = SnackbarComposables();
    const { updateSuscriptionExpiration } = ProfileService();
    const { InCheck, getSubscriptionStatus, userOrganizations,FormulatorsIndex, getProducts } = ZdhcServiceV2();
    const { inCheckAccessToken, inCheckSubscriptionStatus} = ZdhcServiceV5();
    const { logout, checkAuthuser } = AuthService();
    const { t, locale } = useI18n();
    const theme = ref(localStorage.theme);
    const supportEmail = process.env.VUE_APP_YMPACT_SUPPORT_EMAIL;
    const { storeZdhcProduct } = ZdhcProductService();
    const { translate } = GoogleService();
    const system_theme = ref(window.matchMedia('(prefers-color-scheme: dark)').matches);

    /** 
     * 
     * Computed
     * 
     */
    const setLogoutContent = computed( () => 
    {
        switch(logoutStatus.value)
        {
            case 'loading':
                return `<svg class="animate-spin h-5 w-5 fill-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
            case 'success':
                return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
            case 'error':
                return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
            case 'void': default:
                return t('sidebar.logout', locale.value);
        }
    });
    const setLogoutClasses = computed( () =>
    {
        switch(logoutStatus.value)
        {
            case 'void': case 'loading': default:
                return 'text-light-green-500 hover:text-light-green-300';
            case 'error':
                return `text-red-500 hover:text-red-700`;
            case 'success':
                return `text-green-500 hover:text-green-700`;
        }
    });

    /** 
     * 
     * Methods
     * 
     */
    /* const setTheme = () => 
    {
      theme.value = theme.value === 'dark' ? 'light' : 'dark';
      localStorage.setItem('theme', theme.value);
      checkForTheme();
    } */
    const checkForTheme = () => 
    {
      
      if (theme.value === 'dark' || (!theme.value && system_theme.value)) {
        //document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    };
    const openMobileNavigation = () => 
    {
      let sidebar = document.getElementById('dashSidebar');

      if(sidebar) sidebar.classList.remove('-ml-full');
      showLayer.value = true;
    }
    const closeMobileNavigation = () => 
    {
      let sidebar = document.getElementById('dashSidebar');
      if(sidebar) sidebar.classList.add('-ml-full');
      showLayer.value = false;
    }
    const toggleNavigation = () => 
    {
      let sidebar = document.getElementById('dashSidebar');
      if(sidebar)
      {
        sidebar.classList.contains('-ml-full') ? openMobileNavigation() : closeMobileNavigation();
      }
    };
    const checkForUser = async () => 
    {
      status.value = 'loading';
      const res = await checkAuthuser();
      if(res === 200)
      {
        if(route.name === 'WaitingPage') status.value = 'loaded';
        else if(store.state.auth.user.user_key) getAccessToken();
      }
      else 
      {
        store.commit('auth/CLEAR_STATE');
        return router.push(`/${store.state.locale}/signin`);
      }
    }
    const getAccessToken = async () => 
    {
      let res;
      if(process.env.VUE_APP_ZDHC_VERSION == 2) res = await InCheck(store.state.auth.user.user_key);
      else res = await inCheckAccessToken(store.state.auth.user.user_key);

      if(res)
      {
        locale.value = store.state.locale;
        if(res && res.status === 'success') 
        {
          userOrganizations()
          .then( response => 
          {  
            if(response)
            {
              store.commit('auth/SET_ORG_GUID', response.Organizations[0].OrgGUID);
              store.commit('auth/SET_SUPPLIER_AID', response.Organizations[0].SupplierAID);
              /**
               *
               * If user has a valid subscription_expires_at, status is 'loaded'
               * else check for subscription status
               *
               */
              
              if(store.state.auth.user.subscription_expires_at &&
                  moment(store.state.auth.user.subscription_expires_at).isAfter(moment()))
              {
                return status.value = 'loaded';
              }
              checkUserIncheckSubscriptionStatus();
            }
          });
        }
        else
        {
          addSnackbar('error', t('snackbar.refresh', store.state.locale)); // Add a more explicable message + logout 
          return status.value = 'error';
        }
      }
    };
    const checkUserIncheckSubscriptionStatus = async () => 
    {
      let res;
      if(process.env.VUE_APP_ZDHC_VERSION == 2) res = await getSubscriptionStatus(store.state.auth.orgGUID);
      else res = await inCheckSubscriptionStatus(store.state.auth.orgGUID);
      if(res)
      {
        if(res.status === 'ok')
        {
          if(res.expirationDate) updateSuscriptionExpiration({subscription_expires_at: res.expirationDate});
          return status.value = 'loaded';
        }
        else 
        {
          // if expirationDate is not valid try:
          // 1 - renew the subscription
          // 2 - activate subscription (is renew is not possible)
          console.log('non valida')
          return status.value = 'loaded';
        }
      }
    };
    const getFormulators = async () => 
    {
      const res = await FormulatorsIndex();
      console.log(res)
      /* if(res) 
      {
        // Store formulators
        store.dispatch('formulator/storeOrUpdateFormulator', [{formulators: res.data}]);
        // .then( f_res => 
        // {
        //   // store.commit('formulator/SET_FORMULATORS', f_res.formulators);
        // });

      } */
    };
    const getAllProducts = async () => 
    {
      const res = await getProducts();
      res.data.forEach( p => 
      {
        let count = 0;
          translate(p.productName)
          .then(async (translation_res) => 
          {
            let translated_name = translation_res.data.translations[0].translatedText;
            let detected_lang = translation_res.data.translations[0].detectedSourceLanguage;
            if(p.productName.toLowerCase() === translated_name.toLowerCase())
            {
                p.translated_names = { [detected_lang] :  p.productName.toLowerCase() };
            }
            else 
            {
                p.translated_names = 
                { 
                    [detected_lang] :  p.productName.toLowerCase(),
                    'en' : translated_name.toLowerCase(),
                };
            }
            const storeRes = await storeZdhcProduct(p);
            if(storeRes === 200) count +=1;
            if(count === res.data.length) console.log('FINITO - ', count)
          }); 
      });
    };
    const signout = () => 
    {   
      store.commit('errors/CLEAR_STATE');
      logoutStatus.value = 'loading';
      logout()
      .then( res => 
      {
          if(res === 200) 
          {
            setTimeout( () => logoutStatus.value = 'loaded', 1000);
            return router.push(`/${store.state.locale}/signin`);
          }
      });
    };
    const goToRoute = () => 
    {
      if(store.state.auth.user) checkForUser();
      else
      {
        status.value = 'loading';
        setTimeout( () => status.value = 'loaded', 1500);
      }
    }

    /** 
     * 
     * Hooks
     * 
     */
    store.commit('CLEAR_OUTDATED_CHECKED_DATE');
    //store.commit('CLEAR_CHECKED_DATE');
    if(store.state.auth.user) checkForUser();

    //Selecte Dark or Light mode
    checkForTheme();

    return {
      /* data */
      maintenanceMode,
      status,
      logoutStatus,
      showNavigationModal,
      showLayer,
      supportEmail,
      showBanner,
      /* Computed */
      setLogoutContent,
      setLogoutClasses,
      /* Methods */
      toggleNavigation,
      closeMobileNavigation,
      getFormulators,
      getAccessToken,
      getAllProducts,
      checkUserIncheckSubscriptionStatus,
      signout,
      goToRoute
    }
  }
}
</script>

