<template>
    <div class="flex flex-col space-y-12">
        <div class="flex flex-col space-y-5 lg:space-y-12 h-full">
            <div v-if="fetched" class="flex flex-col space-y-5 lg:space-y-12 h-full">         
                <!-- Generals -->
                <div class="grid grid-cols-1 md:grid-cols-2 md:gap-5 xl:gap-8 2xl:gap-10">
                    <div class="rounded w-full bg-white dark:bg-gray-800 shadow p-5">
                        <!-- Name -->
                        <div class="mb-1 sm:mb-2 flex items-center space-x-1">
                            <label for="formulator_name" class="inline-block uppercase">{{ $t('product.create.name', $store.state.locale) }}:</label>
                            <span>{{fields.name}}</span>
                        </div>
                        <!-- Formulator -->
                        <div class="mb-1 sm:mb-2 flex items-center space-x-1">
                            <label for="formulator_name" class="inline-block uppercase">{{ $t('product.create.formulator', $store.state.locale) }}:</label>
                            <span>{{formulator_name}}</span>
                        </div>
                    </div>
                    <div class="hidden md:block flex flex-col space-y-2">
                        <h1 class="text-3xl">
                            {{ $t('product.update.title', $store.state.locale) }}
                        </h1>
                        <!--<hr class="border-gray-300">
                        <p class="">
                            {{ $t('product.update.description', $store.state.locale) }}
                        </p>-->
                    </div>
                </div>
                <!-- Edit Incheck References -->
                <div v-if="incheck_references.data.length" class="grid grid-cols-1 md:grid-cols-2 md:gap-5 xl:gap-8 2xl:gap-10">
                    <div class="rounded w-full bg-white dark:bg-gray-800 shadow p-5 flex flex-col space-y-2">
                        <EditIncheckComponent 
                            v-for="i in incheck_references.data" :key="i.id"
                            :parentInchecReference="i"
                        />
                    </div>
                    <!--<div class="hidden md:block flex flex-col space-y-2">
                        <h1 class="text-3xl">
                            {{ $t('product.update.title', $store.state.locale) }}
                        </h1>
                        <hr class="border-gray-300">
                        <p class="">
                            {{ $t('product.update.description', $store.state.locale) }}
                        </p>
                    </div>-->
                </div>
            </div>
            <div v-else class="flex flex-col space-y-5 lg:space-y-12">         
                <!-- Generals -->
                <div class="grid grid-cols-1 md:grid-cols-2 md:gap-5 xl:gap-8 2xl:gap-10">
                    <div class="rounded w-full bg-white dark:bg-gray-800 shadow p-5 h-32 flex items-center justify-center">
                        <svg class="animate-spin w-12 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
                    </div>  
                    <div class="hidden md:block flex flex-col space-y-2">
                        <h1 class="text-3xl">
                            {{ $t('product.update.title', $store.state.locale) }}
                        </h1>
                    </div>
                </div>
            </div>

            <!--<div class="grid grid-cols-1 md:grid-cols-2 md:gap-5 xl:gap-8 2xl:gap-10">
                <div class="flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-2">
                    <button 
                        type="submit" 
                        title="Aggiungi utente"
                        class="flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-300 rounded shadow-md focus:shadow-outline focus:outline-none"
                        :class="setSubmitClasses"
                        v-html="setSubmitContent">
                    </button> 
                </div>
            </div>-->
        </div>

    </div>
</template>
<script>
import { ref, reactive, computed, watch, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import ProductService from '../../../services/ProductService';
import SnackbarComposables from '../../../composables/SnackbarComposables';
import EditIncheckComponent from '../../../components/incheckReference/editIncheckComponent';
import moment from 'moment';

export default 
{
    components:{ EditIncheckComponent },
    setup()
    {
        /**
         *
         * Data
         *
         */
        const { t, locale } = useI18n();
        const store = useStore();
        const route = useRoute();
        const fields = reactive({});
        const fetched  = ref(false);
        const updateStatus  = ref('void');
        const formulators = ref([]);
        const formulator_name = ref('');
        const search_formulators = ref([]);
        const selected_formulator = ref([]);
        const open_suggestions = ref(false);
        const incheck_references = reactive({
            data: [],
            next_page_url: null
        });
        const { addSnackbar } = SnackbarComposables();
        const {showProduct, updateProduct} = ProductService();

        /**
         *
         * Watch
         *
         */
        watch(
            () => formulator_name.value,
            () => 
            {
                searchFormulator();
            }
        );

        /**
         *
         * Computed
         *
         */
        const setSubmitContent = computed( () => 
        {
            switch(updateStatus.value)
            {
                case 'void': default:
                    return t('product.update.submitContent', locale.value);
                case 'loading':
                    return `<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
                case 'success':
                    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
                case 'error':
                    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
            }
        });
        const setSubmitClasses = computed( () =>
        {
            switch(updateStatus.value)
            {
                case 'void': case 'loading': default:
                    return 'bg-blue-500 hover:bg-blue-700';
                case 'error':
                    return `bg-red-500 hover:bg-red-700`;
                case 'success':
                    return `bg-green-500 hover:bg-green-700`;
            }
        });
        

        /**
         *
         * Methods
         *
         */
        const searchFormulator = () => 
        {
            search_formulators.value = [];
            if(formulator_name.value.length > 0)
            {
                return search_formulators.value = formulators.value.filter( e => e.name.trim().toLowerCase().includes(formulator_name.value.trim().toLowerCase()));
            }
            return search_formulators.value = formulators.value;
        };

        const selectFormulator = (formulator) => 
        {
            if(formulator)
            {
                selected_formulator.value = formulator;
                formulator_name.value = selected_formulator.value.name.trim();
            }
        };

        const openMenu = () => 
        {
            open_suggestions.value= true;
        }

        const closeMenu = () => 
        {
            open_suggestions.value= false;
        }

        const editProduct = () => 
        {
            if(selected_formulator.value.id)
            {
                if(selected_formulator.value.name !== formulator_name.value)
                {
                    addSnackbar('warning', t('product.create.select_formulator', store.state.locale));
                }
                else 
                {
                    fields.formulator_id = selected_formulator.value.id;
                    updateProduct(route.params.product, fields);
                }
                
            }
            else 
            {
                addSnackbar('warning', t('product.create.select_formulator', store.state.locale))
            }
        }

        /**
         *
         * Hooks
         *
         */
        locale.value = store.state.locale;
        onBeforeMount( () => 
        {
            /* formulators.value = store.state.formulator.formulators;
            search_formulators.value = formulators.value; */
            showProduct(route.params.product)
            .then( res => 
            {
                Object.assign(fields, res.product);
                fields.from = fields.from ? moment(fields.from).format('YYYY-MM-DD') : null;
                fields.to = fields.to ?moment(fields.to).format('YYYY-MM-DD') : null;
                selectFormulator(fields.formulator);
                if(fields.formulator) formulator_name.value = fields.formulator.name.trim();
                if(res.incheck_references)
                {
                    incheck_references.data = [... res.incheck_references.data];
                    incheck_references.next_page_url = res.incheck_references.next_page_url
                }
                fetched.value = true;
            });
        });

        return {
            /* Data */
            updateStatus,
            fields,
            fetched,
            formulator_name,
            open_suggestions,
            search_formulators,
            selected_formulator,
            incheck_references,
            /* Computed */
            setSubmitClasses,
            setSubmitContent,
            searchFormulator,
            selectFormulator,
            /* Methods */
            closeMenu,
            editProduct,
            openMenu
        }
    }
}
</script>