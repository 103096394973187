<template>
    <div class="bg-white dark:bg-gray-800 shadow rounded w-full p-5 flex">
        <button 
            class="inline-flex items-center justify-center max-w-max h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md focus:shadow-outline focus:outline-none bg-red-500 hover:bg-red-800"
            :title="$t('actions.save', $store.state.locale)"
            @click.prevent="modalActive = true"
            v-html="setSubmitContent">
        </button>
    </div> 
    <teleport to="body">
        <DeleteProfileModal 
            v-if="modalActive"
            @closeModal="closeModal"
            @deleteAccount="deleteProfile"
        />
    </teleport>
</template>
<script>
import DeleteProfileModal from '../../components/modals/DeleteProfileModal';
import ProfileService from '../../services/ProfileService';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { ref, computed } from 'vue';
export default 
{
    components: { DeleteProfileModal },
    setup()
    {
        /**
         *
         * Data
         *
         */
        const modalActive = ref(false);
        const deleteStatus = ref('void');
        const store = useStore();
        const { t, locale} = useI18n();
        const {deleteAccount} = ProfileService();

        /**
         *
         * Computed
         *
         */
        const setSubmitContent = computed( () => 
        {
            switch(deleteStatus.value)
            {
                case 'loading':
                    return `<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
                case 'success':
                    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
                case 'error':
                    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
                case 'void': default:
                    return t('actions.delete', locale.value)+' account';
            }
        });

        const setSubmitClasses = computed( () =>
        {
            switch(deleteStatus.value)
            {
                case 'void':
                    return 'bg-red-500 hover:bg-red-700';
                case 'loading':
                    return `bg-red-500 hover:bg-red-700`;
                case 'error':
                    return `bg-red-500 hover:bg-red-700`;
                case 'success':
                    return `bg-green-500 hover:bg-green-700`;
                default:
                    return 'bg-red-500 hover:bg-red-700';
            }
        });

        /**
         *
         * Methods
         *
         */
        const closeModal = () => 
        {
            modalActive.value = false;
        };
        const deleteProfile = () => 
        {
            if(deleteStatus.value === 'void')
            {
                deleteStatus.value = 'loading'
                deleteAccount();
            }
        };

        /**
         *
         * Hooks
         *
         */
        locale.value = store.state.locale;

        return {
            /* Data */
            deleteStatus,
            modalActive,
            /* Computed */
            setSubmitClasses,
            setSubmitContent,
            /* Methods */
            closeModal,
            deleteProfile
        }
    }
}
</script>