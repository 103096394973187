<script setup>
import { useStore} from 'vuex';
import { useI18n } from 'vue-i18n';
import SnackbarComposables from '../../composables/SnackbarComposables';
import ProductService from '../../services/ProductService';
import moment from 'moment';
import Encoding from 'encoding-japanese';
import { ref, computed, defineAsyncComponent, defineEmits } from 'vue';
/* Components */
const ImportModalRow = defineAsyncComponent( () => import('./ImportModal/ImportModalRow.vue'));
const PeriodComponent = defineAsyncComponent( () => import('./ImportModal/ImportPeriodComponent.vue'));
/* Emits */
const emits = defineEmits(['closeModal', 'newProducts', 'closeModalWithDate']);
/* Data */
const store = useStore();
const { t, locale} = useI18n();
const { addSnackbar } = SnackbarComposables();
const { storeFromCSV } = ProductService();
const fileInput = ref(null);
const csv_file = ref(null);
const products = ref([]);
const products_status = ref('void');
const storing_status = ref('void');
const importedStatus = ref(false);
const importedDates = ref([]);
const unimportableProducts = ref(0);
/* Computed */
const setSubmitContent = computed( () => 
{
    switch(storing_status.value)
    {
        case 'loading':
            return `<svg class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
        case 'success':
            return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
        case 'error':
            return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
        case 'void': default:
            return t('product.addProducts', locale.value);
    }
});
const setSubmitClasses = computed( () =>
{
    if(setSubmitDisabled.value)
    {
        return 'bg-gray-300 cursor-not-allowed';
    }
    switch(storing_status.value)
    {
        case 'void':  case 'loading':  default:
            return 'bg-blue-500 hover:bg-blue-300 dark:hover:bg-blue-700';
        case 'error':
            return `bg-red-500 hover:bg-red-700`;
        case 'success':
            return `bg-green-500 hover:bg-green-700`;
    }
});
const setSubmitDisabled = computed( () => 
{
    return storing_status.value !== 'void' || products.value.length === unimportableProducts.value;
});
/* Methods */
const openInputFile = () => 
{
    fileInput.value.click();
}
const loadFile = (e) => 
{
    csv_file.value = null;   
    if(e.target.files[0].type !== 'text/csv' && e.target.files[0].type !== 'application/vnd.ms-excel') return addSnackbar('warning', t('errors.csvError', locale.value));
    csv_file.value = e.target.files[0];
    handleCsvFile();

}
const handleCsvFile = () => 
{
    if(csv_file.value)
    {
        const reader = new FileReader();
        reader.readAsArrayBuffer(csv_file.value)
        reader.onload = (event) => 
        {
            var codes = new Uint8Array(event.target.result);
            var encoding = Encoding.detect(codes);
            var unicodeString = Encoding.convert(codes, {
                to: encoding === 'SJIS' ? 'ASCII' : 'UNICODE',
                from: encoding,
                type: 'string'
            });
            csvToArray(unicodeString);
        };
    }
    else return addSnackbar('warning', t('errors.csvError', locale.value));
};
const csvToArray = (str) => 
{
    let indexes = {};
    products.value = [];
    unimportableProducts.value = 0;
    importedDates.value = [];
    products_status.value = 'loading';
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");
    const titleRow = str.split("\n")[0];
    if(titleRow)
    {
        let titles = titleRow.split(';');
        titles.forEach((key, index) => 
        {
            if(key.toLowerCase().trim() == 'name') indexes.name = index;
            else if(key.toLowerCase().trim() == 'formulator') indexes.formulator = index;
            else if(key.toLowerCase().trim().includes('pid')) indexes.pid = index;
            else if(key.toLowerCase().trim().includes('quantity')) indexes.quantity = index;
            else if(key.toLowerCase().trim().includes('date')) indexes.date = index;
            else if(key.toLowerCase().trim().includes('btls')) indexes.btls = index;
        });
    }

    if(!Object.keys(indexes).length) return products_status.value = 'columnError';
    rows.forEach( r => 
    {
        r = r.split(';')
        if(r.length > 1)
        {
            let date = moment(r[indexes.date], 'DD-MM-YYYY').format('DD-MM-YYYY');
            let quantity = !isNaN(r[indexes.quantity].replace(',', '.')) ? r[indexes.quantity].replace(',', '.') : null;
            let p = {
                name: r[indexes.name].trim(),
                formulator_name: r[indexes.formulator].trim(),
                pid: indexes.pid ? r[indexes.pid].trim() : null,
                quantity: quantity,
                reference_date: date,
                bitls_code: indexes.btls ? r[indexes.btls] : null,
                status: null
            };
            products.value.push(p);
            if(p.name  
                && p.formulator_name
                && p.quantity
                && p.reference_date && moment(p.reference_date, 'DD-MM-YYYY').isValid())
            {
                let monthDate = moment('01-'+ moment(p.reference_date, 'DD-MM-YYYY').format('MM-YYYY'), 'DD-MM-YYYY').format('YYYY-MM-DD');
                // if monthDate is not in importedDates array, push it in
                // else add 1 to the specific counter
                if(!importedDates.value.some(e => e.date === monthDate))
                {
                    importedDates.value.push({
                        date: monthDate,
                        counter: 1
                    });
                }
                else 
                {
                    importedDates.value.filter(e => e.date === monthDate)[0].counter +=1;
                }
            }
            else 
            {
                unimportableProducts.value += 1;
            }
        }
    });
    return products_status.value = 'loaded';
};
const storeList = async () => 
{
    storing_status.value = 'loading';
    let count = 0;
    let totalProductsCount = products.value.filter( p => p.name  
            && p.formulator_name
            && p.quantity
            && p.reference_date && moment(p.reference_date, 'DD-MM-YYYY').isValid()).length;
    products.value.forEach( async p => 
    {
        if(p.name  
            && p.formulator_name
            && p.quantity
            && p.reference_date && moment(p.reference_date, 'DD-MM-YYYY').isValid())
        {    
            const res = await storeFromCSV(p);
            if(res)
            {
                count += 1;
                if(res.status && res.status == 'success' ) p.status = true;
                else p.status = false;
            }
            if(count === totalProductsCount)
            {
                storing_status.value = 'void';
                if(products.value.some(p => p.status === false)) storing_status.value = 'error';
                else 
                {
                    storing_status.value = 'success';
                    setTimeout( () => storing_status.value = 'void', 2000);
                    importedStatus.value = true;
                }
            }
        }
    });

};
const cancelImport = () => 
{
    storing_status.value = 'void';
    csv_file.value = null;
    products.value = [];
    unimportableProducts.value = 0;
};
const closeModalWithDate = (date) => 
{
    emits('closeModalWithDate', date);
};
/* Hooks */
locale.value = store.state.locale
</script>
<template>
    <div class="fixed inset-0 p-6 bg-gray-1000 bg-opacity-90 z-30 overscroll-y-contain overflow-y-auto text-blue-500">
        <div class="flex flex-col w-full h-full items-center justify-center">
            <div class="bg-white dark:bg-gray-900 dark:text-gray-400 border dark:border-gray-700 rounded p-5 flex flex-col space-y-2 w-full md:w-2/3 lg:w-1/2 2xl:w-2/3 max-h-xl overflow-y-auto">
                <div class="flex items-start justify-between">
                    <button 
                        title="Chiudi"
                        @click.prevent="emits('closeModal')"
                        class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                                <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                    c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                    c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                            </svg>
                    </button>
                </div>
                <h1 class="text-xl flex items-center space-x-1 px-3 dark:text-gray-200">
                    <span>{{ $t('actions.import', $store.state.locale) }}</span> 
                </h1>
                <input id="fileInput" ref="fileInput" type="file" accept=".csv" class="hidden" @change="loadFile($event)"/>
                <!-- import section -->
                <div v-if="!importedStatus">
                    <div v-if="!csv_file" class="flex flex-col p-3">
                        <h2 class="text-lg">{{ $t('importModal.description.title', $store.state.locale) }}:</h2>
                        <ul class="list-decimal list-inside">
                            <li>
                                {{ $t('importModal.description.first_li', $store.state.locale) }} <a href="/documents/CSV_Scheleton.csv" download class="font-bold text-light-green-500 dark:text-blue-400 hover:underline">{{ $t('importModal.description.here', $store.state.locale) }}</a>.
                            </li>
                            <li>
                                {{ $t('importModal.description.second_li', $store.state.locale) }}:
                                <ul class="list-disc list-inside ml-5">
                                    <li>
                                        {{ $t('importModal.description.point_one', $store.state.locale) }};
                                    </li>
                                    <li>
                                        {{ $t('importModal.description.point_two', $store.state.locale) }};
                                    </li>
                                    <li>
                                        {{ $t('importModal.description.point_three', $store.state.locale) }};
                                    </li>
                                    <li> 
                                        {{ $t('importModal.description.point_four_first', $store.state.locale) }} <span class="text-blue-500 dark:text-blue-400">{{ $t('importModal.description.date_format', $store.state.locale) }}</span> {{ $t('importModal.description.point_four_last', $store.state.locale) }}.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                {{ $t('importModal.description.third_li', $store.state.locale) }};
                            </li>
                            <li>
                                {{ $t('importModal.description.fourth_li_first', $store.state.locale) }} 
                                <span class="text-blue-500 dark:text-blue-400">{{ $t('actions.clickToImport', $store.state.locale) }}</span>
                                {{ $t('importModal.description.fourth_li_last', $store.state.locale) }};
                            </li>
                            <li> 
                                {{ $t('importModal.description.fifth_li_first', $store.state.locale) }}
                                <span class="text-blue-500 dark:text-blue-400">{{ $t('product.addProducts', $store.state.locale) }}</span>
                                {{ $t('importModal.description.fifth_li_last', $store.state.locale) }}.
                            </li>
                        </ul>
                        <div class="flex w-full items-center justify-start mt-8">
                            <button 
                                @click.prevent="openInputFile()"
                                class="bg-blue-500 hover:bg-blue-300 dark:hover:bg-blue-700 duration-300 rounded text-white px-4 py-2 flex  items-center justify-center space-x-1">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 fill-current"><path d="M21,14a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15a1,1,0,0,0-2,0v4a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V15A1,1,0,0,0,21,14Zm-9.71,1.71a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l4-4a1,1,0,0,0-1.42-1.42L13,12.59V3a1,1,0,0,0-2,0v9.59l-2.29-2.3a1,1,0,1,0-1.42,1.42Z"/></svg>
                                <span>{{ $t('actions.clickToImport', $store.state.locale) }}</span> 
                            </button>
                        </div>
                    </div>
                    <div v-else class="flex flex-col items-center justify-center space-y-5 p-3">
                        <span class="text-xl font-bold">
                            File: {{ csv_file.name }}
                        </span>
                    </div>
                    <!-- Products List -->
                    <div v-if="csv_file" class="flex items-center justify-center w-full relative overflox-x-hidden overflow-y-auto overscroll-y-contain h-56 p-3">
                        <div v-if="products_status === 'columnError'" class="flex w-full items-center justify-center font-semibold text-red-500">
                            {{ $t('searchModal.columnError', $store.state.locale) }}
                        </div>
                        <div v-if="products.length && products_status !== 'loading'" class="absolute inset-0  flex flex-col space-y-3 overflw-x-hidden overflow-y-auto">
                            <table>
                                <thead class="border-b-2 dark:border-gray-700">
                                    <tr>
                                        <td class="p-2 text-sm">
                                            {{ $t('productTable.cols.name', $store.state.locale) }}
                                        </td>
                                        <td class="p-2 text-sm">
                                            {{ $t('productTable.cols.formulator', $store.state.locale) }}
                                        </td>
                                        <td class="p-2 text-sm">
                                            PID
                                        </td>
                                        <td class="p-2 text-sm">
                                            {{ $t('productTable.cols.quantity', $store.state.locale) }} (Kg)
                                        </td>
                                        <td class="p-2 text-sm">
                                            {{ $t('productTable.cols.reference_date', $store.state.locale) }}
                                        </td>
                                        <td class="p-2 text-sm">
                                            
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ImportModalRow
                                        v-for="(m, i) in products"
                                        :key="i"
                                        :product="m"
                                    />
                                </tbody>
                            </table>
                        </div>
                        <div v-else-if="products.length === 0 && products_status === 'loaded'" class="flex w-full items-center justify-center">
                            {{ $t('searchModal.initMessage', $store.state.locale) }}
                        </div>
                        <div v-if="products_status === 'loading'" class="flex w-full items-center justify-center">
                            <svg class="animate-spin w-12 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
                        </div>
                    </div>
                    <div v-if="products.length" class="flex items-center justify-end space-x-3 p-3">
                        <button 
                            @click.prevent="cancelImport"
                            class="px-5 py-3 font-medium leading-5 text-center text-blue-500 dark:text-blue-400 hover:bg-gray-200 dark:hover:bg-gray-800 rounded lg:mt-0 duration-300 lg:w-auto"
                            :title="$t('actions.cancel', $store.state.locale)">
                            {{ $t('actions.cancel', $store.state.locale) }}
                        </button>
                        <button 
                            @click.prevent="storeList"
                            class="px-5 py-3 font-medium leading-5 text-center text-white rounded lg:mt-0 duration-300 lg:w-auto"
                            :class="setSubmitClasses"
                            :disabled="setSubmitDisabled"
                            v-html="setSubmitContent">
                        </button>
                    </div>
                </div>
                <div v-else class="p-3 flex flex-col space-y-7">
                    <h2 class="text-lg flex items-center space-x-1 px-3 dark:text-gray-200">
                        <span>{{ $t('importModal.imported_products_time', $store.state.locale) }}</span> 
                    </h2>
                    <div class="flex flex-col space-y-4">
                        <PeriodComponent 
                            v-for="(o, i) in importedDates" :key="i"
                            :period="o" 
                            @closeModalWithDate="closeModalWithDate"
                            @closeModal="emits('closeModal')"
                            @closeModalAfterImport="emits('newProducts')"/>
                        <div 
                            v-if="unimportableProducts"
                            class="flex flex-col space-y-1 p-3 shadow rounded text-red-500">
                            <span>{{ $t('importModal.not_imported_products', $store.state.locale) }}: {{ unimportableProducts }}</span>
                            <span>{{ $t('importModal.not_imported_tips', $store.state.locale) }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>