import axios from 'axios';
import {store} from "../store";
import router from '../routing/router';

let zdhcApiClient;

export const createZdhcApiClient = (options) => 
{
  let baseUrl = process.env.VUE_APP_ENV === 'local' ? process.env.VUE_APP_ZDHC_BETA_URL : process.env.VUE_APP_ZDHC_PROD_URL;
  zdhcApiClient = axios.create({ 
    baseURL: `//${baseUrl}/Gateway/rest/`,
    'Content-Type': 'application/json',
    'Accept' : 'application/json',
    ...options
  });
  
  // Request Interceptor
  zdhcApiClient.interceptors.request.use( config => 
  {
    if(!config.url.includes('oauth/tokenbykey'))
    {
      if(!config.headers.common.Authorization && !config.headers.common.accessToken) 
      {
        axios.get(`//${baseUrl}/oauth/tokenbykey`)
        .then( res => 
          {
            if(res.data?.token?.tokenType && res.data?.token?.tokenType === 'bearer')
            {
              zdhcApiClient.defaults.headers.common['Authorization'] = `Bearer ${res.data.token.accessToken}`;
              zdhcApiClient.defaults.headers.common['accessToken'] = `${res.data.token.accessToken}`;
            }
            return config
          });
      }
    }
    return config;
  });

  // Response Interceptor
  zdhcApiClient.interceptors.response.use(
      (response) => {
        return response;
      },
      function (e) {
        if( e.response &&[401, 419].includes(e.response.status) ) 
        {
          //store.commit("auth/CLEAR_STATE");
        }
        else if(e.response && e.response.status === 422)
        {
          if(e.response.data && e.response.data.errors)
          {
            //store.commit('errors/SET_ERRORS', e.response.data.errors);
          }
          return 422;
        }
        else if(e.status === 422)
        {
          return 422;
        }
        let path = '/';
          switch(e.response.status){
            case 419,401: path = "signin"; break;
            case 403: path = "forbidden"; break;
            case 404: path = "notFound"; break;
            case 500: path = "InternalServerError"; break;
            case 503: path = "ServiceUnavailable"; break;
          }
        router.push(`/${store.state.locale}/${path}`);
        return e;
      } 
  );
};


export const useZdhcAPIClient = (options) =>
{
  if (!zdhcApiClient) {
    createZdhcApiClient(options)
  }
  return zdhcApiClient
}