import axios from 'axios';
import { store } from '../store';
import router from '../routing/router';

let apiClient;
export const createApiClient = () => 
{
  if(process.env.VUE_APP_API_ENV === 'laravel') axios.get(`//${process.env.VUE_APP_LUMEN_URL}/sanctum/csrf-cookie`, {withCredentials: true});
  apiClient = axios.create({ 
    withCredentials: process.env.VUE_APP_API_ENV === 'laravel',
    baseURL: `//${process.env.VUE_APP_LUMEN_URL}/v1`,
    headers: {
      'Content-Type': 'application/json',
      'Accept' : 'application/json'
    }
  });
  
  // Request Interceptor
  apiClient.interceptors.request.use((config) => 
  {
    if(process.env.VUE_APP_API_ENV === 'lumen' && store.state.auth.user && store.state.auth.token && !config.headers.common.Authorization) 
    {
      config.headers = Object.assign(Object.assign({}, config.headers), {
        Accept: `application/json`,
        Language: store.state.locale,
        'Accept-Language': `it`,
        'Content-Type': `application/json`,
        Authorization: `Bearer ${store.state.auth.token}`
      })
    }
    else 
    {
      config.headers = Object.assign(Object.assign({}, config.headers), {
        Accept: `application/json`,
        Language: store.state.locale,
        'Accept-Language': `it`,
        'Content-Type': `application/json`
      })
    }

        
    return config
  });
  // Response Interceptor
  apiClient.interceptors.response.use(
    (response) => {
      return response;
    },
    function (e) 
    {
      if(e.response)
      {
        if( e.response &&[401, 419].includes(e.response.status) ) 
        {
          store.commit("auth/CLEAR_STATE");
        }
        else if((e.response && e.response.status === 422) || e.status === 422)
        {
          if(e.response.data )
          {
            store.commit('errors/SET_ERRORS', e.response.data);
          }
          return 422;
        }

        let path = '/';
          switch(e.response.status){
            case 419,401: path = "signin"; break;
            case 403: path = "forbidden"; break;
            case 404: path = "notFound"; break;
            case 500: path = "InternalServerError"; break;
            case 503: path = "ServiceUnavailable"; break;
          }
        router.push(`/${store.state.locale}/${path}`);
      }
      return e;
    } 
  );
};

export const useApi = () => 
{
  if(!apiClient) createApiClient();
  return apiClient
};