<template>
    <div class="relative min-h-screen flex lg:items-center justify-start">
        <img src="https://source.unsplash.com/w1_4YH5IhDg/1260x750" class="absolute inset-0 object-cover w-full h-full" alt="" />
        <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-start"></div>
        <div class="relative flex items-center justify-start h-full w-full"> 
            <div  class="lg:w-1/2 p-5">   
                <div class="w-full max-w-xl xl:px-8">
                    <div class="bg-white rounded shadow-2xl p-7 sm:p-10">
                        <router-link :to="`/${$store.state.locale}/`" title="Home" class="flex items-center justify-center">
                            <img src="@/assets/img/logo/logo-ympact.png" class="w-36">
                        </router-link>
                        <h3 class="mb-4 text-4xl text-center">
                            {{ $t('auth.reset-password.title', $store.state.locale) }}
                        </h3>
                        <!-- Step 1 -->
                        <div v-if="step === 1">
                            <h2 class="mb-4 text-3xl text-center">
                                {{ $t('auth.reset-password.step-one.title', $store.state.locale) }}
                            </h2>
                            <hr class="my-5">
                            <p>
                                {{ $t('auth.reset-password.step-one.body', $store.state.locale) }}
                            </p>
                            <span
                                v-if="step_one_error"
                                class="block text-sm text-red-500 text-center lg:text-left mx-auto my-3" >
                                {{ step_one_error }}
                            </span>

                            <div class="my-5">
                                <input id="email" v-model="fields.email" name="email" placeholder="Email" type="email" 
                                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border rounded shadow-sm appearance-none focus:shadow-outline"
                                    :class="step_one_error ? 'border-red-500 bg-red-50' : 'border-gray-300 focus:border-blue-500'"/>
                            </div>
                            <div class="mt-4 mb-2 sm:mb-4 flex items-center justify-end">
                                <button 
                                    type="button"
                                    @click.prevent="sendCode" 
                                    class="inline-flex items-center justify-center max-w-max h-12 px-6 font-medium tracking-wide text-white transition duration-300 rounded shadow-md focus:shadow-outline focus:outline-none"
                                    :class="setSendCondeButtonClasses"
                                    :disabled="send_code_status !== 'void'"
                                    :title="$t('auth.reset-password.step-one.send-button', $store.state.locale)" v-html="setSendCondeButtonContent">
                                </button>
                            </div>
                        </div>
                        <!-- Step 2 -->
                        <div v-if="step === 2">
                            <h2 class="mb-4 text-3xl text-center">
                                {{ $t('auth.reset-password.step-two.title', $store.state.locale) }}
                            </h2>
                            <hr class="my-5">
                            <p>
                                {{ $t('auth.reset-password.step-two.body', $store.state.locale) }}
                            </p>
                            <span
                                v-if="step_two_error"
                                class="block text-sm text-red-500 text-center lg:text-left mx-auto my-3" >
                                {{ step_two_error }}
                            </span>
                            <div class="my-5">
                                <input id="code" v-model="fields.code" name="code" placeholder="12345" type="text" 
                                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border rounded shadow-sm appearance-none focus:shadow-outline"
                                    :class="step_two_error ? 'border-red-500 bg-red-50' : 'border-gray-300 focus:border-blue-500'"/>
                            </div>
                            <div class="mt-4 mb-2 sm:mb-4 flex items-center justify-between space-x-5">
                                <button 
                                    type="button"
                                    @click.prevent="step = 1" 
                                    class="inline-flex items-center justify-center max-w-max h-12 px-6 font-medium tracking-wide text-white transition duration-300 rounded focus:outline-none flex items-center justify-center space-x-2 text-blue-500 hover:bg-gray-200"
                                    :title="$t('auth.reset-password.step-two.back-button', $store.state.locale)">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"/></svg>
                                    {{ $t('auth.reset-password.step-two.back-button', $store.state.locale) }}
                                </button>
                                <button 
                                    type="button"
                                    @click.prevent="verifyCode" 
                                    class="inline-flex items-center justify-center max-w-max h-12 px-6 font-medium tracking-wide text-white transition duration-300 rounded shadow-md focus:shadow-outline focus:outline-none"
                                    :class="setVerifyCondeButtonClasses"
                                    :disabled="verify_code_status !== 'void'"
                                    :title="$t('auth.reset-password.step-two.next-button', $store.state.locale)" v-html="setVerifyCondeButtonContent">
                                </button>
                            </div>
                        </div>
                        <!-- Step 3 -->
                        <form v-if="step === 3" @submit.prevent="saveNewPassword">
                            <h2 class="mb-4 text-3xl text-center">
                                {{ $t('auth.reset-password.step-three.title', $store.state.locale) }}
                            </h2>
                            <hr class="my-5">
                            <p>
                                {{ $t('auth.reset-password.step-three.body', $store.state.locale) }}
                            </p>
                            <span
                                v-if="step_three_error"
                                class="block text-sm text-red-500 text-center lg:text-left mx-auto my-3" >
                                {{ step_three_error }}
                            </span>

                            <div class="my-5">
                                <input id="password" v-model="fields.password" name="password" placeholder="Password" type="password" 
                                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border rounded shadow-sm appearance-none focus:shadow-outline"
                                    :class="step_three_error ? 'border-red-500 bg-red-50' : 'border-gray-300 focus:border-blue-500'"/>
                            </div>
                            <div class="mt-4 mb-2 sm:mb-4 flex items-center justify-between space-x-5">
                                <button 
                                    type="button"
                                    @click.prevent="step = 2" 
                                    class="inline-flex items-center justify-center max-w-max h-12 px-6 font-medium tracking-wide text-white transition duration-300 rounded focus:outline-none flex items-center justify-center space-x-2 text-blue-500 hover:bg-gray-200"
                                    :title="$t('auth.reset-password.step-three.back-button', $store.state.locale)">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"/></svg>
                                    {{ $t('auth.reset-password.step-three.back-button', $store.state.locale) }}
                                </button>
                                <button 
                                    type="submit"
                                    class="inline-flex items-center justify-center max-w-max h-12 px-6 font-medium tracking-wide text-white transition duration-300 rounded shadow-md focus:shadow-outline focus:outline-none"
                                    :class="setSaveButtonClasses"
                                    :disabled="save_password_status !== 'void'"
                                    :title="$t('auth.reset-password.step-three.next-button', $store.state.locale)" v-html="setSaveButtonContent">
                                </button>
                            </div>
                        </form>
                        <p class="text-xs text-gray-600 sm:text-sm text-center">
                            <router-link
                                :to="`/${$store.state.locale}`"
                                :title="$t('errors.404.back', $store.state.locale)"
                                class="text-light-green-500 hover:underline"
                            >
                                {{ $t('errors.404.back', $store.state.locale) }}
                            </router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import SnackbarComposables from '../../composables/SnackbarComposables';
import AuthService from '../../services/AuthService';
import moment from 'moment';
export default 
{
    setup()
    {
        /* Data */
        const router = useRouter();
        const store = useStore();
        const { t } = useI18n();
        const fields = reactive({
            email: '',
            code: '',
            password: '',
        });
        const step = ref(1);
        const send_code_status = ref('void');
        const verify_code_status = ref('void');
        const save_password_status = ref('void');
        const step_one_code = ref('');
        const step_one_code_expires_date = ref(null);
        const step_one_error = ref('');
        const step_two_error = ref('');
        const step_three_error = ref('');
        const {sendCode: sendResetPasswordCode, resetPassword} = AuthService();


        const { addSnackbar } = SnackbarComposables();

        /* -------------------------------------- Computed ----------------------------------------------- */
        const setSendCondeButtonContent = computed( () => 
        {
            switch(send_code_status.value)
            {
                case 'void':
                    return t('auth.reset-password.step-one.send-button', store.state.locale);
                case 'loading':
                    return `<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
                case 'success':
                    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
                case 'error':
                    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
                default:
                    return t('auth.reset-password.step-one.send-button', store.state.locale);
            }
        });
        const setSendCondeButtonClasses = computed( () =>
        {
            switch(send_code_status.value)
            {
                case 'void': case 'loading': default:
                    return 'bg-blue-500 hover:bg-blue-300';
                case 'error':
                    return `bg-red-500 hover:bg-red-700`;
                case 'success':
                    return `bg-green-500 hover:bg-green-700`;
            }
        });
        const setVerifyCondeButtonContent = computed( () => 
        {
            switch(verify_code_status.value)
            {
                case 'void':
                    return t('auth.reset-password.step-two.next-button', store.state.locale);
                case 'loading':
                    return `<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="ntwo" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
                case 'success':
                    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
                case 'error':
                    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
                default:
                    return t('auth.reset-password.step-two.next-button', store.state.locale);
            }
        });
        const setVerifyCondeButtonClasses = computed( () =>
        {
            switch(verify_code_status.value)
            {
                case 'void': case 'loading': default:
                    return 'bg-blue-500 hover:bg-blue-300';
                case 'error':
                    return `bg-red-500 hover:bg-red-700`;
                case 'success':
                    return `bg-green-500 hover:bg-green-700`;
            }
        });
        const setSaveButtonContent = computed( () => 
        {
            switch(save_password_status.value)
            {
                case 'void':
                    return t('auth.reset-password.step-three.next-button', store.state.locale);
                case 'loading':
                    return `<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="ntwo" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
                case 'success':
                    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
                case 'error':
                    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
                default:
                    return t('auth.reset-password.step-three.next-button', store.state.locale);
            }
        });
        const setSaveButtonClasses = computed( () =>
        {
            switch(save_password_status.value)
            {
                case 'void': case 'loading': default:
                    return 'bg-blue-500 hover:bg-blue-300';
                case 'error':
                    return `bg-red-500 hover:bg-red-700`;
                case 'success':
                    return `bg-green-500 hover:bg-green-700`;
            }
        });

        /* Methods */
        const sendCode = async() => 
        {  
            send_code_status.value = 'loading';
            step_one_error.value = '';
            if(!fields.email)
            {
                return addSnackbar('warning', t('auth.reset-password.step-one.insert-email', store.state.locale));
            }
            const res = await sendResetPasswordCode(fields.email);
            if(res.userNotFound) 
            {
                send_code_status.value = 'error'; 
                step_one_error.value = t('auth.reset-password.step-one.user-not-found', store.state.locale);
            }
            else if(res.code)
            {
                send_code_status.value = 'success'; 
                step_one_code.value = res.code;
                step_one_code_expires_date.value = new Date();
                step.value = 2;
            }

            setTimeout( () => 
            {
                send_code_status.value = 'void';
            }, 2000);
        };
        const verifyCode = () => 
        {
            step_two_error.value = '';
            verify_code_status.value = 'loading';
            //const verify_code_expyres_at = moment(new Date()).add(30, 'minutes');
            if(fields.code === step_one_code.value && moment(new Date()).isBefore(moment(step_one_code_expires_date.value).add(30, 'minutes')))
            {
                verify_code_status.value = 'success';
                step.value = 3;
            }
            else 
            {
                verify_code_status.value = 'error';
                step_two_error.value = t('auth.reset-password.step-two.wrong-code', store.state.locale);
            }
            setTimeout( () => 
            {
                verify_code_status.value = 'void';
            }, 2000);
        }
        const saveNewPassword = async () => 
        {
            if(!fields.password)
            {
                return addSnackbar('warning', t('auth.reset-password.step-three.insert-password', store.state.locale));
            }
            else 
            {
                save_password_status.value = 'loading';
                const res = await resetPassword(fields);
                if(res.userNotFound) 
                {
                    send_code_status.value = 'error'; 
                    setTimeout( () => save_password_status.value = 'void', 2000);
                    step_three_error.value = t('auth.reset-password.step-one.user-not-found', store.state.locale);
                }
                else
                {
                    addSnackbar('success', t('auth.reset-password.updatedPassowrd', store.state.locale));
                    router.push(`/${store.state.locale}/signin`)
                }

            }
        }

        return {
            step,
            send_code_status,
            verify_code_status,
            save_password_status,
            step_one_error,
            step_two_error,
            step_three_error,
            fields,
            setSendCondeButtonContent,
            setSendCondeButtonClasses,
            setVerifyCondeButtonContent,
            setVerifyCondeButtonClasses,
            setSaveButtonContent,
            setSaveButtonClasses,
            /* signin, */
            router,
            sendCode,
            verifyCode,
            saveNewPassword
        }
    }
}
</script>

