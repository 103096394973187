<template>
    <div class="min-h-screen py-10 px-5 lg:p-10 lg:overflow-x-hidden">
        <DashboardNavbar 
            @newProds="tableInit"
        />
        <ProductsTable v-if="$store.state.auth.user /* && $store.state.auth.token */" ref="table"/>
    </div>
</template>
<script>
import DashboardNavbar from '../layouts/navbars/DashboardNavbar.vue';
import { defineAsyncComponent, ref } from 'vue';
export default 
{
    components:
    {
        DashboardNavbar,
        ProductsTable: defineAsyncComponent( () => import('../sections/dashboard/ProductsTable.vue'))
    },
    setup()
    {
        const table = ref(null);
        const tableInit = () => 
        {
            table.value.init()
        }

        return {
            table,
            tableInit
        }
    }
}
</script>
