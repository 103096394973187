<template>
    <div class="min-h-screen py-10 px-5 lg:p-10 lg:overflow-x-hidden flex flex-col space-y-14">
    <!--<Breadcrumb />-->
        <div class="flex items-center w-full space-x-3 justify-between">
            <div class="flex items-center justify-start space-x-1">
                <router-link 
                    class="duration-300"
                    :class="$route.name.toLowerCase() === 'dashboard' ? 'text-blue-500' : 'text-gray-400 hover:text-blue-500'"
                    title="Dashboard"
                    :to="`/${$store.state.locale}/dashboard`">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 fill-current"><path d="M20,8h0L14,2.74a3,3,0,0,0-4,0L4,8a3,3,0,0,0-1,2.26V19a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V10.25A3,3,0,0,0,20,8ZM14,20H10V15a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1Zm5-1a1,1,0,0,1-1,1H16V15a3,3,0,0,0-3-3H11a3,3,0,0,0-3,3v5H6a1,1,0,0,1-1-1V10.25a1,1,0,0,1,.34-.75l6-5.25a1,1,0,0,1,1.32,0l6,5.25a1,1,0,0,1,.34.75Z"/></svg>
                </router-link>
                <div
                    class="flex items-center justify-start space-x-1">
                    <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current text-gray-400"><path d="M14.83,11.29,10.59,7.05a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41L12.71,12,9.17,15.54a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l4.24-4.24A1,1,0,0,0,14.83,11.29Z"/></svg>
                    <router-link 
                        class="duration-300 text-blue-500"
                        :to="`/${$store.state.locale}/product/create`">
                        {{ $t('product.update.title', $store.state.locale) }} 
                    </router-link>
                </div>
            </div>

            <div class="flex items-center justify-end space-x-3">
                <router-link 
                    :to="`/${$store.state.locale}/dashboard`"
                    class="bg-blue-500 text-white rounded border px-4 py-2 border-blue-500 hover:bg-blue-300 duration-300 flex items-center justify-center space-x-1"
                    :title="$t('dashnav.button.productList', $store.state.locale)">
                    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"/></svg>
                    <span class="text-sm">{{ $t('dashnav.button.productList', $store.state.locale) }}</span>
                </router-link>
            </div>
        </div>
        <EditSection class="py-16"/>
       
    </div>
</template>
<script>
//import Breadcrumb from '../../layouts/Breadcrumb';
import EditSection from '../../sections/product/edit/EditSection';
export default 
{
    components:
    {
        //Breadcrumb
        EditSection
    }
}

</script>
