<template>
    <div class="fixed inset-0 p-6 bg-gray-1000 bg-opacity-90 z-30 overscroll-y-contain overflow-y-auto text-blue-500">
        <div class="flex flex-col w-full h-full items-center justify-center">
            <div class="bg-white dark:bg-gray-900 dark:text-gray-400 border dark:border-gray-700 rounded p-5 flex flex-col space-y-2 w-full md:w-2/3 lg:w-1/2 2xl:w-1/2 max-h-xl overflow-y-auto">
                <div class="flex items-start justify-between">
                    <button 
                        title="Chiudi"
                        @click.prevent="$emit('closeModal')"
                        class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                                <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                    c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                    c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                            </svg>
                    </button>
                </div>
                <h1 class="text-xl flex flex-col text-left space-y-1 dark:text-gray-200">
                    <span>{{ $t('waitingPage.keyHelp.title', $store.state.locale) }}</span> 
                    <br>
                    <span class="text-sm">{{ $t('waitingPage.keyHelp.body', $store.state.locale) }}</span> 
                </h1>
                <div class="flex flex-col space-y-5">
                    <!-- First Section -->
                    <button 
                        class="w-full flex  items-center justify-between space-x-2 border rounded p-2 hover:bg-gray-100 duration-300"
                        @click.prevent="toggleFirstSection">
                        <h2 class="text-lg font-semibold">
                            {{ $t('waitingPage.keyHelp.haveAnAccount', $store.state.locale) }}
                        </h2>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 min-w-5 fill-current"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/></svg>
                    </button>
                    <div v-if="showFirstSection" class="flex flex-col w-full space-y-3 p-2 border rounded">
                        <h1 class="text-lg font-semibold">
                            {{ $t('waitingPage.keyHelp.firstSection.title', $store.state.locale) }}
                        </h1>
                        <h2 class="text-base">
                            1 - {{ $t('waitingPage.keyHelp.firstSection.first', $store.state.locale) }}
                            <a href="https://www.my-aip.com/ZDHCGateway/" target="_blank" class="font-semibold text-light-green-500 hover:underline">www.my-aip.com/ZDHCGateway</a>
                        </h2>
                        <div class="flex flex-col space-y-1">
                            <h2 class="text-base">
                                2 - {{ $t('waitingPage.keyHelp.firstSection.second', $store.state.locale) }}
                            </h2>
                            <img src="@/assets/img/KeyHelpModal/firstImage.png" class="w-full">
                        </div>
                        <div class="flex flex-col space-y-1">
                            <h2 class="text-base">
                                3 - {{ $t('waitingPage.keyHelp.firstSection.third', $store.state.locale) }}
                            </h2>
                            <img src="@/assets/img/KeyHelpModal/secondImage.png" class="max-h-sm object-cover">
                        </div>
                        <div class="flex flex-col space-y-1">
                            <h2 class="text-base">
                                4 - {{ $t('waitingPage.keyHelp.firstSection.fourth', $store.state.locale) }}
                            </h2>
                            <img src="@/assets/img/KeyHelpModal/thirdImage.png" class="w-full object-cover">
                        </div>
                        <h2 class="text-base">
                            5 - {{ $t('waitingPage.keyHelp.firstSection.fifth', $store.state.locale) }}
                        </h2>
                    </div>
                    <!-- Second Section -->
                     <button 
                        class="w-full flex  items-center justify-between space-x-2 border rounded p-2 hover:bg-gray-100 duration-300"
                        @click.prevent="toggleSecondSection">
                        <h2 class="text-lg font-semibold">
                            {{ $t('waitingPage.keyHelp.dontHaveAnAccount', $store.state.locale) }}
                        </h2>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 min-w-5 fill-current"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/></svg>
                    </button>
                    <div v-if="showSecondSection" class="w-full p-2 border rounded">
                        <p class="text-base">
                           {{ $t('waitingPage.keyHelp.secondSection.body', $store.state.locale) }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {ref} from 'vue';
export default 
{
    emits:['closeModal'],
    setup()
    {
        /**
         *
         * Data
         *
         */
        const showFirstSection = ref(false);
        const showSecondSection = ref(false);

        /**
         *
         * Methods
         *
         */
        const toggleFirstSection = () => 
        {
            showFirstSection.value = !showFirstSection.value;
            showSecondSection.value = false;
        };
        const toggleSecondSection = () => 
        {
            showFirstSection.value = false;
            showSecondSection.value = !showSecondSection.value;
        };

        return {
            /* Data */
            showFirstSection,
            showSecondSection,
            /* Methods */
            toggleFirstSection,
            toggleSecondSection
        }
    }
}
</script>