<script setup>
import { defineEmits, defineProps, onMounted, onUnmounted } from 'vue';
import { useStore} from 'vuex';
/* Emits */
const emits = defineEmits(['closeModal']);
/* Props */
const props = defineProps({
    product:{required:true}
});
/* Data */
const store = useStore();
/* Hooks */
onMounted( () => document.body.classList.add('hoverflow-hidden'));
onUnmounted( () => document.body.classList.remove('hoverflow-hidden'));
</script>
<template>
    <div
        @click.prevent="emits('closeModal')" 
        class="fixed inset-0 p-6 bg-gray-1000 bg-opacity-90 z-30 overscroll-y-contain overflow-y-auto">
        <div class="flex flex-col w-full h-full items-center justify-center">
            <div
                @click.stop 
                class="bg-white dark:bg-gray-900 border dark:border-gray-700 rounded p-5 flex flex-col space-y-5 w-full md:w-2/3 lg:w-1/2 2xl:w-1/3 dark:text-gray-400">
                <div class="flex items-center justify-end">
                    <button 
                        :title="$t('actions.close', store.state.locale)"
                        @click.prevent="emits('closeModal')"
                        class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                                <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                    c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                    c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                            </svg>
                    </button>
                </div>
               
                <div class="flex flex-col space-y-5 h-72 overflow-y-auto overscroll-contain text-sm justify-center text-center">
                    <h3 class="text-2xl font-semibold">
                        {{ $t('noMatchModal.title', store.state.locale) }}
                    </h3>
                    <p class="text-lg" v-html="$t('noMatchModal.body', store.state.locale).replace('_pid', props.product.pid)"></p>
                </div>
                <div class="flex items-center justify-end space-x-3">
                    <button 
                        :title="$t('actions.close', store.state.locale)"
                        @click.prevent="emits('closeModal')"
                        class="px-5 py-3 font-medium leading-5 text-center text-blue-500 bg-white dark:bg-gray-800 dark:text-blue-400 rounded-2xl lg:mt-0 hover:bg-gray-100 dark:hover:bg-gray-700 duration-300 lg:w-auto">
                        {{ $t('actions.close', store.state.locale) }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
