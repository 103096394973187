import axios from 'axios';
let googleApiClient;
export const createGoogleApiClient = () => {
    googleApiClient = axios.create({ 
        baseURL: `//${process.env.VUE_APP_GOOGLE_TRANSLATE_URL}/`,
        headers: 
        {
          'Content-Type': 'application/json',
          'Accept' : 'application/json'
        }
      });
};

export const useGoogleApi = () => 
{
  if(!googleApiClient) createGoogleApiClient();
  return googleApiClient
};