<script setup>
import { defineProps, defineEmits } from 'vue';
import { ref } from '@vue/reactivity';
import { watch } from '@vue/runtime-core';
/* emtis */
const emits = defineEmits(['fetch']);
/* Props */
const props = defineProps({
    links : { required: true},
    current_page : { required: true},
    total : { required: true},
    last_page : { required: false}
});
/* Watch */
watch(
    () => props.current_page,
    () => checkPages()
);
/* Data */
const actualLinks = ref([]);
const showedPages = ref([]);
const firstPage = ref(null);
const previousPage = ref(null);
const nextPage = ref(0);
const lastPage = ref(0);
const plainPath = ref('');
const fullParams = ref('');
/* Methods */
const init = () => 
{
    plainPath.value = props.links.filter( l => l.url)[0].url.split('?')[0];
    let params = props.links.filter( l => l.url)[0].url.split('?')[1];
    fullParams.value = new URLSearchParams(params);
};
const checkPages = () => 
{
    init();
    let pagesToShow = 5;
    // if current_page is < pagesToShow, get the firsts "pagesToShow" pages
    if(props.current_page < pagesToShow)
    {
        for(let i = 1; i <= pagesToShow; i++)
        {
            if(i <= props.last_page) showedPages.value.push(i);
        }
    }
    // else if current_page is in the lasts "pagesToShow" pages, get the lasts "pagesToShow" pages
    else if(props.current_page > props.last_page - pagesToShow && props.current_page <= props.last_page)
    {
        for(let i = props.last_page - pagesToShow + 1; i <= props.last_page; i++)
        {
            if(i <= props.last_page) showedPages.value.push(i);
        }
    }
    // else, get current_page, the previous 2 pages and the next 2 pages
    else
    {
        if(props.current_page - 2 > 0) showedPages.value.push(props.current_page - 2);
        if(props.current_page - 1 > 0) showedPages.value.push(props.current_page - 1);
        showedPages.value.push(props.current_page);
        if(props.current_page + 1 <= props.last_page) showedPages.value.push(props.current_page + 1);
        if(props.current_page + 2 <= props.last_page) showedPages.value.push(props.current_page + 2);
    }
    
    /* Setting other button */
    firstPage.value = 1;
    lastPage.value = props.last_page;

    if(props.current_page - 1 > 0) previousPage.value = props.current_page - 1;
    else previousPage.value = 0;
    
    if(props.current_page + 1 <= props.last_page) nextPage.value = props.current_page + 1;
    else nextPage.value = 0;
};

const setFetchUrl = (page = 1) => 
{
    if(fullParams.value.has('page')) fullParams.value.set('page', page);
    else fullParams.value.append('page', page);
    
    return emits('fetch', `${plainPath.value}?${fullParams.value}`);
};
/* Hooks */
actualLinks.value = [...props.links];
actualLinks.value .shift();
actualLinks.value .pop();
if(props.last_page) checkPages();
</script>
<template>
    <div v-if="links" class="flex items-center justify-between w-full py-2 space-x-5 text-blue-500">
        <div class="border rounded-md p-2 bg-white">
            <span class="capitalize">
                {{ $t('tablePagination.page', $store.state.locale) }}
            </span> <span class="font-bold">{{ current_page }}</span> / {{ last_page  }}
        </div>

        <div class="flex items-center justify-center space-x-1">
            <button 
                v-if="firstPage"
                class="px-1 w-10 h-7 py-0.5 border rounded bg-zinc-300  flex items-center justify-center"
                :class="firstPage == current_page ? 'bg-gray-100 text-gray-400 border-gray-300 cursor-not-allowed' : 'text-blue-500'"
                :disabled="firstPage == current_page"
                @click.prevent="setFetchUrl(firstPage)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 min-w-5 fill-current"><path d="M11.46,8.29a1,1,0,0,0-1.42,0l-3,3a1,1,0,0,0,0,1.42l3,3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.16,12l2.3-2.29A1,1,0,0,0,11.46,8.29ZM14.66,12,17,9.71a1,1,0,0,0-1.42-1.42l-3,3a1,1,0,0,0,0,1.42l3,3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>
            </button>
            <button 
                class="px-1 w-10 h-7 py-0.5 border rounded bg-zinc-300 flex items-center justify-center"
                :class="previousPage == 0 ? 'bg-gray-100 text-gray-400 border-gray-300 cursor-not-allowed' : 'text-blue-500'"
                :disabled="previousPage == 0"
                @click.prevent="setFetchUrl(previousPage)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 min-w-5 fill-current"><path d="M11.29,12l3.54-3.54a1,1,0,0,0,0-1.41,1,1,0,0,0-1.42,0L9.17,11.29a1,1,0,0,0,0,1.42L13.41,17a1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41Z"/></svg>
            </button>
            <button 
                v-for="p in showedPages" :key="p" 
                class="px-1 w-10 h-7 py-0.5 border rounded flex items-center justify-center"
                :class="current_page === p ? 'bg-light-green-500 text-white' : 'text-blue-500'"
                @click.prevent="setFetchUrl(p)">
                <span>{{p}}</span>
            </button>
            <button 
                class="px-1 w-10 h-7 py-0.5 border rounded bg-zinc-300 flex items-center justify-center"
                :class="nextPage == 0 ? 'bg-gray-100 text-gray-400 border-gray-300 cursor-not-allowed' : 'text-blue-500'"
                :disabled="nextPage == 0"
                @click.prevent="setFetchUrl(nextPage)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 min-w-5 fill-current"><path d="M14.83,11.29,10.59,7.05a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41L12.71,12,9.17,15.54a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l4.24-4.24A1,1,0,0,0,14.83,11.29Z"/></svg>
            </button>
            <button 
                v-if="lastPage"
                class="px-1 w-10 h-7 py-0.5 border rounded bg-zinc-300 flex items-center justify-center"
                :class="lastPage == current_page ? 'bg-gray-100 text-gray-400 border-gray-300 cursor-not-allowed' : 'text-blue-500'"
                :disabled="lastPage == current_page"
                @click.prevent="setFetchUrl(last_page)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 min-w-5 fill-current"><path d="M8.46,8.29A1,1,0,1,0,7,9.71L9.34,12,7,14.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l3-3a1,1,0,0,0,0-1.42Zm8.5,3-3-3a1,1,0,0,0-1.42,1.42L14.84,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l3-3A1,1,0,0,0,17,11.29Z"/></svg>
            </button>
        </div>
    </div>
</template>