function load (component) {
    return () => import( `../${component}.vue`)
}
import * as guard from '../config/guards';
import multiguard   from 'vue-router-multiguard';
export default 
[
    {
        path: '',
        /* component: load('pages/Welcome'),
        name: 'Welcome', */
        redirect:{name:'Dashboard'},
        meta:
        {
            layout: 'welcome'
        }
    },
    {
        path: 'waiting-page',
        component: load('pages/loading/WaitingPage'),
        beforeEnter: multiguard([guard.AUTH]),
        name: 'WaitingPage',
        meta:
        {
            layout: 'auth'
        }
    },
    {
        path: 'algorithm',
        component: load('pages/Algorithm'),
        name: 'Algorithm',
        meta:
        {
            layout: 'dashboard'
        }
    },
    {
        path: 'dashboard',
        component: load('pages/Dashboard'),
        name: 'Dashboard',
        beforeEnter: multiguard([guard.AUTH, guard.HAS_KEYS, guard.HAS_ACTIVE_SUBSCRIPTION]),
        meta:
        {
            layout: 'dashboard'
        }
    },
    {
        path: 'report',
        component: load('pages/Report'),
        name: 'Report',
        beforeEnter: multiguard([guard.AUTH, guard.HAS_KEYS, guard.HAS_ACTIVE_SUBSCRIPTION]),
        meta:
        {
            layout: 'dashboard'
        }
    },
    {
        path: 'report/:inventory',
        component: load('pages/inventory/Show'),
        name: 'ReportShow',
        beforeEnter: multiguard([guard.AUTH, guard.HAS_KEYS, guard.HAS_ACTIVE_SUBSCRIPTION]),
        meta:
        {
            layout: 'dashboard'
        }
    },
    {
        path: 'settings',
        component: load('pages/Settings'),
        name: 'Settings',
        beforeEnter: multiguard([guard.AUTH, guard.HAS_KEYS, guard.HAS_ACTIVE_SUBSCRIPTION]),
        meta:
        {
            layout: 'dashboard'
        }
    },
    {
        path: 'product/create',
        component: load('pages/product/Create'),
        beforeEnter: multiguard([guard.AUTH, guard.HAS_KEYS, guard.HAS_ACTIVE_SUBSCRIPTION]),
        name: 'product.create',
        meta:
        {
            layout: 'dashboard',
            breadcrumb:
            [
                {
                    name: 'Dashboard',
                    route: '/dashboard'
                },
                {
                    name: 'Create',
                    route: '/product/create'
                }
            ]
        }
    },
    {
        path: 'product/edit/:product',
        component: load('pages/product/Edit'),
        beforeEnter: multiguard([guard.AUTH, guard.HAS_KEYS, guard.HAS_ACTIVE_SUBSCRIPTION]),
        name: 'product.edit',
        meta:
        {
            layout: 'dashboard',
            breadcrumb:
            [
                {
                    name: 'Dashboard',
                    route: '/dashboard'
                },
                {
                    name: 'Edit',
                    route: '/product/edit'
                }
            ]
        }
    },
    {
        path: 'signin',
        component: load('pages/auth/Signin'),
        name: 'Signin',
        beforeEnter: multiguard([guard.GUEST]),
        meta:
        {
            layout: 'auth'
        }
    },
    {
        path: 'oauth',
        component: load('pages/OAuthDocu'),
        name: 'OAuthDocu',
        meta:
        {
            layout: 'auth'
        }
    },
    {
        path: 'cross-signin',
        component: load('pages/auth/CrossAuth'),
        name: 'CrossSignin',
        //beforeEnter: multiguard([guard.GUEST]),
        meta:
        {
            layout: 'auth'
        }
    },
    {
        path: 'impersonate',
        component: load('pages/auth/ImpersonateLogin'),
        name: 'ImpersonateLogin',
        beforeEnter: multiguard([guard.GUEST]),
        meta:
        {
            layout: 'auth'
        }
    },
    {
        path: 'signup',
        component: load('pages/auth/Signup'),
        name: 'Signup',
        beforeEnter: multiguard([guard.GUEST]),
        meta:
        {
            layout: 'auth'
        }
    },
    {
        path: 'reset-password',
        component: load('pages/auth/ResetPassword'),
        name: 'ResetPassword',
        beforeEnter: multiguard([guard.GUEST]),
        meta:
        {
            layout: 'auth'
        }
    },
    {
        path: 'error/access-token',
        component: load('pages/errors/AccessTokenError'),
        name: 'AccessTokenError',
        meta: 
        {
            layout: 'auth'
        }
    },
    {
        path: 'notFound',
        component: load('pages/errors/404'),
        name: '404',
        meta: 
        {
            layout: 'dashboard'
        }
    },
    {
        path: 'forbidden',
        component: load('pages/errors/403'),
        name: '403',
        meta: 
        {
            layout: 'dashboard'
        }
    },
    {
        path: 'InternalServerError',
        component: load('pages/errors/500'),
        name: '500',
        meta: 
        {
            layout: 'dashboard'
        }
    },
    {
        path: 'ServiceUnavailable',
        component: load('pages/errors/503'),
        name: '503',
        meta: 
        {
            layout: 'dashboard'
        }
    },
    {
        path: '/:catchAll(.*)',
        redirect:{name:'404'}
    }
]