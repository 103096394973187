import { createStore }          from 'vuex';
import createPersistedState     from "vuex-persistedstate";
import ErrorsModule             from './modules/ErrorsModule';
import AuthModule               from './modules/AuthModule';
// import ZdhcModule               from './modules/ZdhcModule';
// import ProductModule            from './modules/ProductModule';
// import SuggestionModule         from './modules/SuggestionModule';
// import ZdhcProductModule        from './modules/ZdhcProductModule';
// import FormulatorModule         from './modules/FormulatorModule';
// import ProfileModule            from './modules/ProfileModule';
// import InventoryModule          from './modules/InventoryModule';
// import IncheckReferenceModule   from './modules/IncheckReferenceModule';
// import SearchModule             from './modules/SearchModule';
import StripeModule             from './modules/StripeModule';
import moment from 'moment';

export const store = createStore({
    modules: 
    {
        auth              : AuthModule,
        errors            : ErrorsModule,
        stripe            : StripeModule,
        // zdhc              : ZdhcModule,
        // product           : ProductModule,
        // profile           : ProfileModule,
        // inventory         : InventoryModule,
        // incheckReference  : IncheckReferenceModule,
        // formulator        : FormulatorModule,
        // suggestion        : SuggestionModule,
        // search            : SearchModule,
        // zdhcProduct       :ZdhcProductModule,
    },
    state:
    {
        locale: 'en',
        enabled: true,
        translate_count: 0,
        referencePeriod: moment().format('YYYY-MM-DD'),
        per_page: 15,
        checkedDates: {}
    },
    mutations:
    {
        SET_LOCALE(state, locale = 'en')
        {
            state.locale = locale
        },
        SET_ENABLE(state, value)
        {
            state.enabled = value;
        },
        ADD_COUNT(state, value)
        {
            state.translate_count += value;
        },
        SET_REFERENCE_PERIOD(state, value)
        {
            state.referencePeriod = moment(value).isValid() ? moment(value).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        },
        SET_PER_PAGE(state, value) 
        {
            state.per_page = value;
        },
        PUSH_CHECKED_DATE(state, date)
        {
            let today = moment().format('YYYY-MM-DD');
            if(today in state.checkedDates)
            {
                if(!state.checkedDates[today].includes(date))state.checkedDates[today].push(date);
            }
            else 
            {
                state.checkedDates[today] = [];
                state.checkedDates[today].push(date);
            }
        },
        CLEAR_OUTDATED_CHECKED_DATE(state)
        {
            let today = moment().format('YYYY-MM-DD');
            for(const key in state.checkedDates)
            {
                if(key != today) delete state.checkedDates[key];
            }
        },
        CLEAR_CHECKED_DATE(state)
        {
            state.checkedDates = {};
        }
    },
    strict:true,
    plugins: [createPersistedState()],
});