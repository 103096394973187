import { useApi } from '../composables/useApi';
import { useStore } from 'vuex';

const SuggestionService = () => 
{
    /* Data */
    const ApiClient = useApi();
    const store = useStore();
    
    /* Methods */
    const storeSuggestion = async (productId, payload) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.post(`suggestion/store/${productId}`, payload);
        if(res)
        {
            return res.data;
        }
    };
    const storeSimpleSuggestion = async (productId, zdhcProductId) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.post(`suggestion/store-simple-suggestion/${productId}`, zdhcProductId);
        if(res)
        {
            return res;
        }
    };
    const searchSuggestionById = async (id) => 
    {
        store.commit('errors/CLEAR_STATE');
        let matchesArray = [];
        const res = await ApiClient.get(`suggestion/check-for-match/${id}`);
        if(res)
        {
            res.data.results.forEach( p => 
            {
                if(!matchesArray.some(e => e.id === p.zdhc_product.id))
                {
                    let jsonName = JSON.parse(p.zdhc_product.name)
                    matchesArray.push({
                        name: jsonName[store.state.locale] ? jsonName[store.state.locale].toLowerCase() 
                        : (jsonName['en'] ? jsonName['en'].toLowerCase() : jsonName[Object.keys(jsonName)[0]].toLowerCase()),
                        id: p.zdhc_product.id,
                        guid: p.zdhc_product.guid,
                        count: p.count
                    });
                }
            });
            
            //return res.data;
            return matchesArray;
        }
    };
    const searchSuggestionByName = async (name) => 
    {
        store.commit('errors/CLEAR_STATE');
        let matchesArray = [];
        let encodedName = encodeURIComponent(name);
        const res = await ApiClient.get(`suggestion/search-by-name/${encodedName}`);

        if(res)
        {
            res.data.results.forEach( p => 
            {
                if(!matchesArray.some(e => e.id === p.zdhc_product.id))
                {
                    let jsonName = JSON.parse(p.zdhc_product.name)
                    matchesArray.push({
                        name: jsonName[store.state.locale] ? jsonName[store.state.locale].toLowerCase() 
                        : (jsonName['en'] ? jsonName['en'].toLowerCase() : jsonName[Object.keys(jsonName)[0]].toLowerCase()),
                        id: p.zdhc_product.id,
                        guid: p.zdhc_product.guid,
                        count: p.count
                    });
                }
            });
            return matchesArray;
        }
    };

    return {
        /* Methods */
        storeSuggestion,
        storeSimpleSuggestion,
        searchSuggestionById,
        searchSuggestionByName
    }
};

export default SuggestionService;

