/* eslint-disable */
import * as stripeService  from "../../services/StripeService";
export default
{
    namespaced: true,
    state: {
        sessionId: ''
    },
    actions: 
    {
        async subscriptionsIndex()
        {
            const res = await stripeService.subscriptionsIndex();
            if(res)
            {
                return res;
            }
        },
        async createCheckout({}, payload)
        {
            const res = await stripeService.createCheckout(payload);
            if(res)
            {
                return res;
            }
        },
        async expiresSpecificCheckoutSession({}, payload)
        {
            const res = await stripeService.expiresSpecificCheckoutSession(payload);
            if(res)
            {
                return res;
            }
        },
        async verifySubscription({}, price)
        {
            const res = await stripeService.verifySubscription(price);
            if(res)
            {
                return res;
            }
        },
    },
    mutations:{
        SET_SESSION_ID(state, value)
        {
            state.sessionId = value;
        },
        CLEAR_STATE(state)
        {
            state.sessionId = '';
        }
    },
    getters:{}
}
