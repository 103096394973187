<template>
    <div class="min-h-screen py-10 px-5 lg:p-10 lg:overflow-x-hidden flex flex-col">
        <DashboardNavbar 
            :breadcrumb="breadcrumb"
        />
        <div class="flex flex-col space-y-8 py-24">
            <div class="flex w-full items-center justify-between">
                <h1 class="text-3xl dark:text-white">
                    {{ $t('reports.title', $store.state.locale) }}
                </h1>
                <button 
                    class="bg-white dark:bg-gray-800 rounded border dark:border-gray-700 px-4 py-2 hover:border-blue-500 dark:hover:border-blue-400 hover:text-blue-500 dark:hover:text-blue-400 duration-300 flex items-center justify-center space-x-1"
                    :title="$t('reports.add', $store.state.locale)"
                    @click.prevent="showInventoryModal = true">
                    <span class="text-sm">{{ $t('reports.add', $store.state.locale) }}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"/></svg>
                </button>
            </div>
            <div v-if="status === 'loading'" class=" flex items-center justify-center">
                    <div colspan="7" class="text-center  w-full p-3"> 
                        <svg class="animate-spin w-12 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
                    </div>
            </div>
            <div v-else>
                <!-- -->
                <div v-if="inventories.data.length" class="w-full h-full">
                    <div class="relative wrap overflow-hidden py-10 h-full">
                         <!-- <div class="border-2-2 absolute dark:border-opacity-20 dark:border-gray-700 h-full border" style="left: 50%"></div> -->

                            <!--<div 
                                v-for="(inventory, i) in inventories.data" :key="i"
                                class="mb-8 flex justify-between items-center w-full right-timeline"
                                :class="{'flex-row-reverse': i%2 !== 0}">
                                <div class="order-1 w-5/12"></div>
                                <div class="z-20 flex items-center order-1 bg-gray-800 shadow-xl w-8 h-8 rounded"></div>
                                <div class="order-1 bg-gray-400 rounded shadow-xl w-5/12 px-6 py-4">
                                    <h3 class="mb-3 font-bold text-gray-800 text-xl">Right</h3>
                                    <p class="text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </div>
                            </div>-->
                            <InventoryTimeLineComponent 
                                v-for="(inventory, i) in inventories.data" :key="i"
                                :inventory="inventory"
                                :index="i"
                            />

                        
                    </div>
                </div>
                <div v-else class="w-full flex flex-col items-center justify-center space-y-2">
                    <h2 class="text-2xl text-center">
                        {{ $t('reports.no_reports', $store.state.locale) }}
                    </h2>
                </div>
                <!-- -->
            </div>
        </div>
        <teleport to="body">
            <NewInventoryModal 
                v-if="showInventoryModal"
                :parentInventories="inventories.data"
                @closeModal="closeModal"
                @newReport="init"
            />
        </teleport>
    </div>
</template>
<script>
//import ProductsTable from '../sections/dashboard/ProductsTable.vue';
import { reactive, 
         ref, 
         onBeforeMount }            from 'vue';
import { useStore }                 from 'vuex';
import moment                       from 'moment';
import DashboardNavbar              from '../layouts/navbars/DashboardNavbar.vue';
import NewInventoryModal            from '../components/modals/NewInventoryModal.vue'
import InventoryTimeLineComponent   from '../components/inventory/InventoryTimeLineComponent.vue'
import InventoryService from '../services/InventoryService';

export default 
{
    components:
    {
        DashboardNavbar,
        NewInventoryModal,
        InventoryTimeLineComponent
    },
    setup()
    {
        /** 
         *
         * Data
         *
         */
        const store = useStore();
        moment.locale(store.state.locale);
        const status = ref('loading');
        const {indexInventory} = InventoryService();
        const breadcrumb = reactive([
            { name: 'Report', label: 'reports', to: `/${store.state.locale}/report` },
        ]);
        const inventories = reactive({
            data: [],
            next_page_url: null,
            links: null,
            current_page: null,
            last_page: null
        });
        const selected_date = ref(moment().format('YYYY-MM-DD'));
        let showInventoryModal = ref(false);

        /** 
         *
         * Methods
         *
         */

        const init = () => 
        {
            closeModal();
            status.value = 'loading';
            indexInventory()
            .then( res => 
            {
                inventories.next_page_url = res.results.next_page_url;
                inventories.data = res.results.data;
                inventories.links = res.results.links;
                inventories.last_page_url = res.results.last_page_url;
                inventories.current_page = res.results.current_page;
                inventories.last_page = res.results.last_page;
                status.value = 'fetched';
            });
        }

        const closeModal =() => 
        {
            showInventoryModal.value = false;
        }

        /** 
         *
         * Hooks
         *
         */
        onBeforeMount( () => 
        {
            init();
        });
        return {
            breadcrumb,
            status,
            showInventoryModal,
            inventories,
            selected_date,
            init,
            closeModal
        }
    }
}
</script>
