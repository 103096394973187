<script setup>
import { defineAsyncComponent } from 'vue';

/* Components */
const GuestNavbar = defineAsyncComponent( () => import('./navbars/GuestNavbar.vue'));
</script>
<template>
    <div>
        <GuestNavbar v-if="$route.meta.layout === 'welcome'"/>
        <RouterView v-slot="{ Component }">
            <component :is="Component" />
        </RouterView>
    </div>
</template>