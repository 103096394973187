<template>
    <div class="fixed inset-0 p-6 bg-gray-1000 bg-opacity-90 z-30 overscroll-y-contain text-blue-500">
        <div class="flex flex-col w-full h-full items-center justify-center">
            <div class="bg-white dark:bg-gray-900 dark:text-gray-400 border dark:border-gray-700 rounded p-5 flex flex-col space-y-2 w-full md:w-2/3 lg:w-1/2 2xl:w-1/2 max-h-xl">
                <div class="flex items-start justify-between">
                    <button 
                        title="Chiudi"
                        @click.prevent="$emit('closeModal')"
                        class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                                <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                    c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                    c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                            </svg>
                    </button>
                </div>
                <div class="flex flex-col space-y-5">
                    <h1 class="text-xl flex items-center space-x-1 px-3 dark:text-gray-200">
                        <span>{{ $t('product.create.select_formulator', $store.state.locale) }}</span> 
                    </h1>
                    <!-- import section -->
                    <div class="flex items-center justify-end w-full">
                        <input v-model="searchInput" type="text" 
                            class="w-full pl-4 h-10 rounded border hover:border-blue-500 focus:border-blue-500 duration-300 dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400"
                            :placeholder="`${$t('formulator.search', $store.state.locale)}...`" />
                    </div>
                    <div v-if="pageStatus === 'loading'" class="w-full h-full flex items-center justify-center p-5">
                        <svg class="animate-spin w-12 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
                    </div>
                    <div v-else>
                        <h3  class="text-blue-500 font-semibold mb-5">
                            {{ $t('formulator.results', $store.state.locale) }}: {{ resultsCount }}
                        </h3>
                        <div v-if="!formulators.data.length" class="flex items-center justify-center text-center">
                            <h3 class="text-xl">
                                {{ $t('formulator.no_formulators', $store.state.locale) }}
                            </h3>
                        </div>
                        <div v-else 
                            class="grid grid-cols-1 w-full max-h-xs overflow-y-auto scrollbar-hidden">
                            <div class="flex flex-col space-y-3">
                                <div 
                                    v-for="f in formulators.data" :key="f.id"
                                    class="flex w-full items-center justify-start space-x-2 py-2 border-b">
                                    <button 
                                        class="h-5 w-5 min-w-5 rounded border flex items-center justify-center"
                                        :class="[{ 'bg-blue-500': f.id === selectedFormulator }]"
                                        @click.prevent="selectFormulator(f.id)">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 min-w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>
                                    </button>
                                    <div class="flex flex-col space-y-1">
                                        <h2 class="font-semibold flex items-center space-x-2">
                                            <span 
                                                v-if="f.supplier_aid" 
                                                class="">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 min-w-5 fill-current text-light-green-500"><path d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
                                            </span>
                                            {{ f.name }} 
                                        </h2>
                                        <h4 v-if="f.address" class="text-sm"> 
                                            <span>{{ f.address.substring(0, 52) }}</span>
                                            <span v-if="f.address.length > 52">...</span>
                                        </h4>
                                    </div>
                                </div>
                                <div 
                                    v-if="formulators.next_page_url"
                                    class="w-full flex items-center justify-center">
                                    <button
                                        :title="$t('formulator.more', $store.state.locale)"
                                        class="flex items-center justify-center py-3 w-52 rounded duration-300"
                                        :class="setFetchClasses"
                                        v-html="setFetchContent"
                                        @click.prevent="fetchFormulators">
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-if="formulators.data.length" class="flex items-center justify-center lg:justify-end">
                            <button 
                                :title="$t('formulator.select', $store.state.locale)"
                                class="flex items-center justify-center text-center w-full lg:max-w-max px-4 py-2 rounded bg-blue-500 text-white"
                                @click.prevent="acceptSelection">
                                <span>{{ $t('formulator.select', $store.state.locale) }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore} from 'vuex';
import { useI18n } from 'vue-i18n';
import SnackbarComposables from '../../composables/SnackbarComposables';
import FormulatorService from '../../services/FormulatorService';
import { ref, computed, watch, reactive } from 'vue';
export default {
    emits:['closeModal', 'selectedFormulator'],
    setup(props, {emit})
    {
        /**
         *
         * Data
         *
         */
        const store = useStore();
        const { t, locale} = useI18n();
        const { addSnackbar } = SnackbarComposables();
        const { searchFomulator, fetchFormulator } = FormulatorService();
        const fileInput = ref(null);
        const selectedFormulator = ref('');
        const resultsCount = ref(0);
        const inputTimeout = ref(null);
        const pageStatus = ref('void');
        const fetchingStatus = ref('void');
        const searchInput = ref('');
        const formulators = reactive({
            data:[],
            next_page_url: ''
        });

        /**
         * 
         * Watch 
         * 
         */
        watch(
            () => searchInput.value,
            () => 
            {
                clearTimeout(inputTimeout.value);
                inputTimeout.value = setTimeout(() => init(), 600);
            }
        );
        
        /**
         *
         * Computed
         *
         */
        const setFetchContent = computed( () => 
        {
            switch(fetchingStatus.value)
            {
                case 'loading':
                    return `<svg class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
                case 'error':
                    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
                case 'void': default:
                    return t('formulator.more', locale.value);
            }
        });
        const setFetchClasses = computed( () =>
        {
            switch(fetchingStatus.value)
            {
                case 'void':  case 'loading':  default:
                    return 'text-blue-500 hover:bg-blue-500 hover:text-white';
                case 'error':
                    return 'text-red-500 hover:bg-red-500 hover:text-white';
            }
        });

        /**
         *
         * Methods
         *
         */
        const init = async () => 
        {
            pageStatus.value = 'loading';
            const res = await searchFomulator(searchInput.value);
            if(res.data?.formulators)
            {
                formulators.data = [...res.data.formulators.data];
                formulators.next_page_url = res.data.formulators.next_page_url;
                resultsCount.value = res.data.results;
            }
            if(res) pageStatus.value = 'fetched';
        };
        const selectFormulator = (formulatorId) => 
        {
            selectedFormulator.value = formulatorId;
        };
        const fetchFormulators = async () => 
        {
            if(formulators.next_page_url && fetchingStatus.value === 'void')
            {
                fetchingStatus.value = 'loading';
                const res = await fetchFormulator(formulators.next_page_url);
                if(res.data?.formulators)
                {
                    formulators.data = [...formulators.data, ...res.data.formulators.data];
                    formulators.next_page_url = res.data.formulators.next_page_url;
                }
                if(res) fetchingStatus.value = 'void';
            }
        };
        const acceptSelection = () => 
        {
            if(!selectedFormulator.value) return addSnackbar('warning', t('formulator.no_selection', locale.value));
            else 
            {
                emit('selectedFormulator', formulators.data.filter(f => f.id === selectedFormulator.value)[0]);
            }
        }
        

        /**
         *
         * Hooks
         *
         */
        locale.value = store.state.locale;
        init();

        return {
            /* Data */
            store,
            t,
            fileInput,
            formulators,
            selectedFormulator,
            pageStatus,
            searchInput,
            resultsCount,
            /* Computed */
            setFetchContent,
            setFetchClasses,
            /* Methods */
            selectFormulator,
            fetchFormulators,
            acceptSelection 
        }
    }
}
</script>