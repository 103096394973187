<template>
    <div class="flex flex-col lg:flex-row items-start lg:items-center w-full lg:space-x-3 lg:justify-between dark:bg-gray-900">
       <!-- <div class="flex items-center sapce-x-1 group">
            <button class="h-12 flex items-center justify-center px-4 border-l border-t border-b rounded-l-full bg-white group-hover:border-blue-500 hover:text-blue-500 duration-300">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
            </button>
            <input type="text" 
                class="border-r border-t border-b group-hover:border-blue-500 rounded-r-full px-4 h-12 duration-300"
                :placeholder="$t('dashnav.search.placeholder', $store.state.locale)+'...'">
        </div>-->
        <div class="flex items-center justify-start space-x-1 order-2 lg:order-1">
            <router-link 
                class="duration-300"
                :class="$route.name.toLowerCase() === 'dashboard' ? 'text-blue-500 dark:text-blue-400' : 'text-gray-400 hover:text-blue-500 dark:hover:text-blue-400'"
                title="Dashboard"
                :to="`/${$store.state.locale}/dashboard`">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 fill-current"><path d="M20,8h0L14,2.74a3,3,0,0,0-4,0L4,8a3,3,0,0,0-1,2.26V19a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V10.25A3,3,0,0,0,20,8ZM14,20H10V15a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1Zm5-1a1,1,0,0,1-1,1H16V15a3,3,0,0,0-3-3H11a3,3,0,0,0-3,3v5H6a1,1,0,0,1-1-1V10.25a1,1,0,0,1,.34-.75l6-5.25a1,1,0,0,1,1.32,0l6,5.25a1,1,0,0,1,.34.75Z"/></svg>
            </router-link>
            <div
                class="flex items-center justify-start space-x-1"
                v-for="b in breadcrumb"
                :key="b.name"
                >
                <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current text-gray-400"><path d="M14.83,11.29,10.59,7.05a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41L12.71,12,9.17,15.54a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l4.24-4.24A1,1,0,0,0,14.83,11.29Z"/></svg>
                <router-link 
                    class="duration-300"
                    :class="b.name.toLowerCase() === $route.name.toLowerCase() ? 'text-blue-500 dark:text-blue-400' : 'text-gray-400 hover:text-blue-500 dark:hover:text-blue-400'"
                    :to="b.to">
                    {{ $t(`sidebar.${b.label}`, $store.state.locale) }}
                </router-link>
            </div>
        </div>

        <div class="flex items-center justify-end space-x-3 order-1 lg:order-2 mb-3 lg:mb-0">
            <button 
                @click.prevent="activeModal = true"
                class="bg-white dark:bg-gray-800 rounded border dark:border-gray-700 px-4 py-2 hover:border-blue-500 dark:hover:border-blue-400 hover:text-blue-500 dark:hover:text-blue-400 duration-300 flex items-center justify-center space-x-1"
                :title="$t('dashnav.button.import', $store.state.locale) + ' file'">
                <span class="text-sm">{{ $t('dashnav.button.import', $store.state.locale) }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M21,14a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15a1,1,0,0,0-2,0v4a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V15A1,1,0,0,0,21,14Zm-9.71,1.71a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l4-4a1,1,0,0,0-1.42-1.42L13,12.59V3a1,1,0,0,0-2,0v9.59l-2.29-2.3a1,1,0,1,0-1.42,1.42Z"/></svg>
            </button>
            <!--<button 
                class="bg-white rounded border px-4 py-2 hover:border-blue-500 hover:text-blue-500 duration-300 flex items-center justify-center space-x-1"
                :title="$t('dashnav.button.export', $store.state.locale) + ' file'">
                <span class="text-sm">{{ $t('dashnav.button.export', $store.state.locale) }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M8.71,7.71,11,5.41V15a1,1,0,0,0,2,0V5.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42l-4-4a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-4,4A1,1,0,1,0,8.71,7.71ZM21,14a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15a1,1,0,0,0-2,0v4a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V15A1,1,0,0,0,21,14Z"/></svg>
            </button>-->
            <router-link 
                :to="`/${$store.state.locale}/product/create`"
                class="bg-light-green-500 dark:bg-blue-500 text-white rounded border px-4 py-2 border-light-green-500 dark:border-blue-500 hover:bg-light-green-700 dark:hover:bg-blue-700 duration-300 flex items-center justify-center space-x-1"
                :title="$t('dashnav.button.add', $store.state.locale)">
                <span class="text-sm">{{ $t('dashnav.button.add', $store.state.locale) }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"/></svg>
            </router-link>
        </div>
    </div>
    <teleport to="body">
        <ImportModal 
            v-if="activeModal"
            @closeModal="closeImportModal"
            @newProducts="newProducts"
            @closeModalWithDate="closeModalWithDate"
        />
    </teleport>
</template>
<script>
import { ref } from 'vue';
import ImportModal from '../../components/modals/ImportModal.vue';
export default 
{
    emits:['newProds'],
    props:
    {
        breadcrumb: 
        {
            required: false,
            type: Array
        }
    },
    components: { ImportModal },
    setup(props, {emit})
    {
        const activeModal = ref(false);

        const closeImportModal = () =>
        {
            activeModal.value = false;
        };
        const newProducts = () => 
        {
            activeModal.value = false;
            emit('newProds');
        };
        const closeModalWithDate = (date) => 
        {
            activeModal.value = false;
            emit('closeModalWithDate', date);
        };
        return {
            /* Data */
            activeModal,
            /* Methods */
            closeImportModal,
            newProducts,
            closeModalWithDate
        }
    }
}
</script>