import i18n from './i18n';
import App from './App.vue';
import { createApp } from 'vue';
import { store } from './store';
/* import Bugsnag from '@bugsnag/js' */
import router from './routing/router';
import VueApexCharts from "vue3-apexcharts";
/* import BugsnagPluginVue from '@bugsnag/plugin-vue' */
import { SnackbarService, Vue3Snackbar } from "vue3-snackbar";
import ManualSearchModal from './components/modals/ManualSearchModal.vue';
import DeleteProductModal from './components/modals/DeleteProductModal.vue';

/* Styles */
import './index.css';
import "vue3-snackbar/dist/style.css";

/* Bugsnag.start({
    apiKey: '985e6f397fb576c175b3ed0698019d30',
    plugins: [new BugsnagPluginVue()]
}); */

/* const bugsnagVue = Bugsnag.getPlugin('vue') */
const app = createApp(App);

app.use(router);
app.use(store);
app.use(i18n);
app.use(VueApexCharts);
//app.use(VueClickAway);
app.use(SnackbarService);
/* app.use(bugsnagVue); */
app.component("vue3-snackbar", Vue3Snackbar);
app.component('DeleteProductModal', DeleteProductModal);
app.component('ManualSearchModal', ManualSearchModal);
app.directive('click-away', 
{
    mounted(el, binding)
    {
        el.handleClick = (e) => 
        {
            if (!(el == e.target || el.contains(e.target)))
            {
                binding.value()
            }
        };
        document.addEventListener('click', el.handleClick);
    },
    unmounted(el) {
        document.removeEventListener("click", el.handleClick);
    }
});



app.mount('#app');
