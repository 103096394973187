
import i18n                   from "../i18n";
import routes                 from './routes';
import { store }              from '../store';
import { SUPPORTED_LOCALES }  from '../config/locales';
import { createWebHistory, createRouter } from "vue-router";
//import { useAbortController } from '../composables/useAbortController';

//const controller = useAbortController();

function getLocaleRegex() {
  let reg = ''
  SUPPORTED_LOCALES.forEach((locale, index) => {
    reg = `${reg}${locale.code}${index !== SUPPORTED_LOCALES.length - 1 ? '|' : ''}`
  })
  return `(${reg})`
}

function getLocale(locale = 'it') {

  let actual_locale = locale && locale !=='' ? locale : 'it';
  
  return SUPPORTED_LOCALES.find(loc => loc.code === actual_locale);
}

const router = createRouter({
    history: createWebHistory(),
    routes: [{
      path: `/:locale${getLocaleRegex()}?/`,
      component: {
        template: '<router-view></router-view>'
      },
      beforeEnter(to, from, next) {
        const locale = getLocale(to.params.locale);
        store.commit('SET_LOCALE', locale.code);
        i18n.locale = locale.code;
        store.commit('SET_ENABLE', true);
        next();
      },
      children: routes
    }],
    // eslint-disable-next-line
    scrollBehavior (to, from, savedPosition) {
      if (to.hash) {
        return {
          el: to.hash
        }
      }
      else{
        return {
          // could also be
          // el: document.getElementById('main'),
          el: '#App',
          top: 0,
          behavior:'instant'
        } 
      }
    },
  });

router.beforeEach((to, from, next) => 
{
  store.commit('errors/CLEAR_STATE');
  store.commit('SET_ENABLE', true);
  //controller.abort();
  /*store.commit('SET_GALLERY', false);
  store.commit('SET_DELETE_ACCOUNT_MODAL', false);
  store.commit('SET_PERMANENTLY_DELETE_USER_MODAL', false);
  store.commit('SET_PERMANENTLY_DELETE_USER_ID', null); */
  next();
});

export default router;