import { useSnackbar } from "vue3-snackbar";
const SnackbarComposables = () => 
{
    /* -------------------------------------- Consts ----------------------------------------------- */
    const snackbar = useSnackbar();
    

    /* -------------------------------------- Functions ----------------------------------------------- */
    const addSnackbar = async (type, text, duration = 2000) => 
    {   
        snackbar.add({
            type: type,
            text: text,
            duration: duration
        })

    };

    return {
        snackbar,
        addSnackbar
    };
};

export default SnackbarComposables;