import { useStore } from 'vuex';
import { useApi } from '../composables/useApi';

const StripeService = () => 
{
    /* Data */
    const ApiClient = useApi();
    const store = useStore();
    /* Methods */
    const subscriptionsIndex = async() => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.get(`subscriptions/plans`);
        return res;
    };
    const createCheckout = async(payload) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.post(`subscriptions/create/checkout`, payload);
        return res;
    };
    const expiresSpecificCheckoutSession = async(payload) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.post(`subscriptions/cancel/checkout`, payload);
        return res;
    };
    const verifySubscription = async(price) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.get(`subscriptions/verify`, price);
        return res;
    };

    return {
        /* Methods */
        subscriptionsIndex,
        createCheckout,
        expiresSpecificCheckoutSession,
        verifySubscription
    }
}
export default StripeService;