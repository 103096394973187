export const bitls_email =  [
    'ceo@omnes.cloud',
    'stefano.delgobbo@theidfactory.com',
    'stefano.delgobbo@gmail.com',
    'francesca.tardelli@processfactory.it',
    'virginia.anichini@processfactory.it',
    'paria.behmanesh@processfactory.it',
    'giulia.onofrietti@processfactory.it',
    'ilaria.cornalba@processfactory.it',
    'dario.santalesa@processfactory.it',
    'marco.scovacricchi@processfactory.it'
];