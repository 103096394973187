<script setup>
import { ref, computed, defineProps, reactive } from 'vue';
import moment from 'moment';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import ZdhcServiceV5 from '../../../services/Zdhc/ZdhcServiceV5';
/* Emits */
/* Props */
const props = defineProps({
    product:{required:true}
});
/* Data */
const {t} = useI18n();
const store = useStore();
const zdhcProduct = reactive({});
const matching_status = ref('loading');
const { searchZdhcProductByZdhcPid } = ZdhcServiceV5();
/* Computed */
const setReferenceDate = computed( () => 
{
    return props.product.incheck_reference && props.product.incheck_reference.reference_date 
            ? moment(props.product.incheck_reference.reference_date).format('MMMM YYYY') 
            : '-';
});
/* Methods */
const init =  async () => 
{
    matching_status.value = 'loading';
    const res = await searchZdhcProductByZdhcPid(props.product.pid);
    if(res)
    {
        if(res.data?.[0]) Object.assign(zdhcProduct, res.data[0]);
        matching_status.value = 'fetched';
    }
};
/* Hooks */
moment.locale(store.state.locale);
init();
</script>
<template>
    <div class="w-full flex flex-col space-y-5">
        <template v-if="matching_status === 'loading'" >
            <div class="flex items-center justify-center h-72 overflow-y-auto overscroll-contain">
                <svg class="animate-spin w-12 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
            </div>
        </template>
        <template v-else-if="matching_status === 'fetched'">
            <div class="flex flex-col space-y-5 h-96 overflow-y-auto overscroll-contain text-sm">
                <p v-if="props.product.formulator">
                    {{ t('productTable.cols.formulator', store.state.locale) }}: 
                    <span class="font-semibold">
                        {{ props.product.formulator.name }} 
                    </span>
                </p>
                <p>
                    PID:
                    <span class="font-semibold">
                        {{ props.product.pid ?? '-' }} 
                    </span>
                </p>
                <p>
                    GUID:
                    <span class="font-semibold">
                        {{ props.product.guid ?? '-' }} 
                    </span>
                </p>
                <p>
                    {{ t('productTable.cols.quantity', store.state.locale) }}: 
                    <span class="font-semibold">
                        {{ props.product.incheck_reference.quantity / 100 }} Kg
                    </span>
                </p>
                <p>
                    {{ t('productTable.cols.reference_date', store.state.locale) }}: 
                    <span class="font-semibold">
                        {{ setReferenceDate }}
                    </span>
                </p>
                <div class="flex flex-col space-y-3">
                    <span>{{ t('matchModal.associated_product', store.state.locale) }}:</span>
                    <span v-if="!Object.keys(zdhcProduct).length" class="font-semibold">
                        {{ t('matchModal.no_associated_product', store.state.locale) }}
                    </span>
                    <div v-else class="flex flex-col space-y-5 bg-gray-100 dark:bg-gray-800 rounded-md p-2">
                        <!-- Name -->
                        <p>
                            {{ t('productTable.cols.name', store.state.locale) }}:
                            <span class="font-semibold capitalize">
                                {{ zdhcProduct.productName }}
                            </span>
                        </p>
                        <!-- Code -->
                        <div class="flex flex-col space-y-3">
                            <p>
                                PID:
                                <span class="font-semibold">
                                    {{ zdhcProduct.zdhcPID }}
                                </span>
                            </p>
                        </div>
                        <!-- Formulator -->
                        <div class="flex flex-col space-y-3">
                            <p>
                                {{ t('product.create.formulator', store.state.locale) }}:
                                <span class="font-semibold">
                                    {{ zdhcProduct.formulatorName }}
                                </span>
                            </p>
                        </div>
                        <!-- Certifications -->
                        <p>
                            {{ t('matchModal.certifications', store.state.locale) }}:
                            <span v-if="!zdhcProduct.productCertifications.length" class="font-semibold">
                               {{ t('matchModal.no_certifications', store.state.locale) }}
                            </span>
                        </p>
                        <div v-if="zdhcProduct.productCertifications.length" class="felx flex-col space-y-3">
                            <div
                                v-for="(c, i) in zdhcProduct.productCertifications" :key="i"
                                class="felx flex-col space-y-3 bg-gray-200 dark:bg-gray-700 p-2 rounded-md"
                            >
                                <p>
                                    {{ t('matchModal.certification_type', store.state.locale) }}:
                                    <span class="font-semibold">
                                    {{ c.certification }}
                                    </span>
                                </p>
                                <p>
                                    {{ t('matchModal.certification_result', store.state.locale) }}:
                                    <span class="font-semibold">
                                    {{ c.certificationResult }}
                                    </span>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </template>
    </div>
</template>