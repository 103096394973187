<template>
    <button 
        @click.prevent="setPeriod"
        class="flex flex-col space-y-1 p-3 shadow rounded hover:shadow-lg duration-300">
        <h3 class="text-lg text-light-green-500">
            {{$t('importModal.date', $store.state.locale)}}: <span class="capitalize">{{ dateMonth }}</span>
        </h3>
        <h4>{{$t('importModal.products', $store.state.locale)}}: {{ period.counter }}</h4>
    </button>
</template>
<script>
import moment from 'moment';
import {useStore} from 'vuex';
import {/* useRouter,  */useRoute} from 'vue-router';
export default
{
    emits:['closeModal', 'closeModalAfterImport', 'closeModalWithDate'],
    props:{ period: {required: true}},
    setup(props, {emit})
    {
        /**
         *
         * Data
         *
         */
        const store = useStore();
        //const router = useRouter();
        const route = useRoute();
        const dateMonth = moment(props.period.date, 'YYYY-MM-DD').format('MMMM YYYY');

        /**
         *
         * Methods
         *
         */
        const setPeriod = () => 
        {
            if(route.name === 'Dashboard' && route.query.d && route.query.d == props.period.date && store.state.referencePeriod == props.period.date)
            {
                emit('closeModalAfterImport');
            }
            else 
            {
                store.commit('SET_REFERENCE_PERIOD', props.period.date);
                emit('closeModalAfterImport');
            }
        }

        moment.locale(store.state.locale);
        return {
            /* Data */
            dateMonth,
            /* Methods */
            setPeriod
        }
    }
}
</script>