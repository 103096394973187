import { useApi } from "../composables/useApi";
import { useStore } from "vuex";
const FormulatorService = () => 
{
    /* Data */
    const ApiClient = useApi();
    const store = useStore();

    /* Methods */
    const indexFormulator = async() => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.get('formulator');
        if(res)
        {
            return res.data;
        }
    };
    const storeOrUpdateFormulator = async(payload) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.post('formulator/storeOrUpdate', payload);
        if(res)
        {
            return res.data;
        }
    };

    const storeOrUpdateFormulators = async (payload, start = 0, end = 25) =>
    {   
        store.commit('errors/CLEAR_STATE');
        const step = 26;
        let formulators = payload.formulators.slice(start, end);
        let resCount = 0;
        formulators.forEach( async (f) => 
        {
            const res = await storeOrUpdateFormulator({formulator: f});
            if(res)
            {
                resCount ++;
            }
            if(resCount === formulators.length) 
            {
                storeOrUpdateFormulators(payload, start + step, end + step);
            }
        });
    };
    const searchFomulator = async(inputSearch = '') => 
    {
        let endpoint = `search/formulators`;
        if(inputSearch) endpoint = endpoint + `?input=${inputSearch}`;
        const res = await ApiClient.get(endpoint);
        return res;
    };

    const fetchFormulator = async (endpoint) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.get(endpoint);
        if(res) return res;
    };

    return {
        /* Methods */
        indexFormulator,
        storeOrUpdateFormulator,
        storeOrUpdateFormulators,
        searchFomulator,
        fetchFormulator
    }
};
export default FormulatorService;