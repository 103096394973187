<script setup>
import { ref, defineProps, defineEmits, computed } from 'vue';
import IncheckReferenceService from '../../../services/IncheckReferenceService';
import ZdhcServiceV2 from '../../../services/Zdhc/ZdhcServiceV2';
/* Emits */
const emits = defineEmits(['newNoMatch']);
/* Props */
const props = defineProps({
    product: {required: true}
});
/* Data */
const submitStatus = ref('void');
const { storeNewProduct} = ZdhcServiceV2();
const { setNoMatchInIncheckReference } = IncheckReferenceService();
/* Computed */
const setNoMatchButtonClasses = computed( () =>
{
    switch(submitStatus.value)
    {
        case 'void': case 'loading': case 'error': default:
            return `bg-red-500 hover:bg-red-700`;
        case 'success':
            return `bg-green-500 hover:bg-green-700`;
    }
});
const setNoMatchButtonContet = computed( () => 
{
    switch(submitStatus.value)
    {
        case 'loading':
            return `<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
        case 'success':
            return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
        case 'error':
            return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
        case 'void': default:
            return 'No match';
    }
});
/* Methods */
const setNoMatch = async () => 
{
    submitStatus.value = 'loading';
    setTimeout( () => {if(submitStatus.value == 'loading') submitStatus.value = 'void'}, 5000);
    const formulatorGuid = props.product.name.formulator ? props.product.name.formulator.guid : null;
    const res = await storeNewProduct(props.product.name, formulatorGuid);
    if(res.guid)
    {
        const noMatchRes = await setNoMatchInIncheckReference(props.product.incheck_reference.id, {guid: res.guid, new_results: []});
        if(noMatchRes)
        {
            submitStatus.value = 'success';
            setTimeout( () => submitStatus.value = 'void', 2000);
            return emits('newNoMatch', res.guid);
        }
    }
    else 
    {
        submitStatus.value = 'error';
        setTimeout( () => submitStatus.value = 'void', 2000);
    }
};
</script>
<template>
    <button 
        @click.prevent="setNoMatch"
        class="px-5 py-3 font-medium leading-5 text-center text-white rounded lg:mt-0 duration-300 lg:w-auto"
        :class="setNoMatchButtonClasses"
        v-html="setNoMatchButtonContet"
        :disabled="submitStatus !== 'void'">
    </button>
</template>