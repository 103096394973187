<template>
    <div class="min-h-screen py-10 px-5 lg:p-10 lg:overflow-x-hidden flex flex-col">
        <DashboardNavbar 
            :breadcrumb="breadcrumb"
        />
        <div v-if="page_status === 'loading'" class="h-screen flex items-center justify-center">
                <div colspan="7" class="text-center  w-full p-3"> 
                    <svg class="animate-spin w-12 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
                </div>
        </div>
        <div v-else-if="page_status !== 'loading' && Object.keys(inventory).length" class="flex flex-col space-y-8 py-24">
            <div class="flex flex-col items-center justify-center space-y-3 w-full lg:flex-row lg:space-y-0 lg:space-x-5 lg:justify-between">
                <h1 class="text-3xl dark:text-white">
                    {{ $t('reportShow.title', $store.state.locale) }}: {{ $route.params.inventory }}
                </h1>
                <div class="flex items-center justify-end space-x-3">
                    <button 
                        class="bg-white dark:bg-gray-800 rounded border dark:border-gray-700 px-4 py-2 hover:border-blue-500 dark:hover:border-blue-400 hover:text-blue-500 dark:hover:text-blue-400 duration-300 flex items-center justify-center space-x-1"
                        :title="$t('reportShow.download', $store.state.locale)"
                        @click.prevent="openPdf">
                        <span class="text-sm">{{ $t('reportShow.download', $store.state.locale) }}</span>
                        <!--<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M8.71,7.71,11,5.41V15a1,1,0,0,0,2,0V5.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42l-4-4a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-4,4A1,1,0,1,0,8.71,7.71ZM21,14a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15a1,1,0,0,0-2,0v4a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V15A1,1,0,0,0,21,14Z"/></svg>-->
                    </button>
                    <!-- <button 
                        class="text-gray-400 dark:bg-gray-800 rounded border dark:border-gray-700 px-4 py-2 hover:border-red-500 dark:hover:border-red-400 hover:text-red-500 dark:hover:text-red-400 duration-300 flex items-center justify-center space-x-1"
                        :title="setDeleteButtonContent"
                        @click.prevent="destroyInventory"
                        :disabled="deleteReportStatus !== 'void'">
                        <span class="text-sm" v-html="setDeleteButtonContent"></span>
                    </button> -->
                </div>
            </div>
            <div class="flex flex-col space-y-2 w-full bg-white dark:bg-gray-800 p-5 rounded">
                <div class="flex items-center space-x-2">
                    <span>{{ $t('productTable.cols.reference_date', $store.state.locale) }}:</span>
                    <span class="font-semibold capitalize">{{ setReferenceDate }}</span>
                </div>
                <div class="flex items-center space-x-2">
                    <span>{{ $t('productTable.cols.created_at', $store.state.locale) }}:</span>
                    <span class="font-semibold">{{ setCreatedAt }}</span>
                </div>
                <div class="flex items-center space-x-2">
                    <span>{{ $t('reports.component.products', $store.state.locale) }}:</span>
                    <span class="font-semibold">{{ inventory.products.length }}</span>
                </div>
            </div>
            <!-- Table -->
            <div class="overflow-x-auto overflow-y-hidden z-10 scrollbar-hidden"> 
                <table class="table block space-y-10 w-full min-w-full">
                    <thead>
                        <tr class="bg-white dark:bg-gray-800 shadow border-b-2 dark:border-gray-700">
                            <td class="py-2 px-4">{{ $t('productTable.cols.productId', $store.state.locale) }}</td>
                            <td class="py-2 px-4">{{ $t('productTable.cols.name', $store.state.locale) }}</td>
                            <td class="py-2 px-4">{{ $t('productTable.cols.quantity', $store.state.locale) }} (Kg)</td>
                            <td class="py-2 px-4">{{ $t('productTable.cols.formulator', $store.state.locale) }}</td>
                            <td class="py-2 px-4">{{ $t('productTable.cols.created_at', $store.state.locale) }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <InventoryReportTableRow 
                            v-for="(p, i) in products"  :key="i"
                            :parent_product="p"
                            :date="inventory.reference_date"
                        />
                    </tbody>
                    <!--<tbody v-if="status !== 'loading' && products.data.length === 0">
                        <tr>
                            <td colspan="7" class="text-center  w-full p-3"> 
                                <h3 class="text-2xl">
                                    {{ $t('productTable.no_product', $store.state.locale) }}
                                </h3>
                            </td>
                        </tr>
                    </tbody>-->
                </table>
            </div>
        </div>
        
    </div>
</template>
<script>
import { reactive,
         ref,
         computed }              from 'vue';
import { useRoute, useRouter }         from 'vue-router';
import { useStore }         from 'vuex';
import {useI18n}            from 'vue-i18n';
import moment               from 'moment';
import DashboardNavbar      from '../../layouts/navbars/DashboardNavbar.vue';
import SnackbarComposables  from '../../composables/SnackbarComposables';
import InventoryReportTableRow  from '../../components/inventory/InventoryReportTableRow';
import InventoryService from '../../services/InventoryService'

export default 
{
    components: { DashboardNavbar, InventoryReportTableRow },
    setup()
    {
        /**
         *
         * Data
         *
         */
        const { t, locale } = useI18n();
        const store = useStore();
        const page_status = ref('loading');
        const deleteReportStatus = ref('void');
        const route = useRoute();
        const router = useRouter();
        const { showInventory, deleteInventory, downloadReport } = InventoryService();
        const { addSnackbar } = SnackbarComposables();
        const inventory = reactive({});
        const products = ref([]);
        const report = ref('');
        const breadcrumb = reactive([
            { name: 'Report', label: 'reports', to: `/${store.state.locale}/report` },
            { name: 'ReportShow', label: 'report' , to: `/${store.state.locale}/report/${route.params.inventory }` },
        ]);
        moment.locale(store.state.locale);
        locale.value = store.state.locale;

        /** 
         *
         * Computed
         *
         */
        const setReferenceDate = computed( () => 
        {
            return moment(inventory.reference_date).format('MMMM YYYY');
        });
        const setCreatedAt = computed( () => 
        {
            return store.state.locale === 'en' 
                ? moment(inventory.created_at).format('YYYY-MM-DD') 
                : moment(inventory.created_at).format('DD/MM/YYYY');
        });
        const setDeleteButtonContent = computed( () => 
        {
            switch(deleteReportStatus.value)
            {
                case'loading':
                    return `<svg class="animate-spin h-5 w-5 fill-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
                case 'error':
                    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
                case'void': default:
                    return t('reportShow.delete', locale.value);
            }
        }
        );

        /**
         *
         * Methods
         *
         */
        const init = async () =>
        {
            page_status.value = 'loading';
            const inventoryRes = await showInventory(route.params.inventory);
            if(inventoryRes.inventory)
            {
                Object.assign(inventory, inventoryRes.inventory)
                products.value = inventory.products;
                const reportRes = await downloadReport(inventoryRes.inventory.report);
                if(reportRes.data) report.value = reportRes.data;
            }
            page_status.value = 'void';
        };
        const openPdf = () => 
        {
           /*  let url = `//${process.env.VUE_APP_LUMEN_URL}/${inventory.report}`;
            window.open(url, '_blank').focus(); */
            if(report.value)
            {
                var byteCharacters = atob(report.value);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], { type: 'application/pdf;base64' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }
            else return addSnackbar('warning', t('reports.no_file', locale.value));
        };
        const destroyInventory = async() => 
        {
            let isActualMonth = moment().isSame(moment(inventory.reference_date), 'month') && moment().isSame(moment(inventory.reference_date), 'year');
            let lastMonth = moment().subtract(1, "month");
            let isLastMonth = lastMonth.isSame(moment(inventory.reference_date), 'month') && lastMonth.isSame(moment(inventory.reference_date), 'year');
            if(isActualMonth || isLastMonth)
            {
                deleteReportStatus.value = 'loading';
                const res = await deleteInventory(inventory.id)
                if(res === 200) 
                {
                    deleteReportStatus.value = 'void';
                    return router.push(`/${store.state.locale}/report`);
                }
                deleteReportStatus.value = 'error';
                setTimeout( () => deleteReportStatus.value = 'void', 1000);
            }
            else 
            {
                addSnackbar('warning', 'It is possible to delete inventories that refer to a maximum of 30 days from today');
            }
        }
        /**
         *
         * Hooks
         *
         */
        init();
        return {
            /* Data */
            breadcrumb,
            page_status,
            inventory,
            products,
            deleteReportStatus,
            report,
            /* Computed */
            setReferenceDate,
            setCreatedAt,
            setDeleteButtonContent,
            /* Methods */
            openPdf,
            destroyInventory
        }
    }
}
</script>