<template>
    <div class="min-h-screen py-10 px-5 lg:p-10 lg:overflow-x-hidden">
        
        <div class="flex flex-col space-y-10">
            <h1 class="text-2xl">
                Descrizione algoritmo di ricerca
            </h1>
            <p class="text-xl">
                La ricerca si basa su <span class="text-blue-500 dark:text-blue-400 dark:text-blue-400 font-bold">3 Fasi</span> principali
            </p>
            <div class="flex items-center jusitfy-between space-x-5">
                <button 
                    class="rounded-xl shadow p-5 w-1/3 flex flex-col space-y-5 duration-300"
                    :class="step === 1 ? 'bg-blue-500 text-white' : 'bg-white hover:text-blue-500 dark:bg-gray-800 dark:hover:text-blue-400'"
                    @click.prevent="step = 1">
                    <h3 class="text-center text-xl">
                        Ricerca standard
                    </h3>
                </button>
                <button 
                    class="rounded-xl shadow p-5 w-1/3 flex flex-col space-y-5 duration-300"
                    :class="step === 2 ? 'bg-blue-500 text-white' : 'bg-white hover:text-blue-500 dark:bg-gray-800 dark:hover:text-blue-400'"
                    @click.prevent="step = 2">
                    <h3 class="text-center text-xl">
                        Ricerca avanzata - traduzione
                    </h3>
                </button>
                <button 
                    class="rounded-xl shadow p-5 w-1/3 flex flex-col space-y-5 duration-300"
                    :class="step === 3 ? 'bg-blue-500 text-white' : 'bg-white hover:text-blue-500 dark:bg-gray-800 dark:hover:text-blue-400'"
                    @click.prevent="step = 3">
                    <h3 class="text-center text-xl">
                        Ricerca avanzata - split
                    </h3>
                </button>
            </div>
            <!-- Step 1 -->
            <div v-if="step === 1" class="flex flex-col space-y-5 bg-white dark:bg-gray-800 rounded-xl shadow p-5">
                <h3 class="text-center text-xl p-1">
                        Fase 1 - Ricerca standard
                </h3>
                <hr class="dark:border-gray-700">
                <p>
                    La prima fase della ricerca viene fatta tramite il <span class="font-bold">nome</span> del prodotto inserito dall'utente e si basa su 3 chiamate parallele:
                    <ul class="list-disc list-inside">
                        <li>
                            1 - Ricerca nella tabella Matches (locale)
                        </li>
                        <li>
                            2 - Ricerca nella tabella Suggestions (locale)
                        </li>
                        <li>
                            3 - Ricerca nel db ZDHC
                        </li>
                    </ul>
                </p>
                <p>
                    Una volta ricevuta risposta dalle 3 chiamate precedenti, si hanno a loro volta 3 casi possibili:
                    <ul class=" list-inside">
                        <li>
                            a - le chiamate 1 o 3 restituiscono un match singolo, oppure la chiamata 2 restituisce un suggerimento
                                il cui 'count' supera una certa soglia (attualmente impostata a 15);
                        </li>
                        <li>
                            b - non vi sono 'match singoli' ed almeno una delle 3 chiamate restituisce un set di possibili match;
                        </li>
                        <li>
                            c - le 3 chiamate non restituiscono match compatibili.
                        </li>
                    </ul>
                </p>
                <div class="flex w-full items-start justify-between space-x-5">
                    <div 
                        class="border rounded-xl shadow bg-white dark:bg-gray-800 dark:border-gray-700 p-5 w-1/3 flex flex-col space-y-5">
                        <h3 class="text-center text-xl">
                            Caso <span class="text-blue-500 dark:text-blue-400">A</span>
                        </h3>
                        <p class="text-lg">
                            È stato trovato un match esatto; l'utente non può modificare il risultato del matching
                        </p>    
                    </div>
                    <div 
                        class="border rounded-xl shadow bg-white dark:bg-gray-800 dark:border-gray-700 p-5 w-1/3 flex flex-col space-y-5">
                        <h3 class="text-center text-xl">
                            Caso <span class="text-blue-500 dark:text-blue-400">B</span>
                        </h3>
                        <p class="text-lg">
                            L'utente viene avvisato che un'azione è richiesta: dovrà selezionare manualmente il match da associare al proprio prodotto, 
                            scegliendo tra quelli trovati a seguito delle 3 chiamate effettuate in precedenza
                        </p>    
                    </div>
                    <div 
                        class="border rounded-xl shadow bg-white dark:bg-gray-800 dark:border-gray-700 p-5 w-1/3 flex flex-col space-y-5">
                        <h3 class="text-center text-xl">
                            Caso <span class="text-blue-500 dark:text-blue-400">C</span>
                        </h3>
                        <p class="text-lg">
                            Viene avviata la 
                            <button 
                                class="hover:text-blue-500 duration-300"
                                @click.prevent="step = 2">
                                Ricerca avanzata - traduzione
                            </button>
                        </p>    
                    </div>
                </div>
            </div>
            <!-- Step 2 -->
            <div v-if="step === 2" class="flex flex-col space-y-5 bg-white dark:bg-gray-800 rounded-xl shadow p-5">
                <h3 class="text-center text-xl p-1">
                        Fase 2 - Ricerca avanzata ~ traduzione
                </h3>
                <hr class="dark:border-gray-700">
                <p>
                    La seconda fase della ricerca si basa sull'idea di effettuare una ricerca usando come paramentro non più il nome del prodotto 
                    inserito dall'utente, bensì usando la sua <span class="font-bold">traduzione</span>. <br>
                    Per la traduzione stiamo utilizzando le API di Google Translate (possibilità di affidarsi al servizio di traduzione Amazon) e, al momento, 
                    stiamo usando solo traduzioni verso l'inglese. <br>
                    Una volta ottenuta la traduzione, viene effettuata una ricerca nuovamente basata su 3 step paralleli:
                    <ul class="list-disc list-inside">
                        <li>
                            1 - Ricerca nella tabella Matches (locale)
                        </li>
                        <li>
                            2 - Ricerca nella tabella Suggestions (locale)
                        </li>
                        <li>
                            3 - Ricerca nel db ZDHC
                        </li>
                    </ul>
                </p>
                <p>
                    Una volta ricevuta risposta dalle 3 chiamate precedenti, si hanno nuovamente 3 casi possibili:
                    <ul class=" list-inside">
                         <li>
                            a - le chiamate 1 o 3 restituiscono un match singolo, oppure la chiamata 2 restituisce un suggerimento
                                il cui 'count' supera una certa soglia (attualmente impostata a 15);
                        </li>
                        <li>
                            b - non vi sono 'match singoli' ed almeno una delle 3 chiamate restituisce un set di possibili match;
                        </li>
                        <li>
                            c - le 3 chiamate non restituiscono match compatibili.
                        </li>
                    </ul>
                </p>
                <div class="flex w-full items-start justify-between space-x-5">
                    <div 
                        class="border rounded-xl shadow bg-white dark:bg-gray-800 dark:border-gray-700 p-5 w-1/3 flex flex-col space-y-5">
                        <h3 class="text-center text-xl">
                            Caso <span class="text-blue-500 dark:text-blue-400">A</span>
                        </h3>
                        <p class="text-lg">
                            È stato trovato un match esatto; l'utente non può modificare il risultato del matching
                        </p>    
                    </div>
                    <div 
                        class="border rounded-xl shadow bg-white dark:bg-gray-800 dark:border-gray-700 p-5 w-1/3 flex flex-col space-y-5">
                        <h3 class="text-center text-xl">
                            Caso <span class="text-blue-500 dark:text-blue-400">B</span>
                        </h3>
                        <p class="text-lg">
                            L'utente viene avvisato che un'azione è richiesta: dovrà selezionare manualmente il match da associare al proprio prodotto, 
                            scegliendo tra quelli trovati a seguito delle 2 chiamate effettuate in precedenza
                        </p>    
                    </div>
                    <div 
                        class="border rounded-xl shadow bg-white dark:bg-gray-800 dark:border-gray-700 p-5 w-1/3 flex flex-col space-y-5">
                        <h3 class="text-center text-xl">
                            Caso <span class="text-blue-500 dark:text-blue-400">C</span>
                        </h3>
                        <p class="text-lg">
                            Viene avviata la 
                            <button 
                                class="hover:text-blue-500 duration-300"
                                @click.prevent="step = 3">
                                Ricerca avanzata - split
                            </button>
                        </p>    
                    </div>
                </div>
            </div>
            <!-- Step 3 -->
            <div v-if="step === 3" class="flex flex-col space-y-5 bg-white dark:bg-gray-800 rounded-xl shadow p-5">
                <h3 class="text-center text-xl p-1">
                        Fase 2 - Ricerca avanzata ~ split
                </h3>
                <hr class="dark:border-gray-700">
                <p>
                    L'idea dietro a questa fase è quella di prendere il nome del prodotto inserito dall'utente e di splittarlo,
                    rimuovendo di volta in volta la prima e l'ultima lettera della stringa. <br>
                    Con ogni sotto-stringa trovata vengono effettuate le 3 chiamate in parallelo:
                    <ul class="list-disc list-inside">
                        <li>
                            1 - Ricerca nella tabella Matches (locale)
                        </li>
                        <li>
                            2 - Ricerca nella tabella Suggestions (locale)
                        </li>
                        <li>
                            3 - Ricerca nel db ZDHC
                        </li>
                    </ul>
                </p>
                <p>
                    Una volta ricevuta risposta dalle 3 chiamate precedenti, si hanno nuovamente 3 casi possibili:
                    <ul class=" list-inside">
                         <li>
                            a - le chiamate 1 o 3 restituiscono un match singolo, oppure la chiamata 2 restituisce un suggerimento
                                il cui 'count' supera una certa soglia (attualmente impostata a 15);
                        </li>
                        <li>
                            b - non vi sono 'match singoli' ed almeno una delle 3 chiamate restituisce un set di possibili match;
                        </li>
                        <li>
                            c - le 3 chiamate non restituiscono match compatibili.
                        </li>
                    </ul>
                </p>
                <div class="flex w-full items-start justify-between space-x-5">
                    <div 
                        class="border rounded-xl shadow bg-white dark:bg-gray-800 dark:border-gray-700 p-5 w-1/3 flex flex-col space-y-5">
                        <h3 class="text-center text-xl">
                            Caso <span class="text-blue-500 dark:text-blue-400">A</span>
                        </h3>
                        <p class="text-lg">
                            È stato trovato un match esatto; l'utente non può modificare il risultato del matching
                        </p>    
                    </div>
                    <div 
                        class="border rounded-xl shadow bg-white dark:bg-gray-800 dark:border-gray-700 p-5 w-1/3 flex flex-col space-y-5">
                        <h3 class="text-center text-xl">
                            Caso <span class="text-blue-500 dark:text-blue-400">B</span>
                        </h3>
                        <p class="text-lg">
                            L'utente viene avvisato che un'azione è richiesta: dovrà selezionare manualmente il match da associare al proprio prodotto, 
                            scegliendo tra quelli trovati a seguito delle 2 chiamate effettuate in precedenza
                        </p>    
                    </div>
                    <div 
                        class="border rounded-xl shadow bg-white dark:bg-gray-800 dark:border-gray-700 p-5 w-1/3 flex flex-col space-y-5">
                        <h3 class="text-center text-xl">
                            Caso <span class="text-blue-500 dark:text-blue-400">C</span>
                        </h3>
                        <p class="text-lg">
                            Viene proposto all'untente di effettuare una ricerca manuale
                        </p>    
                    </div>
                </div>
            </div>
            
        </div>
       
    </div>
</template>
<script>
import {ref} from 'vue';
export default 
{
    components:
    {
       
    },
    setup()
    {
        const step = ref(0);

        return {
            step
        }
    }
}
</script>
