import { useApi } from "../composables/useApi";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import moment from "moment";
import SnackbarComposables from "../composables/SnackbarComposables";

const AuthService = () => 
{
    /* Data */
    const ApiClient = useApi();
    const store = useStore();
    const { t, locale } = useI18n();
    const { addSnackbar } = SnackbarComposables();

    /* Methods */
    const registration = async (payload) => 
    {   
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.post('registration', payload);
        if(res)
        {
            if(res.data)
            {
                store.commit('auth/SET_USER', res.data.user);
                // If backend is Lumen, set token value
                if(process.env.VUE_APP_API_ENV === 'lumen')
                {
                    store.commit('auth/SET_TOKEN', res.data.token.value);
                    store.commit('auth/SET_EXPIRES_AT', moment().add(15, 'days'));
                    ApiClient.defaults.headers.common['Authorization'] = `Bearer ${res.data.token.value}`; 
                }
                locale.value = store.state.locale;
                addSnackbar('success', t('auth.signup.successfulRegistration', store.state.locale));
                return 200;
            }
            else if(res.status !== 200 && res !== 422)
            {
                addSnackbar('error', t('snackbar.refresh', store.state.locale));
            }
            return res;
        }
    };
    const login = async (payload) => 
    {   
        store.commit('errors/CLEAR_STATE');
        if(store.state.enabled)
        {
            const res = await ApiClient.post('login', payload);
            if(res)
            {
                if(res.credential_error || res === 422)
                {
                    return 422;
                }
                else if(res.data)
                {
                    store.commit('auth/SET_USER', res.data.user);
                    // If backend is Lumen, set token value
                    if(process.env.VUE_APP_API_ENV === 'lumen')
                    {
                        store.commit('auth/SET_TOKEN', res.data.token.value);
                        store.commit('auth/SET_EXPIRES_AT', moment().add(15, 'days'));
                        ApiClient.defaults.headers.common['Authorization'] = `Bearer ${res.data.token.value}`; 
                    }
                    return 200;
                }
                else if(res.status !== 200 && res !== 422)
                {
                    addSnackbar('error', t('snackbar.refresh', store.state.locale));
                }
                return res;
            }
        }

    };
    const logout = async () => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.post('logout');
        if(res)
        {
            if(res.status === 200)
            {
               
                store.commit('auth/CLEAR_STATE');
                return 200;
            }
            else if(res.status !== 200 && res !== 422)
            {
                addSnackbar('error', t('snackbar.refresh', store.state.locale));
            }
            return res;
        }
    };
    const sendCode = async (email) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.get(`send-reset-password-code?email=${email}`);
        return res.data;
    };
    const resetPassword = async(payload) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.put(`reset-password`, payload);
        return res.data;
    };
    const checkAuthuser = async () => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.get('check-auth');
        if(res.data)
        {
            store.commit('auth/SET_USER', res.data.user);
            return 200;
        }
        return res
    };
    const crossLogin = async (payload) => 
    {   
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.post('cross-login', payload);
        if(res)
        {
            if(res.credential_error || res === 422)
            {
                const logoutRes = await store.dispatch('auth/logout');
                if(logoutRes)
                {
                    if(logoutRes.status === 200)
                    {
                    
                        store.commit('auth/CLEAR_STATE');
                        return 422;
                    }
                    else if(res.status !== 200 && res !== 422)
                    {
                        addSnackbar('error', t('snackbar.refresh', store.state.locale));
                    }
                    return res;
                }
                return 422;
            }
            if(res.data)
            {
                store.commit('auth/SET_USER', res.data.user);
                if(process.env.VUE_APP_API_ENV === 'lumen')
                {
                    store.commit('auth/SET_TOKEN', res.data.token.value);
                    store.commit('auth/SET_EXPIRES_AT', moment().add(15, 'days'));
                    ApiClient.defaults.headers.common['Authorization'] = `Bearer ${res.data.token.value}`;
                }
                return 200;
            }
            else if(res.status !== 200 && res !== 422)
            {
                addSnackbar('error', t('snackbar.refresh', store.state.locale));
            }
            return res;
        }

    };
    const impersonateLogin = async (payload) => 
    {   
        store.commit('errors/CLEAR_STATE');
        if(store.state.enabled)
        {
            const res = await ApiClient.post('impersonate-login', payload);
            if(res)
            {
                if(res.credential_error || res === 422)
                {
                    return 422;
                }
                if(res.data?.status == 'error')
                {
                    if(res.data?.message === 'NOT_AUTHORIZE') return 401;
                    else if(res.data?.message === 'NOT_FOUND') return 404;
                }
                else if(res.data)
                {
                    store.commit('auth/SET_USER', res.data.user);
                    // If backend is Lumen, set token value
                    if(process.env.VUE_APP_API_ENV === 'lumen')
                    {
                        store.commit('auth/SET_TOKEN', res.data.token.value);
                        store.commit('auth/SET_EXPIRES_AT', moment().add(15, 'days'));
                        ApiClient.defaults.headers.common['Authorization'] = `Bearer ${res.data.token.value}`; 
                    }
                    return 200;
                }
                else if(res.status !== 200 && res !== 422)
                {
                    addSnackbar('error', t('snackbar.refresh', store.state.locale));
                }
                return res;
            }
        }

    };

    /* Hooks */
    locale.value = store.state.locale;

    return {
        /* Methods */
        registration,
        login,
        logout,
        sendCode,
        resetPassword,
        checkAuthuser,
        crossLogin,
        impersonateLogin
    }
}
export default AuthService;





