import { useStore } from "vuex";
import { useGoogleApi } from "../composables/useGoogleApi";

const GoogleService = () => 
{
    /**
     * 
     * Data
     * 
     */
    const store = useStore();
    const GoogleApiClient = useGoogleApi();
    /**
     * 
     * Methods
     * 
     */
    const detectLanguage = async(text) => 
    {
        let encodedText = encodeURI(text);
        store.commit('ADD_COUNT', encodedText.length);
        await GoogleApiClient.get(`detect/?key=${process.env.VUE_APP_GOOGLE_TRANSLATE_KEY}&q=${encodedText}`);
    };
    const translate = async(text, target = 'en') => 
    {
        let encodedText = encodeURIComponent(text);
        store.commit('ADD_COUNT', encodedText.length);
        let res = await GoogleApiClient.get(`?key=${process.env.VUE_APP_GOOGLE_TRANSLATE_KEY}&q=${encodedText}&target=${target}`);
        if(res) return res.data;
    }
    
    return {
        /* Methods */
        detectLanguage,
        translate
    }
};

export default GoogleService;