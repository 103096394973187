<script setup>
import { defineAsyncComponent, defineProps } from 'vue';
/* Props */
const props = defineProps({
  showLayer: {required: true, type:Boolean}
});
/* Components */
const Sidebar = defineAsyncComponent( () => import('./sidebars/Sidebar.vue'));
</script>
<template>
<div v-if="$store.state.auth.user" class="text-blue-500 dark:text-gray-300">
        <div class="flex w-full relative min-h-screen">
            <Sidebar 
              v-if="$route.meta.layout === 'dashboard'"
              @closeSidebar="closeMobileNavigation"/>
            <Transition name="fade">
                <div v-if="props.showLayer" @click.prevent="closeMobileNavigation" id="opaqueLayer" class="hidden lg:hidden md:block absolute inset-0 bg-black bg-opacity-70 z-30 duration-100"></div>
            </Transition>
            
            <div id="mainDiv" class="w-full lg:w-4/5 2xl:w-5/6 ml-auto bg-gray-50 dark:bg-gray-900">
              <!-- Mobile navigation button -->
              <div class="lg:hidden w-full flex items-center justify-end p-2 bg-gray-50 dark:bg-gray-900">
                <button 
                  @click.prevent="toggleNavigation"
                  class="bg-white dark:bg-gray-800 rounded border dark:border-gray-700 px-4 py-2 hover:border-blue-500 dark:hover:border-blue-400 hover:text-blue-500 dark:hover:text-blue-400 duration-300 flex items-center justify-center space-x-1">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="fill-current w-5"><path d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"/></svg>
                </button>
              </div>
                <RouterView v-slot="{ Component }">
                    <component :is="Component" />
                </RouterView>
            </div>
        </div>
    </div>
</template>
