import { useStore } from 'vuex';
import {useApi} from '../composables/useApi';

const ChartService = () => 
{
    /* Data */
    const ApiClient = useApi();
    const store = useStore();

    /* Methods */
    const headerCount = async(reference_date) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.get(`chart/header-count?selected_date=${reference_date}`);
        return res;
    };
    const productsByCount = async(reference_date) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.get(`chart/by-count?selected_date=${reference_date}`);
        return res;
    };
    const productsByQuantity = async(reference_date) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.get(`chart/by-quantity?selected_date=${reference_date}`);
        return res;
    };

    /* Hooks */
    //locale.value = store.state.locale;

    return {
        /* Methods */
        headerCount,
        productsByCount,
        productsByQuantity
    }

};

export default ChartService;

