import { useApi } from "../composables/useApi";
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import SnackbarComposables from '../composables/SnackbarComposables';
import moment from "moment";
import { useRouter } from "vue-router";
const ProfileService = () => 
{
    /* Data */
    const store = useStore();
    const ApiClient = useApi();
    const router = useRouter();
    const { t, locale } = useI18n();
    const { addSnackbar } = SnackbarComposables();

    /* Methods */
    const updateProfile = async(payload) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.put('profile/update', payload);
        if(res)
        {
            if(res.data && res.data.user)
            {
                locale.value = store.state.locale;
                addSnackbar('success', t('profile.update.updatedMessage', store.state.locale));
                // Update user
                store.commit('auth/SET_USER', res.data.user);
                return 200;
            }
            else if(res.status !== 200 && res !== 422)
            {
                addSnackbar('error', t('snackbar.refresh', store.state.locale));
            }
            return res;
        }
    };
    const updateKeys = async(payload) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.put('profile/update-keys', payload);
        if(res)
        {
            if(res.data && res.data.user)
            {
                addSnackbar('success', t('profile.keysUpdate.updatedMessage', store.state.locale));
                // Update user
                store.commit('auth/SET_USER', res.data.user);
                return 200;
            }
            else if(res.status !== 200 && res !== 422)
            {
                addSnackbar('error', t('snackbar.refresh', store.state.locale));
            }
            return res;
        }
    };
    const updateSuscriptionExpiration = async(payload) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.patch('profile/update-subscription-expiration', payload);
        if(res)
        {
            if(res.data && res.data.user)
            {
                locale.value = store.state.locale;
                // Update user
                store.commit('auth/SET_USER', res.data.user);
                return 200;
            }
            else if(res.status !== 200 && res !== 422)
            {
                addSnackbar('error', t('snackbar.refresh', store.state.locale));
            }
            return res;
        }
    };
    const checkForKeys = async() =>
    {   
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.get('profile/check-for-keys');
        if(res)
        {
           // user has keys
            if(res.data.message)
            {
                if(res.data.user)
                {
                    // Update user
                    store.commit('auth/SET_USER', res.data.user);
                    return 200;
                }
            }
            // user doesn't have keys
            else if(res.data.message === false)
            {
                addSnackbar('error', t('profile.keysCheck.errorMessage', store.state.locale));
            }
            else if(res.status !== 200 && res !== 422)
            {
                addSnackbar('error', t('snackbar.refresh', store.state.locale));
            }
            return res;
        }
    };
    const updatePassword = async(payload) =>
    {   
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.put('profile/updatePassword', payload);
        if(res)
        {
            if(res.data)
            {
                if(process.env.VUE_APP_API_ENV === 'lumen' && res.data.token)
                {
                    store.commit('auth/SET_TOKEN', res.data.token.value);
                    store.commit('auth/SET_EXPIRES_AT', moment().add(15, 'days'));
                }
                addSnackbar('success', t('profile.passwordUpdate.updatedMessage', store.state.locale));
                return 200;
            }
            else if(res.status !== 200 && res !== 422)
            {
                addSnackbar('error', t('snackbar.refresh', store.state.locale));
            }
            return res;
        }
    };
    const deleteAccount = async() =>
    {  
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.delete('profile/delete');
        if(res)
        {
            if(res === 200)
            {
                store.commit('auth/CLEAR_STATE');

                addSnackbar('success', t('profile.delete.deletedMessage', store.state.locale));
                router.push(`/${store.state.locale}/`);

                return 200;
            }
            else if(res.status !== 200 && res !== 422)
            {
                addSnackbar('error', t('snackbar.refresh', store.state.locale));
            }
            return res;
        }
    };
    const sendZDHCKeysRequestEmail = async () => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.get('profile/send-keys-request-email');
        if(res)
        {
            if(res.status === 200 && res.data.message === 200)
            {
                return 200;
            }
            return res;
        }
    };

    /* Hooks */
    locale.value = store.state.locale;
    
    return {
        /* Methods */
        updateProfile,
        updateKeys,
        updateSuscriptionExpiration,
        checkForKeys,
        updatePassword,
        deleteAccount,
        sendZDHCKeysRequestEmail
    }
}
export default ProfileService;