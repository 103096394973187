<template>
    <div class="fixed inset-0 p-6 bg-gray-1000 bg-opacity-90 z-30 overscroll-y-contain overflow-y-auto">
        <div class="flex flex-col w-full h-full items-center justify-center">
            <div class="bg-white dark:bg-gray-900 dark:text-gray-400 border dark:border-gray-700 rounded p-5 flex flex-col space-y-5 w-full md:w-2/3 lg:w-1/2 2xl:w-1/3">
                <div class="flex items-start justify-between">
                    <button 
                        title="Close"
                        @click.prevent="$emit('closeModal')"
                        class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                                <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                    c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                    c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                            </svg>
                    </button>
                </div>
                <h1 class="text-xl flex items-center space-x-1 dark:text-gray-200">
                    <span>{{ $t('actions.delete', $store.state.locale) }}</span> 
                    <span>Account</span>
                </h1>
                <p>
                    {{ $t('profile.delete.description', $store.state.locale) }}
                </p>
                
                <div class="flex items-center justify-end space-x-3">
                    
                    <button 
                        @click.prevent="destroy"
                        class="px-5 py-3 font-medium leading-5 text-center bg-white dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800 rounded lg:mt-0  duration-300 lg:w-auto bg-white hover:bg-gray-200 text-blue-500 dark:text-blue-400"
                        >
                        {{ $t('actions.delete', $store.state.locale) }}
                    </button>
                    <button 
                        @click.prevent="$emit('closeModal')"
                        class="px-5 py-3 font-medium leading-5 text-center text-white bg-blue-500 rounded lg:mt-0 hover:bg-blue-300 dark:hover:bg-blue-700 duration-300 lg:w-auto">
                        {{ $t('actions.cancel', $store.state.locale) }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    emits:['closeModal', 'deleteAccount'],
    setup(props, {emit})
    {
        /**
         *
         * Dara
         *
         */
        const destroy = () => 
        {
            emit('deleteAccount');
        }
        return {
           destroy
        }
    }
}
</script>