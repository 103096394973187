<template>
    <div class="bg-white dark:bg-gray-800 shadow rounded w-full p-5 flex flex-col space-y-5">
        <h1 class="text-2xl flex items-center space-x-1">
            Password 
        </h1>
        <hr class="dark:border-gray-700">
        <form class="flex flex-col space-y-5" method="POST" @submit.prevent="updateProfilePassword">
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <!-- Current Password -->
                <div class="">
                    <label for="current_password" class="inline-block mb-1 uppercase">{{ $t('forms.labels.current_password', $store.state.locale) }}<span class="text-light-green-500 dark:text-blue-400">*</span></label>
                    <input id="current_password" name="current_password" v-model="fields.current_password" :placeholder="$t('forms.labels.current_password', $store.state.locale)" 
                        type="password" required autocomplete="off"
                        class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-blue-500 focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400"
                        :class="$store.state.errors.errors.current_password ? 'border-red-500' : 'border-gray-300 focus:border-blue-500'"/>
                    <span
                        v-if="$store.state.errors.errors.current_password" 
                        class="block font-bold text-sm text-red-500 text-center mx-auto" >
                        {{ $store.state.errors.errors.current_password[0] }}
                    </span>
                </div>
                <div></div>
                <!-- New Password -->
                <div class="">
                    <label for="password" class="inline-block mb-1 uppercase">{{ $t('forms.labels.new_password', $store.state.locale) }}<span class="text-light-green-500 dark:text-blue-400">*</span></label>
                    <input id="password" name="password" v-model="fields.password" :placeholder="$t('forms.labels.new_password', $store.state.locale)" 
                        type="password" required autocomplete="off"
                        class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-blue-500 focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400"
                        :class="$store.state.errors.errors.password ? 'border-red-500' : 'border-gray-300 focus:border-blue-500'"/>
                    <span
                        v-if="$store.state.errors.errors.password" 
                        class="block font-bold text-sm text-red-500 text-center mx-auto" >
                        {{ $store.state.errors.errors.password[0] }}
                    </span>
                </div>
                <!-- Confirm Password -->
                <div class="">
                    <label for="password_confirmation" class="inline-block mb-1 uppercase">{{ $t('forms.labels.password_confirmation', $store.state.locale) }}<span class="text-light-green-500 dark:text-blue-400">*</span></label>
                    <input id="password_confirmation" name="password_confirmation" v-model="fields.password_confirmation" :placeholder="$t('forms.labels.password_confirmation', $store.state.locale)" 
                        type="password" required autocomplete="off" 
                        class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-blue-500 focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400"
                        :class="$store.state.errors.errors.password_confirmation ? 'border-red-500' : 'border-gray-300 focus:border-blue-500'"/>
                    <span
                        v-if="$store.state.errors.errors.password_confirmation" 
                        class="block font-bold text-sm text-red-500 text-center mx-auto" >
                        {{ $store.state.errors.errors.password_confirmation[0] }}
                    </span>
                </div>
            </div>
            <div class="flex w-full items-center justify-between">
                <p>
                    <span class="text-light-green-500 dark:text-blue-400">*</span>{{ $t('forms.required_field', $store.state.locale) }}
                </p>
                <button 
                    type="submit" 
                    class="inline-flex items-center justify-center max-w-max h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md focus:shadow-outline focus:outline-none"
                    :class="setSubmitClasses"
                    :title="$t('actions.save', $store.state.locale)"
                    v-html="setSubmitContent"
                    :disabled="updateStatus !== 'void'">
                </button>
            </div>
        </form>
    </div>
</template>
<script>
import { reactive, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import ProfileService from '../../services/ProfileService';
export default 
{
    setup()
    {
        /**
         *
         * Data
         *
         */
        const updateStatus = ref('void');
        const store = useStore();
        const fields = reactive({});
        const { t, locale } = useI18n();
        const {updatePassword} = ProfileService();

        /**
         *
         * Computed
         *
         */
        const setSubmitContent = computed( () => 
        {
            switch(updateStatus.value)
            {
                case 'loading':
                    return `<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
                case 'success':
                    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
                case 'error':
                    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
                case 'void':default:
                    return t('actions.save', locale.value);
            }
        });
        const setSubmitClasses = computed( () =>
        {
            switch(updateStatus.value)
            {
                case 'void': case 'loading': default:
                    return 'bg-blue-500 hover:bg-blue-300';
                case 'error':
                    return `bg-red-500 hover:bg-red-700`;
                case 'success':
                    return `bg-green-500 hover:bg-green-700`;
            }
        });
        
        /**
         *
         * Methods
         *
         */
        const updateProfilePassword = async () => 
        {
            updateStatus.value = 'loading';
            const res = await updatePassword(fields);
            if(res === 200) updateStatus.value = 'success';
            else updateStatus.value = 'error';
            setTimeout( () => updateStatus.value = 'void', 2000); 
        };

        /**
         *
         * Hooks
         *
         */
        locale.value = store.state.locale;

        return {
            /* Methods */
            updateStatus,
            fields,
            /* Computed */
            setSubmitClasses,
            setSubmitContent,
            /* Methods */
            updateProfilePassword
        }
    }
}
</script>