export default

{
    namespaced: true,
    state: 
    {
        errors: {}
    },
    actions: 
    {},
    mutations:{
        SET_ERRORS(state, value)
        {
            state.errors = value;
        },
        PUSH_ERRORS(state, [field, error])
        {
            state.errors[field] = error;
        },
        CLEAR_STATE(state)
        {
            state.errors = {};
        }
    },
    getters:{}
}
