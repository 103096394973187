import moment from 'moment';
import { useApi } from '../composables/useApi';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import SnackbarComposables from '../composables/SnackbarComposables';
const InventoryService = () => 
{
    /* Data */
    const ApiClient = useApi();
    const store = useStore();
    const router = useRouter();
    const { t, locale } = useI18n();
     const { addSnackbar } = SnackbarComposables();

    /* Methods */
    const indexInventory = async(date= moment().format('YYYY-MM-DD'), paginated = true) => 
    {
        store.commit('errors/CLEAR_STATE');
        let endpoint = `inventory/?date=${date}&paginated=${paginated}`;
        const res = await ApiClient.get(endpoint);
        if(res)
        {
            return res.data;
        }
    };
    const storeInventory = async(payload) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.post('inventory/store', payload);
        if(res)
        {
            if(res.status === 200)
            {
                if(res.data.error)
                {
                    addSnackbar('error', res.data.error);
                    return res;
                }
                addSnackbar('success', t('newInventoryModal.report_created', locale.value));
                return 200;
            }
            else if(res.status !== 200 && res !== 422)
            {
                addSnackbar('error', t('snackbar.refresh', locale.value));
            }
            return res;
        }
    };
    const showInventory = async(inventory) =>
    {   
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.get(`inventory/show/${inventory}`);
        if(res)
        {
            if(res.data.inventory)
            {
                return res.data;
            }
            return router.push(`/${store.state.locale}/notFound`);
        }
    };
    const deleteInventory = async (inventoryId) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.delete(`inventory/destroy/${inventoryId}`);
        if(res)
        {
            if(res.status === 200)
            {
                addSnackbar('success', t('reportShow.report_deleted', locale.value))
                return 200;
            }
            addSnackbar('error', t('reportShow.report_delete_error', locale.value))
            return res;
        }
    };

    const downloadReport = async(file) => 
    {
        ApiClient.defaults.headers.responseType = 'arraybuffer';
        const res = await ApiClient.get(`inventory/report/${file}`);
        return res;
    };

    const getCountByDate = async (date) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await ApiClient.get(`inventory/count-by-date/${date}`);
        if(res.data?.inventories || res.data?.inventories === 0)
        {
            return res.data;
        }
        return res;
    }

    /* Hooks */
    locale.value = store.state.locale;

    return {
        /* Methods */
        indexInventory,
        storeInventory,
        showInventory,
        deleteInventory,
        downloadReport,
        getCountByDate
    }
};
export default InventoryService;