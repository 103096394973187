<template>
    <div v-if="$route.meta.breadcrumb" class="flex items-center gap-1"> 
        <router-link 
            v-for="(page, i) in $route.meta.breadcrumb"
            :key="i"
            :class="[i+1 === $route.meta.breadcrumb.length ? 'text-gray-900 font-extrabold' : 'text-gray-400 hover:text-gray-900']"
            :to="page.route"> 
            {{ page.name }} <span v-if="i+1 !== $route.meta.breadcrumb.length">/</span>
        </router-link>
    </div>
</template>