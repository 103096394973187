<script setup>
import {defineProps} from 'vue';
/* Props */
const props = defineProps({
    color:String,
    label: String
})
</script>
<template>
    <div class="flex items-center space-x-2">
        <div class="h-7 w-t min-w-7 rounded-full" :style="`background-color:${props.color}`"></div>
        <span class="">{{ props.label }}</span>
    </div>
</template>