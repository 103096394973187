<template>
    <div id="dashSidebar" class="fixed lg:absolute h-screen md:h-full md:w-1/2 -ml-full lg:ml-0 w-full lg:w-1/5 2xl:w-1/6 duration-300 z-40 lg:z-20 flex flex-col bg-white dark:bg-gray-1000 px-2">
        <div class="h-full lg:ml-0 w-full duration-300 px-8 pb-20 overflow-y-auto scrollbar-hidden lg:overflow-y-hidden pt-10">
            <div class="flex items-center justify-between space-x-5">
                <div 
                    class="flex flex-col  items-start justify-start  space-y-3  min-w-min px-4"> 
                    <img 
                        src="@/assets/img/logo/logo-ympact.png"
                        class="min:w-28 w-28">
                        <h1 class="text-lg text-blue-500">
                            {{ $t('sidebar.hello', $store.state.locale) }},  {{ $store.state.auth.user.name }}!
                        </h1>
                </div>
                <button 
                    :title="$t('actions.close', $store.state.locale)"
                    @click.prevent="$emit('closeSidebar')"
                    class="lg:hidden ml-auto bg-transparent min-w-min flex items-center justify-center duration-300">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                            <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                        </svg>
                </button>
            </div>

            <ul class="mt-14">
                <li class="my-5"> 
                    <router-link 
                        :to="`/${$store.state.locale}/dashboard`" 
                        @click="$emit('closeSidebar')"
                        class="block w-full px-4 py-2 flex items-center space-x-4 min-w-min duration-300"
                        :title="$t('sidebar.products', $store.state.locale)"
                        :class="[$route.name == 'Dashboard' ? 'text-light-green-500 dark:text-blue-400 border-2 dark:border-gray-700 rounded-lg hover:border-light-green-500 dark:hover:border-blue-400' : 'text-blue-500 dark:text-gray-400  hover:text-light-green-500 dark:hover:text-blue-400']"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 fill-current"><path d="M10,13H3a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14A1,1,0,0,0,10,13ZM9,20H4V15H9ZM21,2H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V3A1,1,0,0,0,21,2ZM20,9H15V4h5Zm1,4H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14A1,1,0,0,0,21,13Zm-1,7H15V15h5ZM10,2H3A1,1,0,0,0,2,3v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V3A1,1,0,0,0,10,2ZM9,9H4V4H9Z"/></svg>
                        <span>{{ $t('sidebar.products', $store.state.locale) }}</span>
                    </router-link>
                </li>
                <li class="my-5"> 
                    <router-link 
                        :to="`/${$store.state.locale}/report`" 
                        @click="$emit('closeSidebar')"
                        :title="$t('sidebar.reports', $store.state.locale)"
                        class="block w-full px-4 py-2 flex items-center space-x-4 min-w-min duration-300"
                        :class="[$route.name == 'Report' ? 'text-light-green-500 dark:text-blue-400 border-2 dark:border-gray-700 rounded-lg hover:border-light-green-500 dark:hover:border-blue-400' : 'text-blue-500 dark:text-gray-400  hover:text-light-green-500 dark:hover:text-blue-400']"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 fill-current"><path d="M13,14H9a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2ZM17,4H15.82A3,3,0,0,0,13,2H11A3,3,0,0,0,8.18,4H7A3,3,0,0,0,4,7V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V7A3,3,0,0,0,17,4ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm8,14a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V7A1,1,0,0,1,7,6H8V7A1,1,0,0,0,9,8h6a1,1,0,0,0,1-1V6h1a1,1,0,0,1,1,1Zm-3-9H9a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z"/></svg>
                        <span>{{ $t('sidebar.reports', $store.state.locale) }}</span>
                    </router-link>
                </li>
                <li class="my-5"> 
                    <button 
                        @click="openTutorialModal"
                        class="block w-full px-4 py-2 flex items-center space-x-4 min-w-min duration-300 text-blue-500 dark:text-gray-400  hover:text-light-green-500 dark:hover:text-blue-400"
                        title="Tutorial">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 fill-current"><path d="M11.29,15.29a1.58,1.58,0,0,0-.12.15.76.76,0,0,0-.09.18.64.64,0,0,0-.06.18,1.36,1.36,0,0,0,0,.2.84.84,0,0,0,.08.38.9.9,0,0,0,.54.54.94.94,0,0,0,.76,0,.9.9,0,0,0,.54-.54A1,1,0,0,0,13,16a1,1,0,0,0-.29-.71A1,1,0,0,0,11.29,15.29ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM12,7A3,3,0,0,0,9.4,8.5a1,1,0,1,0,1.73,1A1,1,0,0,1,12,9a1,1,0,0,1,0,2,1,1,0,0,0-1,1v1a1,1,0,0,0,2,0v-.18A3,3,0,0,0,12,7Z"/></svg>
                        <span>Tutorial</span>
                    </button>
                </li>
                <li class="my-5"> 
                    <router-link 
                        :to="`/${$store.state.locale}/settings`" 
                        @click="$emit('closeSidebar')"
                        class="block w-full px-4 py-2 flex items-center space-x-4 min-w-min duration-300"
                        :class="[$route.name == 'Settings' ? 'text-light-green-500 dark:text-blue-400 border-2 dark:border-gray-700 rounded-lg hover:border-light-green-500 dark:hover:border-blue-400' : 'text-blue-500 dark:text-gray-400  hover:text-light-green-500 dark:hover:text-blue-400']"
                        :title="$t('sidebar.settings', $store.state.locale)"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-5 fill-current"><path d="M19.9,12.66a1,1,0,0,1,0-1.32L21.18,9.9a1,1,0,0,0,.12-1.17l-2-3.46a1,1,0,0,0-1.07-.48l-1.88.38a1,1,0,0,1-1.15-.66l-.61-1.83A1,1,0,0,0,13.64,2h-4a1,1,0,0,0-1,.68L8.08,4.51a1,1,0,0,1-1.15.66L5,4.79A1,1,0,0,0,4,5.27L2,8.73A1,1,0,0,0,2.1,9.9l1.27,1.44a1,1,0,0,1,0,1.32L2.1,14.1A1,1,0,0,0,2,15.27l2,3.46a1,1,0,0,0,1.07.48l1.88-.38a1,1,0,0,1,1.15.66l.61,1.83a1,1,0,0,0,1,.68h4a1,1,0,0,0,.95-.68l.61-1.83a1,1,0,0,1,1.15-.66l1.88.38a1,1,0,0,0,1.07-.48l2-3.46a1,1,0,0,0-.12-1.17ZM18.41,14l.8.9-1.28,2.22-1.18-.24a3,3,0,0,0-3.45,2L12.92,20H10.36L10,18.86a3,3,0,0,0-3.45-2l-1.18.24L4.07,14.89l.8-.9a3,3,0,0,0,0-4l-.8-.9L5.35,6.89l1.18.24a3,3,0,0,0,3.45-2L10.36,4h2.56l.38,1.14a3,3,0,0,0,3.45,2l1.18-.24,1.28,2.22-.8.9A3,3,0,0,0,18.41,14ZM11.64,8a4,4,0,1,0,4,4A4,4,0,0,0,11.64,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,11.64,14Z"/></svg>
                        <span>{{ $t('sidebar.settings', $store.state.locale) }}</span>
                    </router-link>
                </li>
               <hr class="dark:border-gray-700">
               <li class="my-5"> 
                    <button 
                        class="block w-full px-4 py-2 flex items-center space-x-4 min-w-min duration-300 text-blue-500 hover:text-red-500"
                        :title="$t('sidebar.logout', $store.state.locale)"
                        @click.prevent="signout"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 fill-current"><path d="M4,12a1,1,0,0,0,1,1h7.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H5A1,1,0,0,0,4,12ZM17,2H7A3,3,0,0,0,4,5V8A1,1,0,0,0,6,8V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v3a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V5A3,3,0,0,0,17,2Z"/></svg>
                        <span>{{ $t('sidebar.logout', $store.state.locale) }}</span>
                    </button>
                </li>
            </ul>
        </div>
        <Teleport to="body">
            <TutorialModal 
                v-if="showTutorialModal"
                @closeModal="closeTutorialModal"
            />
        </Teleport>
    </div>
</template>

<script>
import { ref, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import AuthService from '../../services/AuthService';
export default 
{
    emits:['closeSidebar'],
    components:
    {
        TutorialModal: defineAsyncComponent( () => import('../../components/modals/Tutorial.vue'))
    },
    setup(props, {emit})
    {
        /* 
         *
         * Data
         *
         */
        const router = useRouter();
        const store = useStore();
        const { logout } = AuthService();
        const theme = ref(localStorage.theme);
        const system_theme = ref(window.matchMedia('(prefers-color-scheme: dark)').matches);
        const showTutorialModal = ref(false);

        /* 
         *
         * Methods
         *
         */
        const signout = () => 
        {   
            store.commit('errors/CLEAR_STATE');
            logout()
            .then( res => 
            {
                if(res === 200) return router.push(`/${store.state.locale}/signin`);
            });
        };
        const setTheme = () => 
        {
            theme.value = theme.value === 'dark' ? 'light' : 'dark';
            localStorage.setItem('theme', theme.value);
            checkForTheme();
        }
        const checkForTheme = () => 
        {
            if (theme.value === 'dark' || (!theme.value && system_theme.value)) {
                //document.documentElement.classList.add('dark');
                document.documentElement.classList.remove('dark');
            } else {
                document.documentElement.classList.remove('dark');
            }
        };
        const openTutorialModal = () => 
        {
            showTutorialModal.value = true;
            document.body.classList.add('overflow-hidden');
            emit('closeSidebar');
        };
        const closeTutorialModal = () => 
        {
            showTutorialModal.value = false;
            document.body.classList.remove('overflow-hidden');
        };

        return {
            /* Data */
            showTutorialModal,
            theme,
            system_theme,
            /* Methods */
            signout,
            setTheme,
            openTutorialModal,
            closeTutorialModal
        }
    }
}
</script>

