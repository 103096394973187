<script setup>
import { computed, ref, defineEmits } from 'vue';
import {useI18n} from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import AuthService from '../../services/AuthService';
import ZdhcServiceV5 from '../../services/Zdhc/ZdhcServiceV5';
import ProfileService from '../../services/ProfileService';
import CompanyService from '../../services/CompanyService';
import SnackbarComposables from '../../composables/SnackbarComposables';
/* Emits */
const emits = defineEmits(['newRoute']);
/* Data */
const router = useRouter();
const store = useStore();
const { t, locale } = useI18n();
const logoutStatus = ref('void');
const submitStatus = ref('void');
const newUserKey = ref('');
const { logout } = AuthService();
const { addSnackbar } = SnackbarComposables();
const { inCheckAccessToken } = ZdhcServiceV5();
const { updateKeys } = process.env.VUE_APP_API_ENV === 'lumen' ? ProfileService() : CompanyService();
const supportEmail = process.env.VUE_APP_YMPACT_SUPPORT_EMAIL;
/* Computed */
const setLogoutContent = computed( () => 
{
    switch(logoutStatus.value)
    {
        case 'loading':
            return `<svg class="animate-spin h-5 w-5 fill-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
        case 'success':
            return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
        case 'error':
            return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
        case 'void': default:
            return t('sidebar.logout', locale.value);
    }
});
const setLogoutClasses = computed( () =>
{
    switch(logoutStatus.value)
    {
        case 'void': case 'loading': default:
            return 'text-light-green-500 hover:text-light-green-300';
        case 'error':
            return `text-red-500 hover:text-red-700`;
        case 'success':
            return `text-green-500 hover:text-green-700`;
    }
});
const setSubmitContent = computed( () => 
{
    switch(submitStatus.value)
    {
        case 'loading':
            return `<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
        case 'success':
            return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
        case 'error':
            return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
        case 'void': default:
            return t('zdhc.submitButtonContent', store.state.locale);
    }
});
const setSubmitClasses = computed( () =>
{
    switch(submitStatus.value)
    {
        
        case 'error':
            return `bg-red-500 hover:bg-red-700`;
        case 'success':
            return `bg-green-500 hover:bg-green-700`;
        case 'void': case 'loading': default:
            return 'bg-blue-500 hover:bg-blue-300';
    }
});
/* Methods */
const signout = () => 
{   
    logoutStatus.value = 'loading';
    logout()
    .then( res => 
    {
        if(res === 200) 
        {
            store.commit('errors/CLEAR_STATE');
            setTimeout( () => logoutStatus.value = 'loaded', 1000);
            router.push(`/${store.state.locale}/signin`);
            emits('newRoute', {name: 'Signin'});
        }
    });
};
const updateUserKeys = async() => 
{
    submitStatus.value = 'loading';
    if(!newUserKey.value)
    {   
        submitStatus.value = 'error';
        setTimeout( () => submitStatus.value = 'void', 2000)
        return addSnackbar('error', t('insertKeys.no_keys', locale.value))
    }
    else if(newUserKey.value.length !== 32)
    {
        submitStatus.value = 'error';
        setTimeout( () => submitStatus.value = 'void', 3000)
        return addSnackbar('error', t('insertKeys.invalid_user_key', locale.value))
    }
    // Check for user_key validation
    const keyRes = await inCheckAccessToken(newUserKey.value);
    if(keyRes && keyRes.status === 'success') 
    {
        const res = await updateKeys({user_key: newUserKey.value});
        console.log(res)
        if(res == 200) 
        {
            submitStatus.value = 'void';
            router.push({name: 'Dashboard'});
            emits('newRoute', {name: 'Dashboard'})
        }
    }
    else 
    {
        submitStatus.value = 'error';
        setTimeout( () => submitStatus.value = 'void', 3000)
        return addSnackbar('error', t('insertKeys.invalid_user_key', locale.value))
    }
    /* */

};
/* Hooks */
</script>
<template>
    <div class="w-full h-screen flex flex-col items-center justify-center text-center p-2 space-y-5"> 
        <div class="flex flex-col space-y-5">
            <div class="flex flex-col space-y-2">
                <div class="flex items-center justify-center space-x-2 text-red-500">
                    <svg class="w-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" /></svg>                  
                    <h1 class="text-4xl">
                        Attenzione
                    </h1>
                </div>
                <h3 class="text-2xl">
                    {{ t('zdhc.accessTokenGenerationErrorTitle', store.state.locale) }}
                </h3>
            </div>
            <div class="flex flex-col space-y-2 border p-5 rounded-xl text-left lg:max-w-xl lg:mx-auto">
                <div class="mb-1 sm:mb-2 flex flex-col items-center space-y-1">
                    <label class="inline-block uppercase">{{ t('zdhc.accessTokenGenerationErrorSubTitle', store.state.locale) }} </label>
                    <br>
                    <span v-if="store.state.auth.user" class="font-semibold">
                        {{ store.state.auth.user.user_key ?? 'not found' }}
                    </span>
                </div>
                <div class="mb-1 sm:mb-2">
                    <label for="newUserKey" class="inline-block mb-1 uppercase">{{ t('zdhc.updateUserKey', store.state.locale) }}</label>
                    <input id="newUserKey" v-model="newUserKey" name="newUserKey" :placeholder="t('zdhc.updateUserKey', store.state.locale)" type="text" class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border rounded shadow-sm appearance-none focus:shadow-outline border-gray-300 focus:border-blue-500"/>
                </div>
                <div class="flex items-center justify-end">
                    <button 
                        type="button" 
                        class="inline-flex items-center justify-center max-w-max h-12 px-6 font-medium tracking-wide text-white transition duration-300 rounded shadow-md focus:shadow-outline focus:outline-none"
                        :class="setSubmitClasses"
                        :title="$t('zdhc.submitButtonContent', $store.state.locale)" 
                        v-html="setSubmitContent"
                        :disabled="submitStatus!=='void'"
                        @click.prevent="updateUserKeys">
                    </button>
                </div>
            </div>
        </div>
        <a 
            class="w-full text-left p-2 group duration-300 flex items-center justify-center"
            :href="`mailto:${supportEmail}`">
            <h2 class="text-lg font-semibold group-hover:underline">
                {{ t('zdhc.contactSupport', store.state.locale) }}
            </h2>
        </a>
        <button
            :title="t('sidebar.logout', store.state.locale)"
            class="text-lg flex items-center justify-center"
            @click.prevent="signout"
            :class="setLogoutClasses"
            v-html="setLogoutContent"
            :disabled="logoutStatus !== 'void'">
        </button>
  </div>
</template>