<template>
    <tr v-if="Object.keys(product).length" class="bg-white dark:bg-gray-900 dark:hover:bg-gray-700 duration-300"> 
        <!-- Product Id -->
        <td class="px-4 py-3">
            <span class="text-sm font-thin">
                {{product.id}}
            </span>
        </td>
        <!-- Name -->
        <td class="px-4 py-3">
            {{product.name}}
        </td>
        <!-- Quantity -->
        <td class="px-4 py-3">
            <span class="text-sm font-thin">
                {{ product.incheck_reference && product.incheck_reference.quantity ? product.incheck_reference.quantity / 100 :'-' }}
            </span>
        </td>
        <!-- Formulator -->
        <td class="px-4 py-3">
            <span class="text-sm font-thin">
                {{ setFormulatorName }}
            </span>
        </td>
        
        <!-- Created At -->
        <td class="px-4 py-3">
            <span class="text-sm font-thin">
                {{ setCreatedAtDate }}
            </span>
        </td>
    </tr>
</template>
<script>
import { useStore } from 'vuex';
import { reactive, ref, computed, onMounted } from 'vue';
import moment from 'moment';
export default 
{
    props:
    {
        parent_product:
        {
            required:true
        },
        date: 
        {
            required:true
        }
    },
    setup(props)
    {
        
        /* -------------------------------------- Data ----------------------------------------------- */
        const store = useStore();
        const status = ref('void');
        const product = reactive({});
        moment.locale(store.state.locale)

        /* 
         *
         * Computed
         *
         */
        const setStatusClasses = computed( () => 
        {
            switch(status.value)
            {
                case 'void':
                    return 'bg-blue-500 text-white';
                case 'success':
                    return 'bg-green-50 text-green-500';
                case 'actionRequired':
                    return 'bg-orange-50 text-deep-orange-500';
                case 'suggestion':
                    return 'bg-light-blue-50 text-light-blue-500';
                case 'notFound':
                    return 'bg-red-50 text-red-500';
                default:
                    return 'bg-blue-500 text-white';
                    
            }
        });
        const setStatusContent = computed( () => 
        {
            switch(status.value)
            {
                case 'void':
                    return '<svg class="animate-spin w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>';
                case 'success':
                    return 'Match';
                case 'suggestion':
                    return 'Manual Match';
                case 'actionRequired':
                    return 'Action required';
                case 'notFound':
                    return 'No match';
                default:
                    return '<svg class="animate-spin fill-current w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>';
            }
        });
        const setReferenceDate = computed( () => 
        {
            return product.incheck_reference && product.incheck_reference.reference_date 
                    ? moment(product.incheck_reference.reference_date).format('MMMM YYYY') : '-';
        });
        const setCreatedAtDate = computed( () => 
        {
            return product.created_at ? moment(product.created_at).format('YYYY-MM-DD') : '-';
        });
        const setFormulatorName = computed ( () => 
        {
            return product.formulator 
                    ? (product.formulator.name.length > 15 
                        ? product.formulator.name.substring(0, 12)+'...' 
                        : product.formulator.name) 
                    : '-';
        });
        
        /* 
         *
         * Methods
         *
         */
        /* const init = async () => 
        {
            //Search for locale matches
            let local_res = await searchMatchById(product.id);
            if(local_res)
            {
                local_matches.value = local_matches.value.concat(local_res);
            }

            //Search for ZDHC matches
            let zdhc_res = await searchZdhcProductByWord(product.name);
            if(zdhc_res)
            { 
                if(zdhc_res.length > 1) 
                {
                    zdhc_res.forEach( p => 
                    {
                        zdhc_matches.value.push(p);
                    });
                }
            }

            //Search for Suggestions
            let suggestion_res = await searchSuggestionById(product.id);
            if(suggestion_res)
            {
                if(suggestion_res.length > 1) 
                {
                    if(suggestion_res.some( s => s.count > 15))
                    {
                        let p = suggestion_res.filter(s => s.count > 15)[0];
                        if(p.count >= 15)
                        {
                            p.ProductID = p.id;
                            p.productName = p.name;
                            p.productGUID = p.guid;
                            suggestions_matches.value.push(p);
                        }
                    }
                    else 
                    {
                        suggestion_res.forEach( p => 
                        {
                            if(!suggestions_matches.value.some(s => s.id === p.id))
                            {
                                suggestions_matches.value.push(p);
                            }
                        });
                    }
                }
            }

            if(local_res && zdhc_res && suggestion_res)
            {
                let res = finalCheck(local_res, zdhc_res, suggestion_res);
                if(res === 'trueMatch')
                {
                    status.value = 'success';
                }
                else if(res === 'multipleMatch')
                {
                    if(!product.suggestion)status.value = 'actionRequired';
                    else  status.value = 'suggestion';
                }
                else if(res === 'noMatch')
                {
                    //no Maches -> The algorithm search starts
                    algorithmFirstPhase();
                }

            }

        } */

        
        /* -------------------------------------- Hooks ----------------------------------------------- */
        onMounted( () => 
        {
            Object.assign(product, props.parent_product);
            if(Object.keys(product).length)
            {
                let incheck_reference = product.incheck_references.filter(ir => moment(ir.reference_date).isSame(moment(props.date)))[0];
                product.incheck_reference = incheck_reference;
                //product.incheck_reference = product.incheck_references[0];
            }
        });

        return {
            product,
            status,
            setStatusClasses,
            setStatusContent,
            setReferenceDate,
            setCreatedAtDate,
            setFormulatorName,
        }
    }
}
</script>