<template>
    <tr class="border-b dark:border-gray-700" :class="containsAllFields">
        <td  class="p-2 text-xs">
        <span v-html="product.name ?? '-'"></span>
            <!--{{ product.name ?? '-' }}-->
        </td>
        <td class="p-2 text-xs">
            {{ product.formulator_name ? product.formulator_name : '-'  }}
        </td>
        <td class="p-2 text-xs">
            {{ product.pid ? product.pid : '-'  }}
        </td>
        <td class="p-2 text-xs">
            {{ product.quantity ? product.quantity : '-' }}
        </td>
        <td class="p-2 text-xs">
            {{ product.reference_date ? product.reference_date : '-' }}
        </td>
        <td 
            class="p-2 text-xs"
            :class="product.status === null ? '' : (product.status === true ? 'text-green-500' : 'text-red-500')"
            v-html="setStatusContent">
        </td>
    </tr>
</template> 
<script>
import { computed } from 'vue';
import moment from 'moment';
export default 
{
    props:
    {
        product:{ required: true }
    },
    setup(props)
    {
        /**
         *
         * Computed
         *
         */
        const setStatusContent = computed( () => 
        {
            switch(props.product.status)
            {
                case true:
                    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
                case false:
                    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
                default:
                    return '';
            }
        });
        const containsAllFields = computed( () => 
        {
            if(!props.product.name  
                || !props.product.formulator_name
                || !props.product.quantity
                || !props.product.reference_date || !moment(props.product.reference_date, 'DD-MM-YYYY').isValid()) return 'bg-red-200';
            else return'';
        }); 

        return {
            /* Computed */
            setStatusContent,
            containsAllFields
        }
    }
}
</script>