export const SUPPORTED_LOCALES = 
[
    {
        code: 'it',
        base: '',
        flag: 'it',
        name: 'Italiano'
    },
    {
        code: 'en',
        base: '',
        flag: 'en',
        name: 'English'
    }
]