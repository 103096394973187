import { store } from '../store';
import { exceptions_email } from '../config/exceptions';   
//import moment from 'moment';
export const AUTH = (to, from , next) =>
{
    if(/* store.state.auth.token &&  */store.state.auth.user)
    {
        return next();
    }
    else
    {
        let endpoint_url = `/${store.state.locale}/signin`;
        if(to)
        {
            let q = '';
            let path = to.path.split("/");
            path.forEach( (p) => 
            {
                if(p.length > 0)
                {
                    q = q+`${p}~`;
                }
            });
            q = q.substring(0, q.length - 1);
            endpoint_url = `${endpoint_url}?to=${q}`;
        }
        return next(endpoint_url);
    }

};
export const GUEST = (to, from, next) => 
{
    if(!store.state.auth.user)
    {
        next();
    }
    else
    {
        let endpoint_url = `/${store.state.locale}/dashboard`;
        return next(endpoint_url);
    }
};
export const HAS_KEYS = (to, from , next) =>
{
    if(store.state.auth.user && store.state.auth.user.user_key)
    {
        return next();
    }
    else
    {
        let endpoint_url = `/${store.state.locale}/waiting-page`;
        if(to)
        {
            let q = '';
            let path = to.path.split("/");
            path.forEach( (p) => 
            {
                if(p.length > 0)
                {
                    q = q+`${p}~`;
                }
            });
            q = q.substring(0, q.length - 1);
            endpoint_url = `${endpoint_url}?to=${q}`;
        }
        return next(endpoint_url);
    }

};
export const HAS_ACTIVE_SUBSCRIPTION = (to, from , next) =>
{
    if((store.state.auth.user && store.state.auth.user.active_subscription) 
        || exceptions_email.includes(store.state.auth.user.email.toLowerCase())
        || store.state.auth.user.in_exception)
    {
        return next();
        
    }
    let endpoint_url = `/${store.state.locale}/waiting-page`;
    if(to)
    {
        let q = '';
        let path = to.path.split("/");
        path.forEach( (p) => 
        {
            if(p.length > 0)
            {
                q = q+`${p}~`;
            }
        });
        q = q.substring(0, q.length - 1);
        endpoint_url = `${endpoint_url}?to=${q}`;
    }
    return next(endpoint_url);

};