<template>
    <div class="bg-white dark:bg-gray-800 shadow rounded w-full p-5 flex flex-col space-y-5">
        <h1 class="text-2xl flex items-center space-x-1">
            <span>{{ $t('actions.edit', $store.state.locale) }}</span> 
            <span>{{ $t('settings.profile', $store.state.locale) }}</span> 
        </h1>
        <hr class="dark:border-gray-700">
        <form class="flex flex-col space-y-5" method="POST" @submit.prevent="updateGeneralInfos">
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <!-- Name -->
                <div class="">
                    <label for="name" class="inline-block mb-1 uppercase">{{ $t('forms.labels.name', $store.state.locale) }}<span class="text-light-green-500 dark:text-blue-400">*</span></label>
                    <input id="name" name="name" v-model="fields.name" :placeholder="$t('forms.labels.name', $store.state.locale)" type="name" required
                        class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-blue-500 focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400"
                        :class="$store.state.errors.errors.name ? 'border-red-500' : 'border-gray-300 focus:border-blue-500'"/>
                    <span
                        v-if="$store.state.errors.errors.name" 
                        class="block font-bold text-sm text-red-500 text-center mx-auto" >
                        {{ $store.state.errors.errors.name[0] }}
                    </span>
                </div>
                <!-- Email -->
                <div class="">
                    <label for="email" class="inline-block mb-1 uppercase">Email<span class="text-light-green-500 dark:text-blue-400">*</span></label>
                    <input id="email" name="email" v-model="fields.email" placeholder="Email" type="email" required
                        class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-blue-500 focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400"
                        :class="$store.state.errors.errors.email ? 'border-red-500' : 'border-gray-300 focus:border-blue-500'"/>
                    <span
                        v-if="$store.state.errors.errors.email" 
                        class="block font-bold text-sm text-red-500 text-center mx-auto" >
                        {{ $store.state.errors.errors.email[0] }}
                    </span>
                </div>
                <!-- Vat Number -->
                <!-- <div class="mb-1 sm:mb-2">
                    <label for="vat_number" class="inline-block mb-1 uppercase">{{ $t('auth.signup.vatNumber', $store.state.locale) }}<span class="text-light-green-500 dark:text-blue-400">*</span></label>
                    <input id="vat_number" name="vat_number" v-model="fields.vat_number" :placeholder="$t('auth.signup.vatNumber', $store.state.locale)" type="text" required
                        class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border rounded shadow-sm appearance-none focus:shadow-outline"
                        :class="$store.state.errors.errors.vat_number ? 'border-red-500' : 'border-gray-300 focus:border-blue-500'"/>
                    <span
                        v-if="$store.state.errors.errors.vat_number" 
                        class="block font-bold text-sm text-red-500 text-center mx-auto" >
                        {{ $store.state.errors.errors.vat_number[0] }}
                    </span>
                </div> -->
                <!-- Fiscal Code -->
                <!-- <div class="mb-1 sm:mb-2">
                    <label for="fiscal_code" class="inline-block mb-1 uppercase">{{ $t('auth.signup.fiscalCode', $store.state.locale) }}<span class="text-light-green-500 dark:text-blue-400">*</span></label>
                    <input id="fiscal_code" name="fiscal_code" v-model="fields.fiscal_code" :placeholder="$t('auth.signup.fiscalCode', $store.state.locale)" type="text" required
                        class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border rounded shadow-sm appearance-none focus:shadow-outline"
                        :class="$store.state.errors.errors.fiscal_code ? 'border-red-500' : 'border-gray-300 focus:border-blue-500'"/>
                    <span
                        v-if="$store.state.errors.errors.fiscal_code" 
                        class="block font-bold text-sm text-red-500 text-center mx-auto" >
                        {{ $store.state.errors.errors.fiscal_code[0] }}
                    </span>
                </div> -->
                <!-- Phone -->
                <div class="">
                    <label for="phone" class="inline-block mb-1 uppercase">{{ $t('forms.labels.phone', $store.state.locale) }}</label>
                    <input id="phone" name="phone" v-model="fields.phone" :placeholder="$t('forms.labels.phone', $store.state.locale)" type="text"
                        class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-blue-500 focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400"
                        :class="$store.state.errors.errors.phone ? 'border-red-500' : 'border-gray-300 focus:border-blue-500'"/>
                    <span
                        v-if="$store.state.errors.errors.phone" 
                        class="block font-bold text-sm text-red-500 text-center mx-auto" >
                        {{ $store.state.errors.errors.phone[0] }}
                    </span>
                </div>
            </div>
            <div class="flex w-full items-center justify-between">
                <p>
                    <span class="text-light-green-500 dark:text-blue-400">*</span>{{ $t('forms.required_field', $store.state.locale) }}
                </p>
                <button 
                    type="submit" 
                    class="inline-flex items-center justify-center max-w-max h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md focus:shadow-outline focus:outline-none"
                    :class="setSubmitClasses"
                    :title="$t('actions.save', $store.state.locale)"
                    v-html="setSubmitContent"
                    :disabled="updateStatus !== 'void'">
                </button>
            </div>
        </form>
    </div>
</template>
<script>
import { reactive, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import ProfileService from '../../services/ProfileService';
export default 
{
    setup()
    {
        /**
         *
         * Data
         *
         */
        const store = useStore();
        const updateStatus = ref('void');
        const fields = reactive({
            name: store.state.auth.user.name,
            email: store.state.auth.user.email,
            phone: store.state.auth.user.phone,
            vat_number: store.state.auth.user.vat_number,
            fiscal_code: store.state.auth.user.fiscal_code,
        });
        const { t, locale } = useI18n();
        const {updateProfile} = ProfileService();

        /**
         *
         * Computed
         *
         */
        const setSubmitContent = computed( () => 
        {
            switch(updateStatus.value)
            {
                
                case 'loading':
                    return `<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
                case 'success':
                    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
                case 'error':
                    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
                case 'void': default:
                    return t('actions.save', locale.value);
            }
        });
        const setSubmitClasses = computed( () =>
        {
            switch(updateStatus.value)
            {
                case 'void': case 'loading': default:
                    return 'bg-blue-500 hover:bg-blue-300';
                case 'error':
                    return `bg-red-500 hover:bg-red-700`;
                case 'success':
                    return `bg-green-500 hover:bg-green-700`;
            }
        });

        /**
         *
         * Methods
         *
         */
        const updateGeneralInfos = async () => 
        {
            updateStatus.value = 'loading';
            const res = await updateProfile(fields);
            if(res === 200) updateStatus.value = 'success';
            else updateStatus.value = 'error';
            setTimeout( () => updateStatus.value = 'void', 2000); 
        };

        /**
         *
         * Hooks
         *
         */
        locale.value = store.state.locale;

        return {
            /* Data */
            updateStatus,
            fields,
            /* Computed */
            setSubmitClasses,
            setSubmitContent,
            /* Methods */
            updateGeneralInfos
        }
    }
}
</script>