<template>
    <div v-if="fields" class="w-full flex flex-col px-2 py-3 border rounded">
        <button
            @click.prevent="showData = !showData" 
            class="w-full flex items-center justify-between space-x-5">
            <span class="text-lg uppercase">{{ setReferenceDate }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 min-w-5 fill-current transform" :class="{'rotate-180':showData}"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/></svg>
        </button>

        <div v-if="showData">
            <hr class="mb-5">
            <!-- Quantity -->
            <div class="mb-1 sm:mb-2">
                <label for="quantity" class="inline-block mb-1 uppercase">{{ $t('product.create.quantity', $store.state.locale) }}  (kg)<span class="text-light-green-500 dark:text-blue-400">*</span></label>
                <input 
                    id="quantity" v-model="fields.quantity" required name="quantity"
                    :placeholder="$t('product.create.quantity', $store.state.locale)"  
                    type="number" step="0.01" :disabled="fields.hasInventory"
                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200  border border-gray-300 rounded shadow-sm appearance-none focus:border-blue-500 focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400"
                    :class="fields.hasInventory ? 'bg-gray-100 text-gray-400' : 'bg-white'"/>
                <span
                    v-if="$store.state.errors.errors.quantity" 
                    class="text-sm text-red-500" >
                    {{ $store.state.errors.errors.quantity[0] }}
                </span>
            </div>
            <div class="flex items-center justify-end w-full">
                <button 
                    :title="$t('actions.save', $store.state.locale)"
                    @click.prevent="updateReference" 
                    class="flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-300 rounded shadow-md focus:shadow-outline focus:outline-none"
                    :disabled="updateStatus !== 'void'"
                    :class="setSubmitClasses"
                    v-html="setSubmitContent">
                </button> 
            </div>
        </div>

    </div>
</template>
<script>
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import {onMounted, reactive, ref, computed} from 'vue';
import IncheckReferenceService from '../../services/IncheckReferenceService';
import SnackbarComposables from '../../composables/SnackbarComposables';

export default 
{
    props:{ parentInchecReference: {required:true}},
    setup(props)
    {
        /**
         *
         * Data
         *
         */
        const { t } = useI18n();
        const store = useStore();
        const showData = ref(false);
        const updateStatus = ref('void');
        const fields = reactive({});
        const {updateIncheckReference} = IncheckReferenceService();
        const {addSnackbar} = SnackbarComposables();


        /**
         *
         * Computed
         *
         */
        const setReferenceDate = computed( () => 
        {
            return fields && fields.reference_date 
                    ? moment(fields.reference_date).format('MMMM YYYY') : '-';
        });
        const setSubmitContent = computed( () => 
        {
            switch(updateStatus.value)
            {
                case 'void': default:
                    return t('actions.save', store.state.locale);
                case 'loading':
                    return `<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
                case 'success':
                    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
                case 'error':
                    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
            }
        });
        const setSubmitClasses = computed( () =>
        {
            switch(updateStatus.value)
            {
                case 'void': case 'loading': default:
                    return 'bg-blue-500 hover:bg-blue-300';
                case 'error':
                    return `bg-red-500 hover:bg-red-700`;
                case 'success':
                    return `bg-green-500 hover:bg-green-700`;
            }
        });

        /**
         *
         * Computed
         *
         */
         const updateReference = async () => 
         {
            if(fields.hasInventory)
            {
                addSnackbar('warning', t('product.update.hasInventory', store.state.locale))
            }
            else 
            {
                updateStatus.value = 'loading';
                const res = await updateIncheckReference(fields.id, fields);
                if(res === 200) updateStatus.value = 'success';
                else updateStatus.value = 'error';
                setTimeout( () => updateStatus.value = 'void', 2000);
            }
         }
        /**
         *
         * Hooks
         *
         */
        onMounted( () => 
        {
            Object.assign(fields, props.parentInchecReference);
            fields.quantity = fields.quantity / 100;
            moment.locale(store.state.locale);
        });

        return {
            /* Data */
            updateStatus,
            fields,
            showData,
            /* Computed */
            setReferenceDate,
            setSubmitContent,
            setSubmitClasses,
            /* Methods */
            updateReference
        }

    }
}
</script>