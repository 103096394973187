<template>
    <div class="min-h-screen py-10 px-5 lg:p-10 lg:overflow-x-hidden flex flex-col space-y-10">
        <DashboardNavbar 
            :breadcrumb="breadcrumb"
        />
        <h1 class="text-2xl">
            {{ $t('sidebar.settings', $store.state.locale) }}
        </h1>
        <div class="flex flex-col w-full lg:container mx-auto">
            <div  v-for="(s, i) in sections" :key="s">
                <hr class="border-gray-300 dark:border-gray-700">
                <button 
                    class="flex items-center justify-between w-full space-x-5 py-4 duration-300"
                    :class="active_section !== s ? 'text-gray-400 hover:text-light-green-500 dark:hover:text-blue-400' : 'text-blue-500 dark:text-blue-400'"
                    @click.prevent="setActiveSection(s)">
                    <span>{{ $t(`settings.${s}`, $store.state.locale) }}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-6 fill-current duration-300 transform"
                        :class="{'rotate-45' : active_section === s}"
                        ><path d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"/></svg>
                </button>
                <div v-show="active_section === s" class="w-full py-5">
                    <div v-if="active_section === 'profile'">
                        <Profile/>
                    </div>
                    <div v-if="active_section === 'security'" class="flex flex-col space-y-5">
                        <Security/>
                        <DeleteProfile />
                    </div>
                </div>
                <hr v-if="i === sections.length-1" class="border-gray-300 dark:border-gray-700">
            </div>
        </div>
        <!-- Locale -->
        <div class="flex flex-col w-full items-start lg:container space-y-5">
            <div class="flex items-center space-x-1">
                <span>
                    {{ $t('settings.localeSettings', $store.state.locale) }}:
                </span>
                <div class="relative text-left">
                    <button 
                        @click.prevent="localeMenu = !localeMenu" 
                        v-click-away="closeLocaleMenu"
                        class="min-w-min flex items-center justify-center hover:text-blue-500 border rounded-md p-2 bg-white dark:bg-gray-800 dark:border-gray-700 dark:hover:text-blue-400">
                        <span class="duration-300 text-sm px-2 capitalize">
                            {{ $store.state.locale }}
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current text-gray-500"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/></svg>
                    </button>
                    <div v-if="localeMenu" class="origin-top-right absolute left-0 mt-2 min-w-max max-h-32 overflow-y-auto overscroll-contain rounded shadow-lg bg-white dark:bg-gray-900 border focus:outline-none z-10" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <div class="flex flex-col " role="none">
                            <button
                                v-for="(locale,i) in availableLocales" 
                                :key="locale.code"
                                class="py-2 px-7 duration-300"
                                :class="[locale.code === $store.state.locale ? 'bg-blue-500 hover:bg-blue-300 text-white' : 'dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-gray-700', {'rounded-t' : i === 0}, {'rounded-b' : i === availableLocales.length}]"
                                @click.prevent="setLocale(locale.code)">
                                {{ locale.name }}
                            </button>
                            
                        </div>
                    </div>
                </div>
            </div>
            <!-- Logout -->
           <button 
                class="px-4 py-2 flex items-center rounded space-x-4 min-w-min duration-300 text-white bg-red-500 hover:bg-red-700"
                :title="$t('sidebar.logout', $store.state.locale)"
                @click.prevent="signout"
            >
                <span>{{ $t('sidebar.logout', $store.state.locale) }}</span>
            </button>
        </div>

    </div>
</template>
<script>
import DashboardNavbar from '../layouts/navbars/DashboardNavbar.vue';
import Profile from '../sections/settings/Profile.vue';
import Security from '../sections/settings/Security.vue';
import DeleteProfile from '../sections/settings/DeleteProfile.vue';
import AuthService from '../services/AuthService';
import { SUPPORTED_LOCALES } from '../config/locales';
import { ref, reactive, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default 
{
    components:
    {
        DashboardNavbar,
        Profile,
        Security,
        DeleteProfile
    },
    setup()
    {
        /**
         *
         * Data
         *
         */
        const active_section = ref('');
        const store = useStore();
        const router = useRouter();
        const { t } = useI18n(); 
        const localeMenu = ref(false);
        const sections = ref([
            'profile',
            'security'
        ]);
        const { logout } = AuthService();
        const availableLocales = ref([]);
         const breadcrumb = reactive([
            { name: 'Settings', label: 'settings', to: `/${store.state.locale}/settings` },
        ])
       // const active_locale = ref(store.state.locale);

        /**
         *
         * Methods
         *
         */
        const setActiveSection = (section) => 
        {
            return !sections.value.includes(section) || active_section.value === section 
                    ? active_section.value = '' 
                    : active_section.value = section;
        }

        const signout = () => 
        {   
            store.commit('errors/CLEAR_STATE');
            logout()
            .then( res => 
            {
                if(res === 200) return router.push(`/${store.state.locale}/signin`);
            });
        }
        const closeLocaleMenu = () => 
        {
            localeMenu.value = false;
        }

        const setLocale = (locale) =>
        {
            store.commit('SET_LOCALE', locale)
        }

        /**
         *
         * Hooks
         *
         */
        onMounted( () => 
        {
            SUPPORTED_LOCALES.forEach(l =>
            {
                availableLocales.value.push(l);
            })
        });

        return {
            //active_locale,
            sections,
            t,
            breadcrumb,
            availableLocales,
            localeMenu,
            active_section,
            setActiveSection,
            signout,
            closeLocaleMenu,
            setLocale
        }
    }
}
</script>
