export const exceptions_email =  [
    'francesca.tardelli@processfactory.it',
    /* 'ceo@omnes.cloud', */
    'rpiacentini@scrspa.it',
    /* 'stefano.delgobbo@theidfactory.com', */
    'stefano.delgobbo@gmail.com',
    'csr@vitalebarberiscanonico.it',
    'raffaele.guzzon@miroglio.com',
    'eugenio.dellapiana@miroglio.com',
    'g.esposito@vignolanobile.com',
    'laboratorio@antiba.it',
    'i.carrozzo@isaseta.it',
    'elena@conceriabertini.it',
    'michele@nuovaimpala.com',
    'maurizio@laboratoriobest.com',
    'davidbiondi@dolmenspa.com',
    'a.bettarini@cilp.it',
    'giacomo.p@bcn.it',
    'lrosticci@quadrif.it',
    'bnacci@quadrif.it',
    'o.gorga@leoshoes.it',
    'andreana.errichiello@guerrierosrl.com',
    'edoardo.baldini@conceriavaldarno.it',
    'sustainability@lanificiodisordevolo.com',
    'qualita@tessituragiussani.com',
    'sustainability.milano@bonaudo.com',
    'mauro.regaldi@loropiana.com'
];