import { useZdhcAPIClient } from '../../composables/useZdhcApiClient';
import { useStore } from "vuex";
import moment from 'moment';
import GoogleService from '../GoogleService';

const ZdhcService = () => 
{
    /* Data */
    const zdhcApiClient = useZdhcAPIClient()
    const store = useStore();
    const {translate} = GoogleService();

    /* Methods */
    // Retrieve Gateway Access Token
    const InCheck = async (UserAccessKey) => 
    {
        store.commit('errors/CLEAR_STATE');
        // Setting authenticationKey based on APP_ENV variable
        const authenticationKey = process.env.VUE_APP_ENV === 'local' ? process.env.VUE_APP_ZDHC_AUTH_KEY_BETA : process.env.VUE_APP_ZDHC_AUTH_KEY;
        // Setting authenticationKey based on APP_ENV variable
        //const UserAccessKey = process.env.VUE_APP_ENV === 'local' ? process.env.VUE_APP_ZDHC_USER_KEY_BETA : process.env.VUE_APP_ZDHC_USER_KEY;
            const res = await zdhcApiClient.post('oauth/tokenbykey', {
            "authenticationKey": authenticationKey,
            "UserAccessKey": UserAccessKey
        });
        if(res)
        {
            if(res.data.result.success === true)
            {
                if(res.data.token)
                {
                    if(res.data.token.tokenType && res.data.token.tokenType === 'bearer')
                    {
                        zdhcApiClient.defaults.headers.common['Authorization'] = `Bearer ${res.data.token.accessToken}`;
                        zdhcApiClient.defaults.headers.common['accessToken'] = `${res.data.token.accessToken}`;
                    }
                }
                return {status: 'success'};
            }
            else
            {
                return {status: 'error'};
            }
        }
    };
    const userOrganizations = async () => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await zdhcApiClient.get(`v2/userOrganizations`);
        if(res)
        {
            return res.data;
        }
    };
    const searchZdhcProductByWord = async (product ) => 
    {
        store.commit('errors/CLEAR_STATE');
        let matchesArray = [];
        let encodedProduct = encodeURIComponent(product);
        let endpoint = `v2/products?search=${encodedProduct}`;
        /* if(source) */ /* endpoint+= `&Source=2` */
        const res = await zdhcApiClient.get(endpoint);
        if(res)
        {
            if(res.data.result.success) {
              //map dei risultati
                res.data.data.forEach( p => 
                {
                    translate(p.productName)
                    .then(translation_res => 
                    {
                        let translated_name = translation_res.data.translations[0].translatedText;
                        let detected_lang = translation_res.data.translations[0].detectedSourceLanguage;
                        if(p.productName.toLowerCase() === translated_name.toLowerCase())
                        {
                            p.translated_names = { [detected_lang] :  p.productName.toLowerCase() };
                        }
                        else 
                        {
                            p.translated_names = 
                            { 
                                [detected_lang] :  p.productName.toLowerCase(),
                                'en' : translated_name.toLowerCase(),
                            };
                        }
                        //storeProduct(p);
                    }); 
                    // Map match fields
                    matchesArray.push({
                        id: p.ProductID,
                        guid: p.productGUID,
                        formulator_name : p.formulatorName,
                        name: p.productName.toLowerCase(),
                        fullProduct: p
                    });
                });
                return matchesArray;
            }
            else if(res.data.result.errorMessage)
            {
                if(res.data.result.errorMessage === 'No results found.') return [];
            } 
        }
    };
    const searchByFormulator = async (product, formulator) => 
    {
        store.commit('errors/CLEAR_STATE');
        let matchesArray = [];
        let encodedProduct = encodeURIComponent(product);
        let encodedFormulator = encodeURIComponent(formulator);
        const res = await zdhcApiClient.get(`v2/products?search=${encodedProduct}&FormulatorName=${encodedFormulator}&Source=2`);
        if(res)
        {
            if(res.data.result.success) 
            {
                res.data.data.forEach( p => 
                {
                    translate(p.productName)
                    .then(translation_res => 
                    {
                        let translated_name = translation_res.data.translations[0].translatedText;
                        let detected_lang = translation_res.data.translations[0].detectedSourceLanguage;
                        if(p.productName.toLowerCase() === translated_name.toLowerCase())
                        {
                            p.translated_names = { [detected_lang] :  p.productName.toLowerCase() };
                        }
                        else 
                        {
                            p.translated_names = 
                            { 
                                [detected_lang] :  p.productName.toLowerCase(),
                                'en' : translated_name.toLowerCase(),
                            };
                        }
                        //storeProduct(p);
                    }); 
                    matchesArray.push({
                        id: p.ProductID,
                        guid: p.productGUID,
                        formulator_name : p.formulatorName,
                        name: p.productName.toLowerCase(),
                        fullProduct: p
                    })
                });
                return matchesArray;
            }
            else if(res.data.result.errorMessage)
            {
                if(res.data.result.errorMessage === 'No results found.') return 404;
                return res;
            } 
            else return res;
        }
    };
    const FormulatorsIndex = async () => 
    {
        store.commit('errors/CLEAR_STATE');
        let res = await zdhcApiClient.get(`v2/formulators?recordLimit=9999999`);
        if(res)
        {
            if(res.data.result.success) 
            {
                return res.data
            }
            else if(res.data.result.errorMessage)
            {
                return 404;
            } 
        }
    };
    // V4 IncheckReport -> use "OrganizationGUID" in the options
    const incheckReport = async (payload) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await zdhcApiClient.post(`v4/incheckReport`, payload);
        if(res)
        {
            if(res.data.result?.success) 
            {
                return res.data
            }
            else if(res.data.result?.errorMessage)
            {
                return 404;
            } 
        }
    };
    // V5 IncheckReport -> use "OrganizationGUID" in the options
    const incheckReportV5 = async (payload) => 
    {
        store.commit('errors/CLEAR_STATE');
        console.log(zdhcApiClient.defaults.headers.common['accessToken'])
        const res = await zdhcApiClient.post(`v5/incheckReport`, payload);
        if(res)
        {
            if(res.data.result?.success) 
            {
                return res.data
            }
            else if(res.data.result?.errorMessage)
            {
                return 404;
            } 
        }
    };
    const Certificates = async () => 
    {
        store.commit('errors/CLEAR_STATE');
        await zdhcApiClient.get(`v2/certificates?productId=107750`);
    };
    const getProducts = async () => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await zdhcApiClient.get(`v2/products?recordLimit=800`);
        if(res)
        {
            if(res.data.result.success) {
                return res.data
            }
            else if(res.data.result.errorMessage)
            {
                if(res.data.result.errorMessage === 'No results found.') return [];
            } 
        }
    };
    const getSubscriptionStatus = async (organizationGUID) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await zdhcApiClient.get(`v2/subscriptionStatus?organizationGUID=${organizationGUID}`);
        if(res)
        {
            // If expirationDate is valid
            if(res.data?.SubscriptionStatus[0]?.InCheckExpirationDate)
            {
                const expirationDate = res.data.SubscriptionStatus[0].InCheckExpirationDate;
                if(moment(expirationDate).isSameOrAfter(moment()))
                {
                    //moment('2038-01-19 00:00:00')
                    return {
                        status: 'ok',
                        expirationDate: moment(expirationDate).format('YYYY-MM-DD HH:mm:ss')
                    }
                }
                else 
                {
                    // if expirationDate is '1901-01-01':
                    // 1 - activate subscription (renew is not possible)
                    // 2 - else renew
                    if(expirationDate == '1901-01-01')
                    {
                        return activateSubscription(organizationGUID);
                    }
                    else if(moment(expirationDate).isSameOrBefore)
                    {
                        return renewSubscription(organizationGUID);
                    }
                }
            }

            return {
                status: 'ko',
                expirationDate: null
            }
        }
    };
    const activateSubscription = async (organizationGUID) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await zdhcApiClient.post(`v2/activateInCheckSubscription?organizationGUID=${organizationGUID}`);
        if(res)
        {
            var date = res.data.Message.substr(res.Message.length - 10); 
            return {
                status: 'ok',
                expirationDate: moment(date).format('YYYY-MM-DD 00:00:00')
            }
        }
        return {
            status: 'ko',
            expirationDate: null
        }
    };
    const renewSubscription = async (organizationGUID) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await zdhcApiClient.post(`v2/renewInCheckSubscription?organizationGUID=${organizationGUID}`);
        if(res)
        {
            var date = res.data.Message.substr(res.Message.length - 10); 
            return {
                status: 'ok',
                expirationDate: moment(date).format('YYYY-MM-DD HH:mm:ss')
            }
        }
        return {
            status: 'ko',
            expirationDate: null
        }
    };
    const storeNewProduct = async (productName, formulatorGuid = null) => 
    {
        store.commit('errors/CLEAR_STATE');
        // If formulatorGUID is not present, 
        if(!formulatorGuid) formulatorGuid = process.env.VUE_APP_FOMULATOR;
        const res = await zdhcApiClient.post('v2/products', {name: productName, formulatorGUID:formulatorGuid});
        if(res.data?.productGUID) return {guid: res.data.productGUID};
        return res;
    };
    const getProductByGuid = async (guid) => 
    {
        let res = await zdhcApiClient.get(`v2/products?productGUID=${guid}`);

        if(res)
        {
            return res;
        }
    };
    

    return {
        /* Methods */
        InCheck,
        userOrganizations,
        searchZdhcProductByWord,
        searchByFormulator,
        FormulatorsIndex,
        incheckReport,
        incheckReportV5,
        Certificates,
        getProducts,
        getSubscriptionStatus,
        activateSubscription,
        renewSubscription,
        storeNewProduct,
        getProductByGuid
    }
}
export default ZdhcService;





