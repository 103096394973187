import { useZdhcAPIClient } from '../../composables/useZdhcApiClient';
import { useStore } from "vuex";
import moment from 'moment';
import GoogleService from '../GoogleService';

const ZdhcService = () => 
{
    /* Data */
    const store = useStore();
    const {translate} = GoogleService();
    const zdhcApiClient = useZdhcAPIClient()

    /* Methods */
    // Retrieve Gateway Access Token
    const inCheckAccessToken = async (UserAccessKey) => 
    {
        store.commit('errors/CLEAR_STATE');
        // Setting authenticationKey based on APP_ENV variable
        const authenticationKey = process.env.VUE_APP_ENV === 'local' ? process.env.VUE_APP_ZDHC_AUTH_KEY_BETA : process.env.VUE_APP_ZDHC_AUTH_KEY;
        // Setting authenticationKey based on APP_ENV variable
        //const UserAccessKey = process.env.VUE_APP_ENV === 'local' ? process.env.VUE_APP_ZDHC_USER_KEY_BETA : process.env.VUE_APP_ZDHC_USER_KEY;
            const res = await zdhcApiClient.post('oauth/tokenbykey', {
            "authenticationKey": authenticationKey,
            "UserAccessKey": UserAccessKey
        });
        if(res)
        {
            if(res.data.result.success === true)
            {
                if(res.data.token)
                {
                    if(res.data.token.tokenType && res.data.token.tokenType === 'bearer')
                    {
                        zdhcApiClient.defaults.headers.common['Authorization'] = `Bearer ${res.data.token.accessToken}`;
                        zdhcApiClient.defaults.headers.common['accessToken'] = `${res.data.token.accessToken}`;
                    }
                }
                return {status: 'success'};
            }
            else
            {
                return {status: 'error'};
            }
        }
    };
    const PurchaseInCheckSubscription = async (organizationGUID) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await zdhcApiClient.post(`v5/PurchaseInCheckSubscription?OrgGUID=${organizationGUID}`);
        if(res)
        {
            if (res.data?.result?.Success) return 200;
            else return res;
        }
    };
    const inCheckReport = async (payload) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await zdhcApiClient.post(`v5/incheckReport`, payload);
        if(res)
        {
            if(res.data.result?.Success) 
            {
                return res.data
            }
            else if(res.data.result?.errorMessage)
            {
                return 404;
            } 
        }
    };
    const inCheckSubscriptionStatus = async (organizationGUID) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await zdhcApiClient.get(`v5/InCheckSubscriptionStatus?organizationGUID=${organizationGUID}`);
        if(res.data.InCheckStatus)
        {
            if(res.data.InCheckStatus === 'Active')
            {
                const expirationDate = res.data.InCheckExpirationDate;
                if(moment(expirationDate).isSameOrAfter(moment()))
                {
                    //moment('2038-01-19 00:00:00')
                    return {
                        status: 'ok',
                        expirationDate: moment(expirationDate).format('YYYY-MM-DD HH:mm:ss')
                    }
                }
                else
                {
                    // renew subscription
                    return renewInCheckSubscription(organizationGUID);
                }
            }
            else if(res.data.InCheckStatus === 'Inactive')
            {
                return renewInCheckSubscription(organizationGUID);
            }
            else if(res.data.InCheckStatus === 'Subscribed but not yet activated/used')
            {
                return {
                    status: 'ok',
                    expirationDate: null
                }
            }
            else if(res.data.InCheckStatus.includes('No subscription found'))
            {
                const purhcaseRes = await PurchaseInCheckSubscription(organizationGUID);
                if(purhcaseRes === 200)  return inCheckSubscriptionStatus(organizationGUID);
                else 
                {
                    return {
                        status: 'ko',
                        expirationDate: null
                    }
                }
            }
            else 
            {
                return {
                    status: 'ko',
                    expirationDate: null
                }
            }
        }
        return {
            status: 'ko',
            expirationDate: null
        }
    };
    const renewInCheckSubscription = async (organizationGUID) => 
    {
        store.commit('errors/CLEAR_STATE');
        const res = await zdhcApiClient.post(`v5/RenewInCheckSubscription?OrgGUID=${organizationGUID}`);
        if(res.data.result)
        {
            if(res.data.result.Success)
            {
                var date = res.data.result.Message.substr(res.data.result.Message.length - 10); 
                return {
                    status: 'ok',
                    expirationDate: moment(date).format('YYYY-MM-DD HH:mm:ss')
                }
            }
        }
        return {
            status: 'ko',
            expirationDate: null
        };
    };
    const getZdhcProducts = async (source = 2) => 
    {
        let obj = {source: source};
        obj.recordLimit = 800;
        const endpoint = `v5/products`;
        let params = new URLSearchParams(obj);
        const res = await zdhcApiClient.get(`${endpoint}?${params}`);
        if(res)
        {
            if(res.data.result.success) {
                return res.data
            }
            else if(res.data.result.errorMessage)
            {
                if(res.data.result.errorMessage === 'No results found.') return [];
            } 
        }
    };
    const searchZdhcProductByWord = async (product, limit = 800, source = 3) => 
    {
        let matchesArray = [];
        let params = '';
        let obj = {source: source};
        const endpoint = 'v5/products';
        if(limit) obj.recordLimit =  limit;
        if(product) obj.search =  product;
        if(Object.keys(obj).length) params = new URLSearchParams(obj);
        const res = await zdhcApiClient.get(`${endpoint}?${params}`);
        if(res)
        {
            if(res.data.result.success) {
                //map dei risultati
                let resulsts = res.data.data.filter(p => p.source == 'ZDHC Gateway');
                resulsts.forEach( p => 
                {
                    translate(p.productName)
                    .then(translation_res => 
                    {
                        let translated_name = translation_res.data.translations[0].translatedText;
                        let detected_lang = translation_res.data.translations[0].detectedSourceLanguage;
                        if(p.productName.toLowerCase() === translated_name.toLowerCase())
                        {
                            p.translated_names = { [detected_lang] :  p.productName.toLowerCase() };
                        }
                        else 
                        {
                            p.translated_names = 
                            { 
                                [detected_lang] :  p.productName.toLowerCase(),
                                'en' : translated_name.toLowerCase(),
                            };
                        }
                        //storeProduct(p);
                    }); 
                    // Map match fields
                    matchesArray.push({
                        id: p.productID,
                        guid: p.productGUID,
                        formulator_name : p.formulatorName,
                        name: p.productName.toLowerCase(),
                        fullProduct: p
                    });
                });
                return matchesArray;
            }
            else if(res.data.result.errorMessage)
            {
                if(res.data.result.errorMessage === 'No results found.') return [];
            } 
        }
    };
    const searchZdhcProductByFormulator = async (product, formulator, source = 3, limit = 800) => 
    {
        store.commit('errors/CLEAR_STATE');
        let matchesArray = [];
        let params = '';
        let obj = {source: source, limit: limit};
        const endpoint = `v5/products`;
        if(product) obj.search =  product;
        if(product) obj.formulatorName =  formulator;
        if(Object.keys(obj).length) params = new URLSearchParams(obj);
        const res = await zdhcApiClient.get(`${endpoint}?${params}`);
        if(res)
        {
            if(res.data.result.success) 
            {
                res.data.data.forEach( p => 
                {
                    if(p.source && p.source == 'ZDHC Gateway')
                    {
                        translate(p.productName)
                        .then(translation_res => 
                        {
                            let translated_name = translation_res.data.translations[0].translatedText;
                            let detected_lang = translation_res.data.translations[0].detectedSourceLanguage;
                            if(p.productName.toLowerCase() === translated_name.toLowerCase())
                            {
                                p.translated_names = { [detected_lang] :  p.productName.toLowerCase() };
                            }
                            else 
                            {
                                p.translated_names = 
                                { 
                                    [detected_lang] :  p.productName.toLowerCase(),
                                    'en' : translated_name.toLowerCase(),
                                };
                            }
                            //storeProduct(p);
                        }); 
                        matchesArray.push({
                            id: p.productID,
                            guid: p.productGUID,
                            formulator_name : p.formulatorName,
                            name: p.productName.toLowerCase(),
                            fullProduct: p
                        });
                    }
                });
                return matchesArray;
            }
            else if(res.data.result.errorMessage)
            {
                if(res.data.result.errorMessage === 'No results found.') return 404;
                return res;
            } 
            else return res;
        }
    };
    const searchZdhcProductByGuid = async (guid, source = 3) => 
    {
        const endpoint = `v5/products`;
        let obj = {source: source};
        obj.productGUID = guid;
        let params = new URLSearchParams(obj);
        let res = await zdhcApiClient.get(`${endpoint}?${params}`);
        return res;
    };
    const searchZdhcProductByZdhcPid = async (zdhcPid, source = 2) => 
    {
        const endpoint = `v5/products`;
        let obj = {source: source};
        obj.zdhcPid = zdhcPid;
        let params = new URLSearchParams(obj);
        let res = await zdhcApiClient.get(`${endpoint}?${params}`);
        if(res.data) return res.data;
        return res;
    };
    

    return {
        /* Methods */
        inCheckAccessToken,
        PurchaseInCheckSubscription,
        inCheckReport,
        inCheckSubscriptionStatus,
        renewInCheckSubscription,
        getZdhcProducts,
        searchZdhcProductByWord,
        searchZdhcProductByFormulator,
        searchZdhcProductByGuid,
        searchZdhcProductByZdhcPid
    }
}
export default ZdhcService;





