<script setup>
import { ref, defineEmits, defineProps, computed, defineAsyncComponent, onMounted} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore} from 'vuex';
import SnackbarComposables from '../../../composables/SnackbarComposables';
import ProductService from '../../../services/ProductService';
/* Components */
const NoMatchButton = defineAsyncComponent( () => import('./NoMatchButton.vue'));
/* Emits */
const emits = defineEmits(['PidUpdated', 'newNoMatch']);
/* Props */
const props = defineProps({
    product:{required:true},
    productStatus:{required:true}
});
/* Data */
const { t } = useI18n();
const newPid = ref('');
const store = useStore();
const submitStatus = ref('void');
const { addSnackbar } = SnackbarComposables();
const {updatePidProduct} = ProductService();
/* Computed */
const setSubmitContent = computed( () => 
{
    switch(submitStatus.value)
    {
        case 'void': default:
            return t('actions.save', store.state.locale);
        case 'loading':
            return `<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
        case 'success':
            return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
        case 'error':
            return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
    }
});
const setSubmitClasses = computed( () =>
{
    switch(submitStatus.value)
    {
        case 'void': case 'loading': default:
            return 'bg-blue-500 hover:bg-blue-300';
        case 'error':
            return `bg-red-500 hover:bg-red-700`;
        case 'success':
            return `bg-green-500 hover:bg-green-700`;
    }
});
/* Methods */
const updatePid = async () =>
{
    if(!newPid.value) return addSnackbar('error', t('updatePidModal.noPidError', store.state.locale));

    submitStatus.value = 'loading';
    setTimeout( () => {if(submitStatus.value == 'loading') submitStatus.value = 'void';}, 5000);
    const res = await updatePidProduct(props.product.id, {new_pid: newPid.value});
    if(res.status)
    {
        if(res.status == 'success')
        {
            addSnackbar('success', t('updatePidModal.updatedMessage', store.state.locale));
            submitStatus.value = 'void';
            return emits('PidUpdated', newPid.value);
        }
        else if(res.status == 'error')
        {
            if(res.message)
            {
                if(res.message == 'pid_already_stored') addSnackbar('error', t('updatePidModal.PidAlreadyStored', store.state.locale));
            }
        }
    }
    submitStatus.value = 'void';
};
const newNoMatch = (noMatchGuid) => emits('newNoMatch', noMatchGuid);
/* Hooks */
onMounted( () => {if(props.product.pid) newPid.value = props.product.pid;});
</script>
<template>
<div class="w-full flex flex-col space-y-5">
    <div class="flex flex-col space-y-4 min-h-[10rem] overflow-y-auto overscroll-contain text-sm">
        <div class="flex items-center space-x-1 text-lg">
            <span>{{ t('updateQuantityModal.product', store.state.locale ) }}:</span>
            <span class="font-semibold">{{ props.product.name }}</span>
        </div>
        <div class="flex items-center space-x-1">
            <span>{{ t('updatePidModal.formulator', store.state.locale ) }}:</span>
            <span class="font-semibold">{{ props.product.formulator ? props.product.formulator.name : (props.product.formulator_name ? props.product.formulator_name : '-') }}</span>
        </div>
        <div class="">
            <label for="newPid" class="inline-block mb-1">
                <span>PID:</span>
                <span class="text-light-green-500 dark:text-blue-400">*</span>
            </label>
            <input 
                id="newPid" v-model="newPid" required name="newPid"
                placeholder="PID"
                type="text"
                class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-blue-500 focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400"/>
            <span
                v-if="store.state.errors.errors.new_pid" 
                class="text-sm text-red-500" >
                {{ store.state.errors.errors.new_pid[0] }}
            </span>
        </div>
    </div>
    <div class="flex items-center justify-end w-full space-x-5">
        <NoMatchButton 
            v-if="props.productStatus && props.productStatus != 'manualNoMatch'"
            :product="props.product"
            @newNoMatch="newNoMatch"
        />
        <button 
            :title="t('actions.save', store.state.locale)"
            @click.prevent="updatePid" 
            class="px-5 py-3 font-medium leading-5 text-center text-white rounded lg:mt-0 duration-300 lg:w-auto"
            :disabled="submitStatus !== 'void'"
            :class="setSubmitClasses"
            v-html="setSubmitContent">
        </button> 
    </div>
</div>
</template>