<template>
    <div class="fixed inset-0 p-6 bg-gray-1000 bg-opacity-90 z-30 overscroll-y-contain overflow-y-auto">
        <div class="flex flex-col w-full h-full items-center justify-center">
            <div class="bg-white dark:bg-gray-900 dark:text-gray-400 border dark:border-gray-700 rounded p-5 flex flex-col space-y-5 w-full md:w-2/3 lg:w-1/2 2xl:w-1/3">
                <div class="flex items-start justify-between">
                    <button 
                        title="Chiudi"
                        @click.prevent="$emit('closeModal')"
                        class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                                <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                    c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                    c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                            </svg>
                    </button>
                </div>
                <h1 class="text-xl flex items-center space-x-1">
                    <span>{{ $t('actions.delete', $store.state.locale) }}</span> 
                    <span class="lowercase">{{ $t('product.delete.products', $store.state.locale) }}</span>
                </h1>
                <p>
                    {{ $t('product.delete.multiDeleteMessage', $store.state.locale) }} {{ products.length }} {{ $t('product.delete.products', $store.state.locale) }}?
                </p>
                
                <div class="flex items-center justify-end space-x-3">
                    <button 
                        @click.prevent="destroy"
                        class="px-5 py-3 font-medium leading-5 text-center bg-white dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800 rounded lg:mt-0  duration-300 lg:w-auto"
                        :class="setSubmitClasses"
                        v-html="setSubmitContent">
                    </button>
                    <button 
                        @click.prevent="$emit('closeModal')"
                        class="px-5 py-3 font-medium leading-5 text-center text-white bg-blue-500 rounded lg:mt-0 hover:bg-blue-300 dark:hover:bg-blue-700 duration-300 lg:w-auto">
                        {{ $t('actions.cancel', $store.state.locale) }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore} from 'vuex';
import { useI18n } from 'vue-i18n';
import IncheckReferenceService from '../../services/IncheckReferenceService';
import SnackbarComposables from '../../composables/SnackbarComposables';

import { computed, ref } from 'vue';
export default {
    emits:['closeModal', 'productsDeleted'],
    props:
    {
        products:{required:true}
    },
    setup(props, {emit})
    {
        /**
         *
         * Data 
         *
         */
        const store = useStore();
        const {t, locale} = useI18n();
        const deleteStatus = ref('void');
        const {addSnackbar} = SnackbarComposables();
        const {destroyIncheckReference} = IncheckReferenceService();
         locale.value = store.state.locale;
        /**
         *
         * Computed 
         *
         */
        const setSubmitContent = computed( () => 
        {
            switch(deleteStatus.value)
            {
                case 'void':
                    return t('actions.delete', locale.value);
                case 'loading':
                    return `<svg class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
                case 'success':
                    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
                case 'error':
                    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
                default:
                    return t('actions.delete', locale.value);
            }
        });

        const setSubmitClasses = computed( () =>
        {
            switch(deleteStatus.value)
            {
                case 'void':
                    return 'text-blue-500 dark:text-blue-400';
                case 'loading':
                    return `text-blue-500 dark:text-blue-400`;
                case 'error':
                    return `text-red-500`;
                case 'success':
                    return `text-green-500`;
                default:
                    return 'text-blue-500 dark:text-blue-400';
            }
        });

        /**
         *
         * Methods 
         *
         */
        const destroy = async () => 
        {
            let count = 0;
            deleteStatus.value = 'loading';
            props.products.forEach( async (p) => 
            {
                const res = await destroyIncheckReference(p.incheck_reference);
                if(res === 200) count ++;
                else deleteStatus.value = 'error';
                if(count === props.products.length) 
                {
                    addSnackbar('success', t('product.delete.multiDeletedMessage', store.state.locale));
                    return emit('productsDeleted');
                }
            });
            setTimeout( () => deleteStatus.value = 'void', 2000);
        };

        return {
            /* Data */
            store,
            t,
            deleteStatus,
            /* Computed */
            setSubmitContent,
            setSubmitClasses,
            /* Methods */
            destroy,
            destroyIncheckReference
        }
    }
}
</script>