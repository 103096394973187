<script setup>
import { reactive, ref, /* onMounted, */ defineProps } from '@vue/reactivity';
//import { useRoute } from 'vue-router';
import ChartService from '../../services/ChartService';
import { useStore } from 'vuex';
/* Props */
const props = defineProps( {
    referenceDate: String,
    totalProducts: Number
});
/* Data */
const store = useStore()
const {productsByQuantity} = ChartService();
//const route = useRoute();
const weeklyArticlesAnalytics = ref(null);
const pageStatus = ref('loading');
const series = ref([{
    name: 'Products',
    data:[]
}]);
const chartOptions = reactive({
    chart: {
        type: 'bar',
        toolbar:
        {
            show:false
        },
        foreColor: store.state.theme === 'dark' ? '#e4e4e7' : '#18181b'
    },
    legend: {
      show: false,
    },
    dataLabels: 
    {   
        enabled: false  
    },
    plotOptions: {
        bar: {
            columnWidth: '25%',
            horizontal: false,
            distributed: true,
        }
    },
    colors:[],
    grid:
    {
        show:true,
        borderColor: '#a1a1aa',
    },
    xaxis: 
    {
        //categories: ['ZDHC MRSL v2.0 - Levels 1', 'ZDHC MRSL v3.1 - Levels 1', 'ZDHC MRSL v2.0 - Levels 2', 'ZDHC MRSL v3.1 - Levels 2', 'ZDHC MRSL v2.0 - Levels 3', 'ZDHC MRSL v3.1 - Levels 3', 'Expired', 'Not published'],
        categories: [],
        labels:
        {
            show:false
        },
    },
    yaxis: {
        forceNiceScale: true,
        labels:
        {
            show:true
        },
    },
});
/* Methods */
const init = async () => 
{
    pageStatus.value = 'loading';
    const res = await productsByQuantity(props.referenceDate);
    if(res?.data)
    {
        if(res.data.mrsl_v_3_lv_1)
        {
            chartOptions.xaxis.categories.push('ZDHC MRSL v3.1 - Levels 1');
            series.value[0].data.push(res.data.mrsl_v_3_lv_1);
            chartOptions.colors.push('#acc051');
        }
        if(res.data.mrsl_v_3_lv_2)
        {
            chartOptions.xaxis.categories.push('ZDHC MRSL v3.1 - Levels 2');
            series.value[0].data.push(res.data.mrsl_v_3_lv_2);
            chartOptions.colors.push('#4caf50');
        }
        if(res.data.mrsl_v_3_lv_3)
        {
            chartOptions.xaxis.categories.push('ZDHC MRSL v3.1 - Levels 3');
            series.value[0].data.push(res.data.mrsl_v_3_lv_3);
            chartOptions.colors.push('#9c27b0');
        }
        if(res.data.conformant)
        {
            chartOptions.xaxis.categories.push('Conformant');
            series.value[0].data.push(res.data.conformant);
            chartOptions.colors.push('#009688');
        }
        if(res.data.expired)
        {
            chartOptions.xaxis.categories.push('Expired');
            series.value[0].data.push(res.data.expired);
            chartOptions.colors.push('#71717A');
        }
        if(res.data.not_published)
        {
            chartOptions.xaxis.categories.push('Not published');
            series.value[0].data.push(res.data.not_published);
            chartOptions.colors.push('#03a9f4');
        }
        if(res.data.not_evaluated)
        {
            chartOptions.xaxis.categories.push('Not evaluated');
            series.value[0].data.push(res.data.not_evaluated);
            chartOptions.colors.push('#f44336');
        }
    }
    if(res) pageStatus.value ="loaded";

}
/* Hooks */
init();
</script>
<template>
    <div class="bg-white dark:bg-zinc-800 p-2 rounded w-full">
        <div v-if="pageStatus !== 'loading'" class="h-full w-full min-h-250px max-h-250px">
            <h1 class="text-xl font-semobild">
                Products by weight (kg)
            </h1>
            <apexchart
                ref="weeklyArticlesAnalytics"
                height="100%"
                :options="chartOptions"
                :series="series"
            ></apexchart>
        </div>
        <div v-else class="h-[250px] w-full min-h-250px max-h-250px flex items-center justify-center bg-gray-200">
            <svg class="animate-spin w-8 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
        </div>
    </div>
</template>