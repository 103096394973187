<template>
    <div class="fixed inset-0 p-6 bg-gray-1000 bg-opacity-90 z-30 overscroll-y-contain overflow-y-auto text-blue-500">
        <div class="flex flex-col w-full h-full items-center justify-center">
            <div class="bg-white dark:bg-gray-900 border dark:border-gray-700 rounded w-full p-5 flex flex-col space-y-5 w-full md:w-2/3 lg:w-1/2 2xl:w-1/3">
                <div class="flex items-start justify-between">
                    <button 
                        title="Chiudi"
                        @click.prevent="$emit('closeModal')"
                        class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                                <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                    c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                    c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                            </svg>
                    </button>
                </div>
                <div class="w-full flex items-center justify-start space-x-3">
                    <h1 class="text-xl dark:text-gray-200">
                        {{ $t('matchesModal.title', $store.state.locale) }}: {{ parent_product.name }}
                    </h1>
                    <span 
                        v-if="parent_product.incheck_reference?.no_match_id"
                        class="py-1 px-2 text-xs rounded-full flex items-center justify-center whitespace-nowrap bg-red-50 text-red-500">
                        No match
                    </span>
                </div>
               
                <div class="flex flex-col space-y-5">
                    <p class="text-lg dark:text-gray-400">
                        {{ $t('matchesModal.results', $store.state.locale) }}: 
                        <span class="text-blue-500 dark:text-blue-400">{{ setResultCount }}</span>
                    </p>
                    <div class="flex items-center w-full justify-center">
                        <input v-model="input_search" type="text" 
                            class="pl-4 h-10 rounded w-full border hover:border-blue-500 focus:border-blue-500 duration-300 dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400"
                            :placeholder="`${$t('actions.search', $store.state.locale)}...`" />
                    </div>
                    <div class="flex items-center justify-center w-full relative overflox-x-hidden overflow-y-auto overscroll-y-contain h-56">
                        <div v-if="matches.length && matching_status !== 'loading'" class="absolute inset-0  flex flex-col space-y-3 overflw-x-hidden overflow-y-auto">
                            <SelectButton 
                                v-for="(m, i) in matches"
                                :key="i"
                                :parent_match="m"
                                :active_match="active_match"
                                :activeNoMatch="parent_product.incheck_reference.no_match_id || ''"
                                @setActiveMatch="setActiveMatch"
                            />
                        </div>
                        <div v-if="matches.length === 0 && matching_status !== 'loading' && matching_status !== 'init'" class="flex w-full items-center justify-center dark:text-gray-400">
                            {{ $t('searchModal.noMatches', $store.state.locale) }}
                        </div>
                        <div v-else-if="matches.length === 0 && matching_status === 'init'" class="flex w-full items-center justify-center  dark:text-gray-400">
                            {{ $t('searchModal.initMessage', $store.state.locale) }}
                        </div>
                        <div v-if="matching_status === 'loading'" class="flex w-full items-center justify-center">
                            <svg class="animate-spin w-12 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
                        </div>
                    </div>
                </div>
                
                <div class="flex items-center justify-end space-x-3">
                    <!-- No Match Button -->
                    <button 
                        v-if="!parent_product.incheck_reference.no_match_id"
                        @click.prevent="setNoMatch"
                        class="px-5 py-3 font-medium leading-5 text-center text-white rounded lg:mt-0 duration-300 lg:w-auto"
                        :class="setNoMatchButtonClasses"
                        v-html="setNoMatchButtonContet"
                        :disabled="notMatchStatus !== 'void'">
                    </button>
                    <button 
                        @click.prevent="associateProduct"
                        class="px-5 py-3 font-medium leading-5 text-center text-white bg-blue-500 rounded w-full lg:mt-0 hover:bg-blue-800 duration-300 lg:w-auto">
                        {{ $t('matchesModal.associate', $store.state.locale) }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, watch, computed } from 'vue';
import { useStore} from 'vuex';
import { useI18n } from 'vue-i18n';
import SelectButton from './MatchesModal/SelectButton';
import SnackbarComposables from '../../composables/SnackbarComposables';
import ZdhcServiceV2 from '../../services/Zdhc/ZdhcServiceV2';
import SuggestionService from '../../services/SuggestionService';
import IncheckReferenceService from '../../services/IncheckReferenceService';
export default {
    emits:['closeModal', 'newSuggestion', 'noMatch'],
    props:
    {
        parent_product:{required:true}
    },
    components:{ SelectButton },
    setup(props, {emit})
    {
        /**
         *
         * Data
         *
         */
        const store = useStore();
        const {t} = useI18n();
        const matching_status = ref('init');
        const matches = ref([]);
        const active_match = ref(null);
        const input_search = ref('');
        const notMatchStatus = ref('void');
        const { addSnackbar } = SnackbarComposables();
        const { searchZdhcProductByWord, storeNewProduct } = ZdhcServiceV2();
        const { storeSuggestion } = SuggestionService();
        const { setNoMatchInIncheckReference } = IncheckReferenceService();
        const input_timer = ref(null);

        /**
         *
         * Watch
         *
         */
        watch( 
            () => input_search.value,
            () => 
            {
               clearTimeout(input_timer.value);
                input_timer.value = setTimeout(() => {
                    // Only fires if not cleared
                    startSearch();
                }, 600);
                
            }
        );
        /**
         *
         * Computed
         *
         */
        const setNoMatchButtonContet = computed( () => 
        {
            switch(notMatchStatus.value)
            {
                case 'loading':
                    return `<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
                case 'success':
                    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
                case 'error':
                    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
                case 'void': default:
                    return 'No match';
            }
        });
        const setNoMatchButtonClasses = computed( () =>
        {
            switch(notMatchStatus.value)
            {
                case 'void': case 'loading': case 'error': default:
                    return `bg-red-500 hover:bg-red-700`;
                case 'success':
                    return `bg-green-500 hover:bg-green-700`;
            }
        });
        const setResultCount = computed( () => 
        {
            return matches.value.some( m => m.guid === props.parent_product.incheck_reference.no_match_id) ? matches.value.length -1 : matches.value.length;
        });
        
        /**
         *
         * Methods
         *
         */
        const startSearch = async() => 
        {
            matches.value = [];
            if(input_search.value)
            {
                matching_status.value = 'loading';
                // ?recordLimit=${limit}
                let res = await searchZdhcProductByWord(input_search.value, true);
                if(res)
                {
                    matches.value = res.filter(o1 => o1.formulator_name !== 'No match Formulator' && o1.fullProduct?.registered && o1.fullProduct?.registered === 'ZDHC Gateway' && !matches.value.some(o2 => o1.id === o2.id));
                    matching_status.value = 'fetched';
                }
            }
        }
        const setActiveMatch = (id) => 
        {   
            active_match.value = id;
        }
        const associateProduct = async () => 
        {
            if(!active_match.value)
            {
                addSnackbar('warning', t('product.associate.noProduct', store.state.locale));
            }
            else if((props.parent_product.suggestion && active_match.value === props.parent_product.suggestion.zdhc_product_id) 
                    || (props.parent_product.match && active_match.value === props.parent_product.match.id))
            {
                addSnackbar('warning', t('product.associate.anotherProduct', store.state.locale));
            }
            else 
            {
               /*  store.dispatch('suggestion/store', { product_id: props.parent_product.id, ProductID: active_match.value })
                .then( res => 
                {
                    if(res.suggestion)
                    {
                        emit('newSuggestion', res.suggestion);
                        addSnackbar('success', t('matchesModal.successfulAssociation', store.state.locale));
                    }
                    else addSnackbar('error', 'none');
                }); */
                let p;
                if(!active_match.value.fullProduct)
                {
                    p = {
                        ProductID:  active_match.value.id,
                        translated_names:  active_match.value.translated_names,
                        productGUID:  active_match.value.guid,
                        reference_date: props.parent_product.incheck_reference.reference_date || null,
                        OrganizationCountry:  active_match.value.data?.organization_country || null,
                        registered:  active_match.value.data?.registered || null,
                        LastUpdatedDate:  active_match.value.data?.zdhc_last_updated_at || null,
                        productCode:  active_match.value.data?.code || null,
                        OtherName:  active_match.value.data?.other_name || null,
                        productOtherName:  active_match.value.data?.product_other_name || null,
                        zdhcPID:  active_match.value.data?.pid || null,
                        zdhcAID:  active_match.value.data?.aid || null,
                        formulatorGUID: active_match.value.formulator?.guid || null,
                        formulatorName: active_match.value.formulator?.name || null,
                        supplierAID: active_match.value.formulator?.supplierAID || null,
                        ProductCertifications: active_match.value.certifications || []
                    };
                }
                else 
                {
                    active_match.value.fullProduct.reference_date = props.parent_product.incheck_reference.reference_date;
                    p = active_match.value.fullProduct;
                }
                const res = await storeSuggestion(props.parent_product.id, p);
                if(res)
                {
                    addSnackbar('success', t('matchesModal.successfulAssociation', store.state.locale));
                    return emit('newSuggestion', res.suggestion);
                }
                else addSnackbar('error', 'none');
            }
        };
        const setNoMatch = async () => 
        {
            notMatchStatus.value = 'loading';
            const formulatorGuid = props.parent_product.name.formulator ? props.parent_product.name.formulator.guid : null;
            const res = await storeNewProduct(props.parent_product.name, formulatorGuid);
            if(res.guid)
            {
                
                const noMatchRes = await setNoMatchInIncheckReference(props.parent_product.incheck_reference.id, {guid: res.guid});
                if(noMatchRes)
                {
                    notMatchStatus.value = 'success';
                    setTimeout( () => notMatchStatus.value = 'void', 2000);
                    return emit('noMatch', res.guid);
                }
            }
            else 
            {
                notMatchStatus.value = 'error';
                setTimeout( () => notMatchStatus.value = 'void', 2000);
            }
        };
        

        return {
            /* Data */
            matches,
            matching_status,
            active_match,
            input_search,
            notMatchStatus,
            /* Computed */
            setNoMatchButtonContet,
            setNoMatchButtonClasses,
            setResultCount,
            /* Methods */
            associateProduct,
            setActiveMatch,
            searchZdhcProductByWord,
            setNoMatch
        }
    }
}
</script>